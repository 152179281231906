import { Wrapper } from './error.styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { selectGenericErrorNotifications } from 'store/errors/selectors';
import { getErrorDetails } from 'utils/get-error-details';
import { ErrorType } from 'shared/consts/error-translations';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';

export const ErrorPage = (): JSX.Element => {
  const { formatMessage, messages } = useIntl();
  const client = useMiniappSdk();
  const errors = useSelector(selectGenericErrorNotifications);
  const handleClick = useCallback(() => {
    client.close();
  }, [client]);
  const { titleTranslationId, descriptionTranslationId, buttonTextTranslationId } = getErrorDetails(
    errors,
    messages,
    ErrorType.GENERIC_ERROR,
  );

  return (
    <Wrapper>
      <ErrorNotification
        title={formatMessage({ id: titleTranslationId })}
        description={formatMessage({ id: descriptionTranslationId })}
        buttonText={formatMessage({ id: buttonTextTranslationId })}
        onClick={handleClick}
      />
    </Wrapper>
  );
};
