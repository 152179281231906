import { CardsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const cardsState = (state: RootState): CardsState => state.cards;

export const selectRegisterCardStatus = createSelector([cardsState], (cards) => cards.registerCard.status);

export const selectRegisterCardError = createSelector([cardsState], (cards) => cards.registerCard.error);

export const selectCards = createSelector([cardsState], (cards) => cards.cards ?? []);

export const selectGetCardsStatus = createSelector([cardsState], (cards) => cards.getCards.status);

export const selectDeleteCardStatus = createSelector([cardsState], (cards) => cards.deleteCard.status);

export const selectAreCardsRegistered = createSelector([selectCards], (cards) => cards.length > 0);
