import { createSelector } from 'reselect';
import { ACTION_STATUSES } from 'shared/consts';
import { NotificationSettings, NotificationSettingsState } from 'store/notification-settings/types';
import { RootState } from 'store/reducer';

export const notificationSettingsStateSelector = (state: RootState): NotificationSettingsState =>
  state.notificationSettings;

export const selectNotificationSettings = createSelector(
  notificationSettingsStateSelector,
  (notificationSettingsState) => notificationSettingsState.notificationSettings,
);

export const selectNotificationSettingsForRender = createSelector(
  selectNotificationSettings,
  (rawNotificationSettings) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = rawNotificationSettings || {};

    return Object.entries(rest).map(([key, value]) => ({
      title: key,
      values: value,
    }));
  },
);

export const selectGetNotificationSettingsStatus = createSelector(
  notificationSettingsStateSelector,
  (notificationSettingsState) => notificationSettingsState?.getNotificationSettings?.status,
);

export const selectPatchNotificationSettingsStatus = createSelector(
  notificationSettingsStateSelector,
  (notificationSettingsState) => notificationSettingsState?.patchNotificationSettings?.status,
);

export const selectIsPatchNotificationSettingsStatusPending = createSelector(
  selectPatchNotificationSettingsStatus,
  (patchNotificationSettingsStatus) => patchNotificationSettingsStatus === ACTION_STATUSES.PENDING,
);

export const selectIsGetNotificationSettingsStatusPending = createSelector(
  selectGetNotificationSettingsStatus,
  (getNotificationSettingsStatus) => getNotificationSettingsStatus === ACTION_STATUSES.PENDING,
);

export const checkIsPatchingSettingByTitle = (title: Exclude<keyof NotificationSettings, 'id'>) => createSelector(
  notificationSettingsStateSelector,
  (notificationSettingsState) => notificationSettingsState.patchedSettings.includes(title),
);
