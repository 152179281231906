import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

import { RewardsState } from './types';

export const initialState: RewardsState = {
  rewards: null,
  usedRewards: null,
  rewardsBalance: null,
  reward: null,
  activateReward: {
    status: null,
    error: null,
  },
  finalizeReward: {
    status: null,
    error: null,
  },
  getRewards: {
    status: null,
    error: null,
  },
  getUsedRewards: {
    status: null,
    error: null,
  },
  getInfiniteRewards: {
    status: null,
    error: null,
  },
  getRewardsBalance: {
    status: null,
    error: null,
  },
  total: null,
  count: null,
  imageIsLoaded: false,
};

const getRewardsRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  getRewards: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getRewardsSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.getRewards.success>,
): RewardsState => ({
  ...state,
  rewards: payload.rewards,
  total: payload.total,
  count: payload.count,
  getRewards: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getRewardsFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.getRewards.failure>,
): RewardsState => ({
  ...state,
  getRewards: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const getUsedRewardsRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  getUsedRewards: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getUsedRewardsSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.getUsedRewards.success>,
): RewardsState => ({
  ...state,
  usedRewards: [...(state.usedRewards || []), ...payload.rewards],
  total: payload.total,
  count: payload.count,
  getUsedRewards: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getUsedRewardsFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.getUsedRewards.failure>,
): RewardsState => ({
  ...state,
  getUsedRewards: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const getRewardsInfiniteRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  getInfiniteRewards: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getRewardsInfiniteSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.getInfiniteRewards.success>,
): RewardsState => ({
  ...state,
  rewards: [...(state.rewards || []), ...payload],
  getInfiniteRewards: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getRewardsInfiniteFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.getInfiniteRewards.failure>,
): RewardsState => ({
  ...state,
  getInfiniteRewards: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const getRewardsBalanceRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  getRewardsBalance: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getRewardsBalanceSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.getRewardsBalance.success>,
): RewardsState => ({
  ...state,
  rewardsBalance: payload,
  getRewardsBalance: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getRewardsBalanceFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.getRewardsBalance.failure>,
): RewardsState => ({
  ...state,
  getRewardsBalance: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetRewardsStateHandler = (state: RewardsState): RewardsState => ({
  ...state,
  rewards: null,
  usedRewards: null,
  rewardsBalance: null,
  getRewards: {
    status: null,
    error: null,
  },
  getUsedRewards: {
    status: null,
    error: null,
  },
  total: null,
  count: null,
});

const imageIsLoaded = (state: RewardsState, { payload }: ActionType<typeof actions.imageIsLoaded>): RewardsState => ({
  ...state,
  imageIsLoaded: payload,
});

const activateRewardRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  activateReward: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const activateRewardSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.activateReward.success>,
): RewardsState => ({
  ...state,
  reward: payload,
  activateReward: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const activateRewardFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.activateReward.failure>,
): RewardsState => ({
  ...state,
  activateReward: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const finalizeRewardRequestHandler = (state: RewardsState): RewardsState => ({
  ...state,
  finalizeReward: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const finalizeRewardsSuccessHandler = (
  state: RewardsState,
  { payload }: ActionType<typeof actions.finalizeReward.success>,
): RewardsState => ({
  ...state,
  reward: payload,
  finalizeReward: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const finalizeRewardsFailureHandler = (
  state: RewardsState,
  { payload: error }: ActionType<typeof actions.finalizeReward.failure>,
): RewardsState => ({
  ...state,
  finalizeReward: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetActivateRewardStateHandler = (state: RewardsState): RewardsState => ({
  ...state,
  activateReward: {
    status: null,
    error: null,
  },
});

const resetUsedRewardsStateHandler = (state: RewardsState): RewardsState => ({
  ...state,
  usedRewards: null,
  getUsedRewards: {
    status: null,
    error: null,
  },
});

const resetFinalizeRewardStateHandler = (state: RewardsState): RewardsState => ({
  ...state,
  finalizeReward: {
    status: null,
    error: null,
  },
});

const rewardsReducer = createReducer(initialState)
  .handleAction(actions.getRewards.request, getRewardsRequestHandler)
  .handleAction(actions.getRewards.success, getRewardsSuccessHandler)
  .handleAction(actions.getRewards.failure, getRewardsFailureHandler)
  .handleAction(actions.getUsedRewards.request, getUsedRewardsRequestHandler)
  .handleAction(actions.getUsedRewards.success, getUsedRewardsSuccessHandler)
  .handleAction(actions.getUsedRewards.failure, getUsedRewardsFailureHandler)
  .handleAction(actions.getInfiniteRewards.request, getRewardsInfiniteRequestHandler)
  .handleAction(actions.getInfiniteRewards.success, getRewardsInfiniteSuccessHandler)
  .handleAction(actions.getInfiniteRewards.failure, getRewardsInfiniteFailureHandler)
  .handleAction(actions.getRewardsBalance.request, getRewardsBalanceRequestHandler)
  .handleAction(actions.getRewardsBalance.success, getRewardsBalanceSuccessHandler)
  .handleAction(actions.getRewardsBalance.failure, getRewardsBalanceFailureHandler)
  .handleAction(actions.activateReward.request, activateRewardRequestHandler)
  .handleAction(actions.activateReward.success, activateRewardSuccessHandler)
  .handleAction(actions.activateReward.failure, activateRewardFailureHandler)
  .handleAction(actions.finalizeReward.request, finalizeRewardRequestHandler)
  .handleAction(actions.finalizeReward.success, finalizeRewardsSuccessHandler)
  .handleAction(actions.finalizeReward.failure, finalizeRewardsFailureHandler)
  .handleAction(actions.resetRewardsState, resetRewardsStateHandler)
  .handleAction(actions.resetActivateRewardState, resetActivateRewardStateHandler)
  .handleAction(actions.resetUsedRewardsState, resetUsedRewardsStateHandler)
  .handleAction(actions.resetFinalizeRewardState, resetFinalizeRewardStateHandler)
  .handleAction(actions.imageIsLoaded, imageIsLoaded);

export default rewardsReducer;
