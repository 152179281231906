import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { UserAuthState } from './types';
import { ACTION_STATUSES } from 'shared/consts';

export const userAuthStateSelector = (state: RootState): UserAuthState => state.userAuth;

export const selectLoginStatus = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.login.status,
);

export const selectIsLoginFailed = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.login.status === ACTION_STATUSES.REJECTED,
);

export const selectIsLoginPending = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.login.status === ACTION_STATUSES.PENDING,
);

export const selectLoginError = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.login.error,
);

export const selectLogoutStatus = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.logout.status,
);

export const selectIsLogoutFailed = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.logout.status === ACTION_STATUSES.REJECTED,
);

export const selectIsLogoutPending = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.logout.status === ACTION_STATUSES.PENDING,
);

export const selectLogoutError = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.logout.error,
);

export const selectRegisterStatus = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.register.status,
);

export const selectIsRegisterFailed = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.register.status === ACTION_STATUSES.REJECTED,
);

export const selectIsRegisterPending = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.register.status === ACTION_STATUSES.PENDING,
);

export const selectRegisterError = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.register.error,
);

export const selectFinishLoginStatus = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.finishLogin.status,
);

export const selectFinishLoginError = createSelector(
  userAuthStateSelector,
  (userAuthState) => userAuthState.finishLogin.error,
);
