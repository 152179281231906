import { GetExternalLinksResponse } from './types';

import { FailureActionPayload } from '../errors/types';
import { createAsyncAction } from 'typesafe-actions';

export const getExternalLinks = createAsyncAction(
  'GET_EXTERNAL_LINKS_REQUEST',
  'GET_EXTERNAL_LINKS_SUCCESS',
  'GET_EXTERNAL_LINKS_FAILURE',
)<[undefined, undefined], GetExternalLinksResponse, FailureActionPayload>();
