import React, { useEffect, useState } from 'react';

import { StyledCountdown } from 'components/countdown/styles';
import { calculateTimeLeft, formatCountdownText, isTimeExpired } from 'components/countdown/utils';
import { useIntl } from 'react-intl';
import { MILLIS_IN_SECOND } from 'shared/consts';

export type CountdownProps = {
  endDatetime: Date;
  onEnd?: VoidFunction;
};

export const Countdown = ({
  endDatetime,
  onEnd,
}: CountdownProps) => {
  const intl = useIntl();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft({ startDatetime: new Date(), endDatetime }));
  const [hasExpired, setHasExpired] = useState(isTimeExpired(timeLeft));

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft({ startDatetime: new Date(), endDatetime });

      if (isTimeExpired(newTimeLeft) && !hasExpired) {
        clearInterval(timer);
        setHasExpired(true);
      }
      setTimeLeft(newTimeLeft);
    }, MILLIS_IN_SECOND);

    return () => clearInterval(timer);
  }, [hasExpired, endDatetime]);

  useEffect(() => {
    if (hasExpired && onEnd != null) {
      onEnd();
    }
  }, [hasExpired, onEnd]);

  const expirationText = intl.formatMessage({ id: 'rewardActivation.expirationText' });
  const countdownText = formatCountdownText(timeLeft);
  const formattedCountdownText = `${expirationText} ${countdownText}`;

  return (hasExpired ? null
    : (
      <StyledCountdown data-testid="countdown-component">
        {formattedCountdownText}
      </StyledCountdown>
    )
  );
};
