import { HeaderTitle } from '@hqo/react-components-library/dist/page-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px 12px;
`;

export const Title = styled(HeaderTitle)`
  font-weight: 500;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  height: 20px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  cursor: pointer;
`;
