import { ACTION_STATUSES, BUILDING, HISTORY_REWARDS_LIST_LIMIT } from 'shared/consts';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Reward } from 'store/rewards/types';
import { selectGetUsedRewardsStatus, selectUsedRewards } from 'store/rewards/selectors';
import { selectBuildingUuid } from 'store/building/selectors';
import { getUsedRewards } from 'store/rewards/actions';

interface UseHistoryListContentReturnValues {
  usedRewards: Array<Reward>;
  isLoading: boolean;
}

export const useHistoryListContent = (): UseHistoryListContentReturnValues => {
  const dispatch = useDispatch();
  const usedRewards = useSelector(selectUsedRewards);
  const isLoading = useSelector(selectGetUsedRewardsStatus) === ACTION_STATUSES.PENDING;
  const buildingUuid = useSelector(selectBuildingUuid);
  const rewardsStatus = useSelector(selectGetUsedRewardsStatus);

  useEffect(() => {
    const payload = {
      ownerId: buildingUuid,
      ownerType: BUILDING,
      limit: HISTORY_REWARDS_LIST_LIMIT,
      offset: 0,
    };

    if (!rewardsStatus) {
      dispatch(getUsedRewards.request(payload));
    }
  }, [rewardsStatus, buildingUuid]);

  return { usedRewards, isLoading };
};
