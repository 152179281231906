import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';

export interface SwitchDefaultFilterProps {
  children: Array<JSX.Element>;
}

export const SwitchModalContent = ({ children }: SwitchDefaultFilterProps): JSX.Element | null => {
  const { step } = useSearchParams<SearchParams>();

  if (!step) return null;

  return children.find((child) => child.props['data-value'] === step) ?? null;
};
