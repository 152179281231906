import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentMethodsManager } from '@hqo/react-components-library/dist/payment/payment-methods-manager';

const COMBINED_TITLE_FOOTER_HEIGHT = 236;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: calc(100% - ${COMBINED_TITLE_FOOTER_HEIGHT}px);
`;

export const CardIcon = styled(FontAwesomeIcon)`
  height: 18px;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  margin-left: 8px;
`;

export const StyledPaymentMethodsManager = styled(PaymentMethodsManager)`
  display: flex;
  margin-bottom: 14px;
  width: 100%;

  button {
    color: ${({ theme }) => theme.colors.palette.modifiedMalibuBlue};
    font: 400 16px / normal ${({ theme }) => theme.fonts.join()};
    margin-top: 14px;
  }

  .radio-button-title {
    font: 400 16px / normal ${({ theme }) => theme.fonts.join()};
    color: ${({ theme }) => theme.colors.palette.systemBlack};
  }

  .radio-button-container {
    margin-top: 6px;
  }

  [data-testid="radio-form-test-id"] {
    overflow-y: auto;
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
