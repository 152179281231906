import { FailureActionPayload } from '../errors/types';
import { createAsyncAction } from 'typesafe-actions';
import {
  NotificationFailedPatch,
  NotificationSettings,
  NotificationSuccessPatch,
} from 'store/notification-settings/types';

export const getNotificationSettings = createAsyncAction(
  'GET_NOTIFICATION_SETTINGS_REQUEST',
  'GET_NOTIFICATION_SETTINGS_SUCCESS',
  'GET_NOTIFICATION_SETTINGS_FAILURE',
)<[undefined, undefined], NotificationSettings, FailureActionPayload>();

export const patchNotificationSettings = createAsyncAction(
  'PATCH_NOTIFICATION_SETTINGS_REQUEST',
  'PATCH_NOTIFICATION_SETTINGS_SUCCESS',
  'PATCH_NOTIFICATION_SETTINGS_FAILURE',
)<Partial<Omit<NotificationSettings, 'id'>>, NotificationSuccessPatch, NotificationFailedPatch>();
