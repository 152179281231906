import { useCallback, useEffect } from 'react';

import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_STATUSES } from 'shared/consts';
import { getIntroReward } from 'store/intro-reward/actions';
import {
  selectIntroRewardStatus,
  selectRedeemDescription,
} from 'store/intro-reward/selectors';
import { replace } from 'store/router/actions';

interface UseSignInContentReturnValues {
  handleButtonClick: VoidFunction;
  redeemDescription: string;
  isLoaded: boolean;
}

export const useWelcomeContent = (): UseSignInContentReturnValues => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const redeemDescription = useSelector(selectRedeemDescription);
  const introRewardStatus = useSelector(selectIntroRewardStatus);

  useEffect(() => {
    if (!introRewardStatus) {
      dispatch(getIntroReward.request());
    }
  }, [introRewardStatus]);

  const handleButtonClick = useCallback(() => {
    const searchString = qs.stringify({ step: SSO_MODAL_CONTENT_STEPS.SIGN_UP, isSlideAnimationEnable: true });

    dispatch(replace(`${pathname}?${searchString}`));
  }, [pathname]);

  return { handleButtonClick, redeemDescription, isLoaded: introRewardStatus !== ACTION_STATUSES.PENDING };
};
