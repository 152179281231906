import { useBooleanState } from '@hqo/react-components-library';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'store/router/actions';
import { ACTION_STATUSES, BUILDING, DEFAULT_SLIDE_ANIMATION_DELAY } from 'shared/consts';
import { deleteUser } from 'store/user/actions';
import { selectBuildingUuid } from 'store/building/selectors';
import { selectDeleteUserStatus } from 'store/user/selectors';
import { VariantTypes } from '@hqo/react-components-library/dist/atoms/button/button';
import { useIntl } from 'react-intl';
import { BottomModalContentButtons } from 'components/bottom-modal-content/types';

interface UseAccountRemovalModalReturnValues {
  handleGoBack: VoidFunction;
  handleCTAButtonClick: VoidFunction;
  redirectToTheLandingPage: VoidFunction;
  isReverseAnimation: boolean;
  buttons: Array<BottomModalContentButtons>;
}

export const useAccountRemovalModal = (): UseAccountRemovalModalReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const { value: isReverseAnimation, toggle: toggleIsReverseAnimation } = useBooleanState(false);
  const defaultPayload = { ownerId: buildingUuid, ownerType: BUILDING };
  const isCTAButtonLoading = useSelector(selectDeleteUserStatus) === ACTION_STATUSES.PENDING;

  const handleGoBack = useCallback(() => {
    toggleIsReverseAnimation();

    setTimeout(() => {
      dispatch(replace('/settings'));
    }, DEFAULT_SLIDE_ANIMATION_DELAY);
  }, [dispatch, toggleIsReverseAnimation]);

  const handleCTAButtonClick = useCallback(() => {
    dispatch(deleteUser.request(defaultPayload));
  }, [dispatch, defaultPayload]);

  const buttons = [
    {
      isLoading: isCTAButtonLoading,
      isDisabled: isCTAButtonLoading,
      text: intl.formatMessage({ id: 'accountRemovalModal.requestAccountRemoval' }),
      variant: 'Destructive' as VariantTypes,
      onClick: handleCTAButtonClick,
    },
    {
      isDisabled: isCTAButtonLoading,
      text: intl.formatMessage({ id: 'accountRemovalModal.goBack' }),
      variant: 'Plain' as VariantTypes,
      onClick: handleGoBack,
    },
  ];

  const redirectToTheLandingPage = useCallback(() => {
    dispatch(replace('/'));
  }, [dispatch]);

  return {
    handleGoBack, handleCTAButtonClick, isReverseAnimation, buttons, redirectToTheLandingPage,
  };
};
