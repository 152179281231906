import * as actions from './actions';

import {
  catchError, filter, map, mergeMap, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { concat, of } from 'rxjs';
import { getErrorCode } from '../utils/get-error-code.util';
import { replace } from '../router/actions';
import { getCards } from './actions';
import { deletePaymentMethod } from 'store/payment-methods/actions';

export const registerCardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.registerCard.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .registerCard(payload)
    .pipe(
      mergeMap(() => concat(
        of(actions.registerCard.success()),
        of(getCards.request()),
      )),
      catchError((error: Error) => {
        const failureAction = of(actions.registerCard.failure({ error, errorCode: getErrorCode(error) }));

        if (payload.new_payment_method_id) {
          return failureAction.pipe(
            mergeMap((action) => of(action, deletePaymentMethod.request(payload.new_payment_method_id))),
          );
        }

        return failureAction;
      }),
    )),
);

export const getCardsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getCards.request)),
  withLatestFrom(state$),
  switchMap(([, state]) => {
    const ownerId = state.building.building.uuid;

    return apiClient(state)
      .getCards(ownerId)
      .pipe(
        map((xhrPayload) => actions.getCards.success(xhrPayload.response.data.cards)),
        catchError((error: Error) => of(actions.getCards.failure({ error, errorCode: getErrorCode(error) }))),
      );
  }),
);

export const deleteCardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.deleteCard.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => {
    const ownerId = payload.ownerId ?? state.building.building.uuid;

    return apiClient(state)
      .deleteCard({ ownerId, ...payload })
      .pipe(
        mergeMap(() => concat(
          of(actions.deleteCard.success()),
          of(getCards.request()),
          of(replace('/settings/payment-methods')),
        )),
        catchError((error: Error) => of(actions.deleteCard.failure({ error, errorCode: getErrorCode(error) }))),
      );
  }),
);
