import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SavedPaymentMethod } from 'store/payment-methods/types';
import { formatSavedPaymentMethodBrand } from 'utils/formatSavedPaymentMethodBrand';
import { RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { CardIcon } from '../styles';

interface UsePaymentLabelsProps {
  paymentMethods: Array<SavedPaymentMethod>;
}

interface UsePaymentLabelsReturnValues {
  buildSavedPaymentLabels: () => RadioOption[];
  labels: { title: string; invoiceText: string; addCardButtonText: string };
}

export const usePaymentLabels = ({ paymentMethods }: UsePaymentLabelsProps): UsePaymentLabelsReturnValues => {
  const intl = useIntl();

  const buildSavedPaymentLabel = useCallback(
    (paymentMethod: SavedPaymentMethod) => {
      const capitalizedBrand = formatSavedPaymentMethodBrand(paymentMethod.card_brand);

      return intl.formatMessage(
        {
          id: 'registerCardModal.savedPaymentMethodOption',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod.card_last_four,
        },
      );
    },
    [intl],
  );

  const buildSavedPaymentLabels = useCallback(
    () => paymentMethods.map((paymentMethod) => {
      const label = buildSavedPaymentLabel(paymentMethod);

      return {
        label,
        value: paymentMethod.id.toString(),
        icon: <CardIcon icon={faCreditCard} />,
      };
    }),
    [paymentMethods, buildSavedPaymentLabel, intl],
  );

  const labels = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'registerCardModal.selectCardToRegister' }),
      invoiceText: '',
      addCardButtonText: intl.formatMessage({
        id: 'registerCardModal.registerNewCard',
      }),
    }),
    [intl],
  );

  return {
    buildSavedPaymentLabels,
    labels,
  };
};
