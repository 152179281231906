import { SkeletonBlock, SkeletonContainer } from './styles';
import React from 'react';

export interface SkeletonProps {
  boneNumber?: number;
}

export const RewardTileSkeleton = ({ boneNumber = 2 }: SkeletonProps) => {
  const skeletonPlaceholder = Array.from(Array(boneNumber).keys());

  return (
    <SkeletonContainer>
      {skeletonPlaceholder.map((bone) => (
        <SkeletonBlock key={bone} data-testid="reward-tile-skeleton" />
      ))}
    </SkeletonContainer>
  );
};
