import { MailTo } from '@hqo/react-components-library';
import { slideInRight, slideOutLeft } from 'hocs/shared-styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isSlideAnimationEnable: boolean, reverseSlideAnimation?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  ${({ isSlideAnimationEnable }) => isSlideAnimationEnable && 'transform: translate(-50%, -50%);'}
  ${({ isSlideAnimationEnable, reverseSlideAnimation }) => isSlideAnimationEnable
    && css`animation: ${reverseSlideAnimation ? slideOutLeft : slideInRight} 0.5s forwards;`}
`;

export const Title = styled.div`
  font: 500 18px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
`;

export const Subtitle = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 48px;
`;

export const Button = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    font-weight: 500;
  }
`;

export const StyledMailTo = styled(MailTo)`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
`;

export const MailToContainer = styled.div`
  width: fit-content;
`;
