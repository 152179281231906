import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { styled } from 'styled-components';

export const StyledSwipeModal = styled(SwipeModal)<{isIframe: boolean}>`
  .modal-title {
    padding-left: 16px;
  }

  .content-body {
    padding: ${({ isIframe }) => (isIframe ? '0' : '0px 16px')};
  }
`;
