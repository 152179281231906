import React from 'react';
import { useLandingPage } from './hooks/use-landing-page.hook';
import { Skeleton } from 'components/skeleton';
import { Route, Routes } from 'react-router-dom';
import { SsoFlow } from 'components/modals/sso-flow';
import { SettingsPage } from 'components/modals/settings';
import {
  StyledWrapper, Container, Title, Subtitle, StyledPageHeader,
} from './styles';
import { useIntl } from 'react-intl';
import { RewardsListContent } from 'components/rewards-list-content';
import { RewardsBalance } from 'components/rewards-balance';
import { RegisterCard } from 'components/register-card';
import { THANX_BRAND_NAME } from 'shared/consts';

export const LandingPage = (): JSX.Element => {
  const intl = useIntl();
  const {
    isLoading,
    landingPageHeaderHeight,
    pageHeaderRef,
    isRegisterCardVisible,
    onRegisterCardClick,
    registerCardRef,
    rewardsBalanceRef,
  } = useLandingPage();

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <StyledWrapper>
      <StyledPageHeader
        pageHeaderRef={pageHeaderRef}
        title={intl.formatMessage({ id: 'rewardsHeaderTitle' }, { brandName: THANX_BRAND_NAME })}
      />
      <Container>
        <Title>{intl.formatMessage({ id: 'rewardsTitle' })}</Title>
        <Subtitle>{intl.formatMessage({ id: 'rewardsSubtitle' })}</Subtitle>
        {isRegisterCardVisible && <RegisterCard onClick={onRegisterCardClick} registerCardRef={registerCardRef} />}
        <RewardsBalance rewardsBalanceRef={rewardsBalanceRef} />
        <RewardsListContent landingPageHeaderHeight={landingPageHeaderHeight} />
      </Container>
      <Routes>
        <Route path="sso" element={<SsoFlow />} />
        <Route path="settings/*" element={<SettingsPage />} />
      </Routes>
    </StyledWrapper>
  );
};
