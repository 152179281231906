import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowLeftChevron } from '@hqo/react-components-library';
import { Container, StyledBackButton } from './styles';
import { goBack, replace } from 'store/router/actions';

interface Props {
  backArrowMobileHeaderRef?: React.MutableRefObject<HTMLDivElement>;
  backArrowMobileLocation?: string;
}

export const BackArrowMobileHeader = ({ backArrowMobileHeaderRef, backArrowMobileLocation }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleOnClick = useCallback(() => {
    if (backArrowMobileLocation) {
      dispatch(replace(backArrowMobileLocation));
      return;
    }

    dispatch(goBack());
  }, [backArrowMobileLocation, dispatch]);

  return (
    <Container data-testid="back-button-mobile-header" ref={backArrowMobileHeaderRef}>
      <StyledBackButton data-testid="mobile-back-button" onClick={handleOnClick}>
        <ArrowLeftChevron size="sm" variant="adminBlack" />
      </StyledBackButton>
    </Container>
  );
};
