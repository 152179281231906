import { Button } from '@hqo/react-components-library/dist/atoms';
import { Plus } from '@hqo/react-components-library/dist/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 16px;
  gap: 16px;
`;

export const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
`;

export const StyledLink = styled(Button)`
  display: flex;
  font: 400 14px / 22px normal ${({ theme }) => theme.fonts.join()};
  width: fit-content;
  color: ${({ theme }) => theme.colors.palette.modifiedMalibuBlue};
  cursor: pointer;
  padding: 0;
`;

export const StyledIcon = styled(Plus)`
  height: 14px;
  color: ${({ theme }) => theme.colors.palette.modifiedMalibuBlue};
`;
