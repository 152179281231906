import styled from 'styled-components';
import { DEFAULT_CONTAINER_PADDING } from 'shared/consts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px ${DEFAULT_CONTAINER_PADDING}px;
  width: calc(100% - ${2 * DEFAULT_CONTAINER_PADDING}px);
  box-shadow: 0 2px 10px 0 rgba(54, 54, 54, 0.20);
  user-select: none;
  border-radius: 12px;
  gap: 16px;
`;

export const Wrapper = styled.div`
  padding: 0 ${DEFAULT_CONTAINER_PADDING}px;
  width: calc(100% - ${2 * DEFAULT_CONTAINER_PADDING}px);
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  flex: 1;
`;
