import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(isLoading) => isLoading && 'center'};
  height: 100vh;
`;

export const BoldText = styled.strong`
  font-weight: 500;
`;

export const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const slide = (reverseAnimation: boolean) => keyframes`
  from {
    transform: translateY(${reverseAnimation ? 0 : '100%'});
  }
  to {
    transform: translateY(${reverseAnimation ? '100%' : 0});
  }
`;

export const fade = (reverseAnimation: boolean) => keyframes`
  from {
    opacity: ${reverseAnimation ? 1 : 0};
  }
  to {
    opacity: ${reverseAnimation ? 0 : 1};
  }
`;

export const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium};
  background: ${({ theme }) => `linear-gradient(110deg,
    ${theme?.colors?.palette?.systemGreyMedium} 8%,
    ${theme?.colors?.greys?.adminGreyLightAlt} 18%,
    ${theme?.colors?.palette?.systemGreyMedium} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
