import React from 'react';

import { Router } from 'components/router';
import { useInitialData } from 'components/app/use-initial-data.hooks';
import { Skeleton } from 'components/skeleton';

import { useMagicLinkRedirect } from 'hooks/use-magic-link-redirect.hook';
import { useSdkData } from 'hooks/use-sdk-data.hook';
import { useAnalytics } from 'hooks/use-analytics.hook';

export const App: React.FC = (): JSX.Element => {
  useSdkData();
  useAnalytics();

  const shouldWaitForFinishLogin = useMagicLinkRedirect();
  const { isReadyToRenderApp } = useInitialData();

  if (!isReadyToRenderApp || shouldWaitForFinishLogin) {
    return (
      <Skeleton />
    );
  }

  return <Router />;
};
