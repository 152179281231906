import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUILDING, EMPTY_STRING } from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import { login, register, resetUserAuthState } from 'store/userAuth/actions';
import {
  selectIsLoginFailed,
  selectIsLoginPending,
  selectIsRegisterFailed,
  selectIsRegisterPending,
} from 'store/userAuth/selectors';

interface UseEmailInputButtonReturnValues {
  email: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLoginButtonClick: VoidFunction;
  onRegisterButtonClick: VoidFunction;
  isLoginFailed: boolean;
  isLoginPending: boolean;
  isRegisterFailed: boolean;
  isButtonLoading: boolean;
}

export const useEmailInputButton = (): UseEmailInputButtonReturnValues => {
  const [email, setEmail] = useState<string>(EMPTY_STRING);
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const isLoginFailed = useSelector(selectIsLoginFailed);
  const isLoginPending = useSelector(selectIsLoginPending);
  const isRegisterFailed = useSelector(selectIsRegisterFailed);
  const isRegisterPending = useSelector(selectIsRegisterPending);
  const isButtonLoading = isRegisterPending;

  const handleChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isLoginFailed || isRegisterFailed) {
        dispatch(resetUserAuthState());
      }

      setEmail(event.target.value);
    },
    [isLoginFailed, isRegisterFailed],
  );

  const onLoginButtonClick = useCallback(
    () => {
      dispatch(login.request({ email, ownerType: BUILDING, ownerId: buildingUuid }));
    },
    [dispatch, email, buildingUuid],
  );

  const onRegisterButtonClick = useCallback(
    () => {
      dispatch(register.request({ email, ownerType: BUILDING, ownerId: buildingUuid }));
    },
    [dispatch, email, buildingUuid],
  );

  return {
    email,
    handleChangeEmail,
    onLoginButtonClick,
    onRegisterButtonClick,
    isLoginFailed,
    isLoginPending,
    isButtonLoading,
    isRegisterFailed,
  };
};
