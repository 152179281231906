import React from 'react';

import NoCards from 'assets/images/no-cards.svg';
import Points from 'assets/images/points.svg';
import Rewards from 'assets/images/rewards.svg';
import { Title } from 'components/modals/sso-flow/components/email-input-button/styles';
import { Spinner } from 'components/spinner';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useIntl } from 'react-intl';
import { SearchParams } from 'shared/types';
import { isTruthyString } from 'utils/isTruthyString';

import { WelcomeBlock } from './components/welcome-block';
import { useWelcomeContent } from './hooks/use-welcome-content.hook';
import { Container, StyledButton } from './styles';

export const WelcomeContent = (): JSX.Element => {
  const intl = useIntl();
  const { isSlideAnimationEnable } = useSearchParams<SearchParams>();
  const isSlideAnimationExist = isTruthyString(isSlideAnimationEnable);
  const { handleButtonClick, redeemDescription, isLoaded } = useWelcomeContent();

  return isLoaded ? (
    <Container isSlideAnimationEnable={isSlideAnimationExist} data-testid="welcome-content-container">
      <Title>{intl.formatMessage({ id: 'welcomeModal.title' })}</Title>
      <WelcomeBlock icon={Points} title={redeemDescription} showBlock={redeemDescription?.length > 0} />
      <WelcomeBlock icon={Rewards} title={intl.formatMessage({ id: 'welcomeModal.receiveRewards' })} />
      <WelcomeBlock icon={NoCards} title={intl.formatMessage({ id: 'welcomeModal.noNewCards' })} />
      <StyledButton
        data-testid="next-button"
        onClick={handleButtonClick}
        text={intl.formatMessage({ id: 'buttons.next' })}
      />
    </Container>
  ) : <Spinner />;
};
