import { PaginationParams, PaginatedResponse } from 'shared/types/pagination';

export interface RewardsAPIResponse {
  data: GetRewardsResponse;
  total: number;
  count: number;
  paging: PaginatedResponse;
}

export interface GetRewardsBalanceResponse {
  data: RewardsBalance;
}

export interface GetRewardResponse {
  data: {
    reward: Reward;
  };
}

export interface GetRewardsResponse {
  rewards: Array<Reward>;
  total: number;
  count: number;
}

export interface Tier {
  id: string;
  spend_threshold: number;
}

export interface RewardsBalance {
  balance: number;
  tier_progress: number;
  tiers: Array<Tier>;
}

export interface RewardLocation extends Record<string, string> {
  id?: string;
  name?: string;
}

export interface Reward {
  id: string;
  image_url: string;
  title: string;
  description: string;
  cost: number;
  coupon_code?: string | null;
  coupon_code_type?: string | null;
  expiration_window: number;
  restrictions: string;
  locations: Array<RewardLocation>;
  instructions: string;
  activated_at: string | null;
  state: RewardsStateEnum;
  used_at: string | null;
  retire_at: string;
}

export interface RewardsState {
  rewards: Array<Reward>;
  usedRewards: Array<Reward>;
  rewardsBalance: RewardsBalance;
  reward: Reward;
  activateReward: AsyncActionTypes;
  finalizeReward: AsyncActionTypes;
  getRewards: AsyncActionTypes;
  getInfiniteRewards: AsyncActionTypes;
  getUsedRewards: AsyncActionTypes;
  getRewardsBalance: AsyncActionTypes;
  total: number;
  count: number;
  imageIsLoaded: boolean;
}

export enum RewardsStateEnum {
  AVAILABLE = 'available',
  USED = 'used',
  NOT_REDEEMED = 'not_redeemed',
  ACTIVE = 'active',
}

export enum SortRewardsByEnum {
  USED_AT = 'used_at',
}

export enum OrderByEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export interface DefaultParams {
  ownerType?: string;
  ownerId?: string;
}

export interface GetRewardsPayload extends PaginationParams, DefaultParams {
  states: Array<RewardsStateEnum>;
  sort_dir?: OrderByEnum;
  sort_by?: SortRewardsByEnum;
}

export interface ActivateRewardPayload extends DefaultParams {
  rewardId: string;
  rewardState: RewardsStateEnum;
}

export interface FinalizeRewardPayload extends DefaultParams {
  rewardId: string;
}
