import styled from 'styled-components';
import { colors } from 'shared/consts';
import { skeletonAnimation } from 'hocs/shared-styles';

export const Container = styled.div<{ isSettingsPage: boolean }>`
  padding: ${({ isSettingsPage }) => (isSettingsPage ? '16px 0' : '28px 16px')};
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RewardBlock = styled.div`
  width: 100%;
  height: 68px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium ?? colors.systemGreyMedium};
  ${skeletonAnimation};
`;

export const RewardsBalanceBlock = styled.div`
  width: 100%;
  height: 82px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium ?? colors.systemGreyMedium};
  ${skeletonAnimation};
`;

export const Bar = styled.div`
  width: 96px;
  height: 22px;
  border-radius: 4px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium ?? colors.systemGreyMedium};
  ${skeletonAnimation};
`;

export const Title = styled.div`
  width: 125px;
  height: 33px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium ?? colors.systemGreyMedium};
  ${skeletonAnimation};
`;

export const Subtitle = styled.div`
  width: 125px;
  height: 22px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.palette?.systemGreyMedium ?? colors.systemGreyMedium};
  ${skeletonAnimation};
`;
