import { styled } from 'styled-components';

export const CodeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};
  max-width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 24px 32px;
  font: 500 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;
