import * as BuildingEpics from 'store/building/epics';

import * as routerEpics from 'store/router/epics';
import * as errorsEpics from 'store/errors/epics';
import * as themeEpics from 'store/theme/epics';
import * as userEpics from 'store/user/epics';
import * as uiMetadataEpics from 'store/ui-metadata/epics';
import * as rewardsEpics from 'store/rewards/epics';
import * as introRewardEpics from 'store/intro-reward/epics';
import * as userAuthEpics from 'store/userAuth/epics';
import * as paymentMethodsEpics from 'store/payment-methods/epics';
import * as spreedlyEpics from 'store/spreedly/epics';
import * as cardsEpics from 'store/cards/epics';
import * as externalLinksEpics from 'store/external-links/epics';
import * as notificationSettingsEpic from 'store/notification-settings/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(routerEpics),
  ...Object.values(userEpics),
  ...Object.values(themeEpics),
  ...Object.values(errorsEpics),
  ...Object.values(BuildingEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(rewardsEpics),
  ...Object.values(introRewardEpics),
  ...Object.values(userAuthEpics),
  ...Object.values(paymentMethodsEpics),
  ...Object.values(spreedlyEpics),
  ...Object.values(cardsEpics),
  ...Object.values(externalLinksEpics),
  ...Object.values(notificationSettingsEpic),
);
