import { CouponCodeTypes } from 'components/coupon-code/constants';

export const barCodeMapper = new Map<CouponCodeTypes, string>([
  [CouponCodeTypes.BARCODE39, 'code39'],
  [CouponCodeTypes.BARCODE39EXTENDED, 'code39ext'],
  [CouponCodeTypes.BARCODE128, 'code128'],
  [CouponCodeTypes.BARCODE_UPC_A, 'upca'],
  [CouponCodeTypes.BARCODE_EAN_8, 'ean8'],
  [CouponCodeTypes.BARCODE_EAN_13, 'ean13'],
  [CouponCodeTypes.BARCODE25INTERLEAVED, 'interleaved2of5'],
  [CouponCodeTypes.QRCODE, 'qrcode'],
  [CouponCodeTypes.RAW, 'raw'],
]);
