import * as userActions from './actions';

import { NotFound } from 'store/user/errors';
import { UserState } from './types';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

const AUTH_TOKEN_LS_KEY = 'authToken';

export const initialState: UserState = {
  user: null,
  loading: false,
  token: localStorage.getItem(AUTH_TOKEN_LS_KEY),
  error: null,
  deleteUser: {
    status: null,
    error: null,
  },
};

const loading = createReducer(initialState.loading)
  .handleAction(userActions.externalLogin.request, () => true)
  .handleAction(userActions.externalLogin.success, () => false)
  .handleAction(userActions.externalLogin.failure, () => false)
  .handleAction(userActions.getCurrentUser.request, () => true)
  .handleAction(userActions.getCurrentUser.success, () => false)
  .handleAction(userActions.getCurrentUser.failure, () => false);

const user = createReducer(initialState.user)
  .handleAction(userActions.externalLogin.success, (_state, action) => action.payload.user)
  .handleAction(userActions.externalLogin.failure, () => null)
  .handleAction(userActions.getCurrentUser.success, (_state, action) => action.payload.user)
  .handleAction(userActions.getCurrentUser.failure, () => null);

const token = createReducer(initialState.token)
  .handleAction(userActions.externalLogin.success, (_state, action) => {
    const { token: authToken } = action.payload;
    localStorage.setItem(AUTH_TOKEN_LS_KEY, authToken);

    return authToken;
  })
  .handleAction(userActions.externalLogin.failure, () => {
    localStorage.removeItem(AUTH_TOKEN_LS_KEY);

    return null;
  })
  .handleAction(userActions.getCurrentUser.failure, (_state, action) => {
    if (action.payload instanceof NotFound) {
      localStorage.removeItem(AUTH_TOKEN_LS_KEY);
    }

    return null;
  });

const error = createReducer(initialState.error)
  .handleAction(userActions.externalLogin.request, () => null)
  .handleAction(userActions.externalLogin.success, () => null)
  .handleAction(userActions.externalLogin.failure, (_state, action) => action.payload.error)
  .handleAction(userActions.getCurrentUser.request, () => null)
  .handleAction(userActions.getCurrentUser.success, () => null)
  .handleAction(userActions.getCurrentUser.failure, (_state, action) => action.payload.error);

const deleteUserRequestHandler = (): AsyncActionTypes => ({
  status: ACTION_STATUSES.PENDING,
  error: null,
});

const deleteUserSuccessHandler = (): AsyncActionTypes => ({
  status: ACTION_STATUSES.FULFILLED,
  error: null,
});

const deleteUserFailureHandler = (
  _state: AsyncActionTypes,
  { payload: deleteUserError }: ActionType<typeof userActions.deleteUser.failure>,
): AsyncActionTypes => ({
  status: ACTION_STATUSES.REJECTED,
  error: deleteUserError.error,
});

const deleteUser = createReducer(initialState.deleteUser)
  .handleAction(userActions.deleteUser.request, deleteUserRequestHandler)
  .handleAction(userActions.deleteUser.success, deleteUserSuccessHandler)
  .handleAction(userActions.deleteUser.failure, deleteUserFailureHandler);

export default combineReducers({
  loading,
  user,
  token,
  error,
  deleteUser,
});
