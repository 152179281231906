import { ACTION_STATUSES, SETTINGS_PATHS } from 'shared/consts';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getCards } from 'store/cards/actions';
import { selectGetPaymentMethodsStatus } from 'store/payment-methods/selectors';
import { getPaymentMethods } from 'store/payment-methods/actions';
import { Card } from 'store/cards/types';
import { selectAreCardsRegistered, selectCards, selectGetCardsStatus } from 'store/cards/selectors';
import { replace } from 'store/router/actions';

interface UseSettingsListContentReturnValues {
  cards: Array<Card>;
  isLoading: boolean;
  handleRegisterCardClick: VoidFunction;
  isRegisterCardVisible: boolean;
}
export const usePaymentMethodsContent = (): UseSettingsListContentReturnValues => {
  const dispatch = useDispatch();
  const getPaymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const cards = useSelector(selectCards);
  const isLoading = useSelector(selectGetCardsStatus) === ACTION_STATUSES.PENDING;
  const areCardsRegistered = useSelector(selectAreCardsRegistered);

  useEffect(() => {
    dispatch(getCards.request());

    if (getPaymentMethodsStatus !== ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request());
    }
  }, []);

  const handleRegisterCardClick = useCallback(() => {
    dispatch(replace(SETTINGS_PATHS.REGISTER_CARD));
  }, []);

  return {
    cards,
    isLoading,
    handleRegisterCardClick,
    isRegisterCardVisible: !areCardsRegistered,
  };
};
