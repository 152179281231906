import * as actions from './actions';

import { isActionOf } from 'typesafe-actions';
import {
  IFrameErrorSpreedly,
  SpreedlyCreditCardConfig,
  SpreedlyError,
  SpreedlyPaymentMethod,
  SpreedlyPaymentMethodData,
} from './types';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';

export interface SpreedlyLifecycleParams {
  environmentKey: string;
  // The environmentKey field is required, but if omitted,
  // you will receive a console warning message and the transaction will still succeed.
  hiddenIframeLocation: string;
  // The DOM node that you'd like to inject hidden iframes
  challengeIframeLocation: string;
  // The DOM node that you'd like to inject the challenge flow
  transactionToken: string;
  // The token for the transaction - used to poll for state
  challengeIframeClasses: string;
  // The css classes that you'd like to apply to the challenge iframe.
  //
  // Note: This is where you'll change the height and width of the challenge
  //       iframe. You'll need to match the height and width option that you
  //       selected when collecting browser data with `Spreedly.ThreeDS.serialize`.
  //       For instance if you selected '04' for browserSize you'll need to have a
  //       CSS class that has width and height of 600px by 400px.
}

export interface LifecycleObject {
  start: () => void;
}

export interface LifecycleConstructor {
  new (argo0: SpreedlyLifecycleParams): LifecycleObject;
}
declare global {
  interface Window {
    Spreedly: {
      init: (arg0: string, arg1: Record<string, string>) => void;
      reload: () => void;
      tokenizeCreditCard: (arg0: SpreedlyCreditCardConfig) => void;
      validate: () => void;
      on: (arg0: string, arg1: unknown) => void;
      setFieldType: (arg0: string, arg1: string) => void;
      setLabel: (arg0: string, arg1: string) => void;
      setStyle: (arg0: string, arg1: string) => void;
      setNumberFormat: (arg0: string) => void;
      setPlaceholder: (arg0: string, arg1: string) => void;
      ThreeDS: {
        Lifecycle: LifecycleConstructor;
        serialize: (arg0?: string, arg1?: string) => string;
      };
    };
  }
}

export const initializeSpreedlyEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.initializeSpreedly.request)),
    mergeMap(({ payload: { envKey, numberEl, cvvEl } }) => {
      const initializeSpreedlyPromise = () =>
        new Promise<void>((resolve) => {
          window.Spreedly.init(envKey, { numberEl, cvvEl });
          resolve();
        });
      return from(initializeSpreedlyPromise()).pipe(mergeMap(() => of(actions.noop())));
    }),
  );

export const reloadSpreedlyEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.reloadSpreedly.request)),
    mergeMap(() => {
      const reloadSpreedlyPromise = () =>
        new Promise<void>((resolve) => {
          window.Spreedly.reload();
          resolve();
        });
      return from(reloadSpreedlyPromise()).pipe(mergeMap(() => of(actions.noop())));
    }),
  );

// Handle 'ready' event after firing Spreedly.init() or Spreedly.reload()
export const onReadySpreedlyEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([actions.reloadSpreedly.request, actions.initializeSpreedly.request])),
    mergeMap(() => {
      const spreedlyReadyPromise = () =>
        new Promise<void>((resolve) => {
          window.Spreedly.on('ready', () => {
            resolve();
          });
        });
      return from(spreedlyReadyPromise()).pipe(
        mergeMap(() =>
          of(actions.setReadySpreedly(), actions.initializeSpreedly.success(), actions.reloadSpreedly.success()),
        ),
      );
    }),
  );

export const spreedlyTokenizeCreditCardEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.tokenizeCreditCardSpreedly.request)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const spreedlyTokenizeCreditCardPromise = () =>
        new Promise<void>((resolve) => {
          window.Spreedly.tokenizeCreditCard(state.spreedly.creditCardConfigSpreedly);
          resolve();
        });
      return from(spreedlyTokenizeCreditCardPromise()).pipe(mergeMap(() => of(actions.noop())));
    }),
  );

// Handle 'paymentMethod' event after after firing Spreedly.tokenizeCreditCard().
// This does not map to its own action as we will not track payment information in state.
export const onPaymentMethodEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.tokenizeCreditCardSpreedly.request)),
    mergeMap(() => {
      const onPaymentMethodPromise = () =>
        new Promise((resolve) => {
          window.Spreedly.on('paymentMethod', (token: string, paymentMethod: SpreedlyPaymentMethod) => {
            resolve({ token, paymentMethod });
          });
        });
      return from(onPaymentMethodPromise()).pipe(
        mergeMap((result: SpreedlyPaymentMethodData) =>
          of(
            actions.tokenizeCreditCardSpreedly.success(result),
          ),
        ),
      );
    }),
  );

// Handle Spreedly 'errors' event after firing Spreedly.tokenizeCreditCard()
export const onErrorsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.tokenizeCreditCardSpreedly.request)),
    mergeMap(() => {
      const onErrorsPromise = () =>
        new Promise((resolve) => {
          window.Spreedly.on('errors', (errors: Array<SpreedlyError>) => {
            resolve(errors);
          });
        });
      return from(onErrorsPromise()).pipe(
        mergeMap((result: Array<SpreedlyError>) => of(actions.tokenizeCreditCardSpreedly.failure(result))),
      );
    }),
  );

export const onInitializeSpreedlyErrorEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.initializeSpreedly.request)),
    mergeMap(() => {
      const onErrorsPromise = () =>
        new Promise((resolve) => {
          window.Spreedly.on('consoleError', (iframeError: IFrameErrorSpreedly) => {
            resolve(iframeError?.msg);
          });
        });
      return from(onErrorsPromise()).pipe(
        mergeMap((error: IFrameErrorSpreedly) => of(actions.initializeSpreedly.failure(error))),
      );
    }),
  );

export const onReloadSpreedlyErrorEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.reloadSpreedly.request)),
    mergeMap(() => {
      const onErrorsPromise = () =>
        new Promise((resolve) => {
          window.Spreedly.on('consoleError', (iframeError: IFrameErrorSpreedly) => {
            resolve(iframeError?.msg);
          });
        });
      return from(onErrorsPromise()).pipe(
        mergeMap((error: IFrameErrorSpreedly) => of(actions.reloadSpreedly.failure(error))),
      );
    }),
  );
