import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_STATUSES, BUILDING, HISTORY_REWARDS_LIST_LIMIT } from 'shared/consts';
import {
  selectGetUsedRewardsStatus,
  selectRewardsTotal,
} from 'store/rewards/selectors';
import { getUsedRewards } from 'store/rewards/actions';
import { selectBuildingUuid } from 'store/building/selectors';

interface UseHistoryInfiniteScrollProps {
  contentContainerRef: React.RefObject<HTMLDivElement>;
  totalDisplayedRewards: number;
}

interface UseHistoryInfiniteScrollReturnValues {
  isHistoryInfiniteScrolling: boolean;
}

export const useHistoryInfiniteScroll = ({
  contentContainerRef,
  totalDisplayedRewards,
}: UseHistoryInfiniteScrollProps): UseHistoryInfiniteScrollReturnValues => {
  const dispatch = useDispatch();
  const isHistoryInfiniteScrolling = useSelector(selectGetUsedRewardsStatus) === ACTION_STATUSES.PENDING;
  const buildingUuid = useSelector(selectBuildingUuid);
  const totalRewards = useSelector(selectRewardsTotal);
  const initiateInfiniteScrollThreshold = 20; // px

  const payload = {
    ownerId: buildingUuid,
    ownerType: BUILDING,
    limit: HISTORY_REWARDS_LIST_LIMIT,
  };

  const onScroll = useCallback(() => {
    const containerRef = contentContainerRef?.current;

    if (isHistoryInfiniteScrolling) {
      return;
    }

    const scrollTop = containerRef?.scrollTop;
    const offsetHeight = containerRef?.offsetHeight;
    const scrollHeight = containerRef?.scrollHeight;

    const isScrollingToBottom = scrollTop + offsetHeight >= scrollHeight - initiateInfiniteScrollThreshold;

    if (isScrollingToBottom && totalDisplayedRewards < totalRewards) {
      dispatch(getUsedRewards.request({
        ...payload,
        offset: totalDisplayedRewards,
      }));
    }
  }, [isHistoryInfiniteScrolling, contentContainerRef, totalDisplayedRewards, totalRewards, dispatch]);

  useEffect(() => {
    const containerRef = contentContainerRef?.current;
    containerRef?.addEventListener('scroll', onScroll);

    return () => {
      containerRef?.removeEventListener('scroll', onScroll);
    };
  }, [contentContainerRef, onScroll]);

  return { isHistoryInfiniteScrolling };
};
