import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const StyledTitle = styled.span`
  display: flex;
  flex-shrink: 0;
  font: 500 18px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
  max-width: 255px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
  text-align: center;
  width: 100%;
  height: 48px;
`;

export const StyledSkipButton = styled(Button)`
  display: flex;
  height: fit-content;
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  background-color: transparent;
  padding: 4px 24px;
`;

export const ErrorMessage = styled.span`
  font: italic 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
  margin-top: -16px;
  padding-bottom: 4px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardTypesNotice = styled.div`
  text-align: center;
  font: 400 12px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;
