import React from 'react';
import { useIntl } from 'react-intl';
import { EmailInputButton } from '../email-input-button';
import { useEmailInputButton } from '../email-input-button/hooks/use-email-input-button.hook';
import { LinkText } from '../sign-in-content/styles';
import { useSignUpContent } from './hooks/use-sign-up-content.hook';
import { THANX_BRAND_NAME } from 'shared/consts';

export const SignUpContent = (): JSX.Element => {
  const intl = useIntl();
  const {
    email, handleChangeEmail, onRegisterButtonClick, isRegisterFailed, isButtonLoading,
  } = useEmailInputButton();
  const { handleLinkClick, isRelatedWhitelabel, logoUrl } = useSignUpContent();

  const subtitle = intl.formatMessage(
    { id: 'signUpModal.subtitle' },
    {
      withBrand: isRelatedWhitelabel,
      brand: THANX_BRAND_NAME,
      linkText: <LinkText onClick={handleLinkClick}>{intl.formatMessage({ id: 'signInModal.signIn' })}</LinkText>,
    },
  ) as string;

  return (
    <EmailInputButton
      title={intl.formatMessage({ id: 'signUpModal.title' })}
      subtitle={subtitle}
      value={email}
      onChange={handleChangeEmail}
      onClick={onRegisterButtonClick}
      logoUrl={logoUrl}
      isButtonLoading={isButtonLoading}
      isExternalError={isRegisterFailed}
    />
  );
};
