import { barCodeMapper } from 'components/coupon-code/bar-code.mapper';
import { Barcode, DefaultCodeRender, QRCode } from './components';
import { CouponCodeTypes } from 'components/coupon-code/constants';
import React from 'react';

interface CouponCodeProps {
  code: string;
  codeType?: string;
}

export const CouponCode = ({ code, codeType }: CouponCodeProps) => {
  let component;
  switch (codeType) {
  case CouponCodeTypes.QRCODE:
    component = <QRCode value={code} />;
    break;
  case CouponCodeTypes.BARCODE39:
  case CouponCodeTypes.BARCODE39EXTENDED:
  case CouponCodeTypes.BARCODE128:
  case CouponCodeTypes.BARCODE_UPC_A:
  case CouponCodeTypes.BARCODE_EAN_8:
  case CouponCodeTypes.BARCODE_EAN_13:
  case CouponCodeTypes.BARCODE25INTERLEAVED:
  case CouponCodeTypes.RAW:
    component = <Barcode code={code} codeType={barCodeMapper.get(codeType)} />;
    break;
  default:
    component = <DefaultCodeRender code={code} />;
    break;
  }

  return component;
};
