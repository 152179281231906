import styled, { css } from 'styled-components';
import { Button, ExclamationMarkTriangle, Confirmed } from '@hqo/react-components-library';
import { slideInRight } from 'hocs/shared-styles';

export const ModalContentContainer = styled.div<{ isSlideAnimationEnable?: boolean, gap: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
  ${({ isSlideAnimationEnable }) => isSlideAnimationEnable && 'transform: translate(-50%, -50%);'}
  ${({ isSlideAnimationEnable }) => isSlideAnimationEnable
  && css`animation: ${slideInRight} 0.5s forwards;`}
`;

export const ModalContentTitle = styled.div<{ top: number }>`
  font: 700 28px/normal ${({ theme }) => theme.fonts.join()};
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.fontColor};
  top: ${({ top }) => `${top}px`};
  text-align: center;
`;

export const ModalContentSubtitle = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
  width: 100%;
`;

export const StyledCTAButton = styled(Button)`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  background: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  border-color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  height: 48px;
  width: 100%;

  &:hover:not([disabled]), &:focus:not([disabled]) {
    border-color: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    background: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    opacity: 0.9;
  }
`;

export const SuccessIconWrapper = styled.div`
  height: 45px;
  width: 45px;
  background-color: ${({ theme }) => theme.colors.palette.systemGreenLight};
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
`;

export const SuccessIcon = styled(Confirmed)`
  color: ${({ theme }) => theme.colors.palette.$darkGreen};
  font-size: 20px;
  padding: 12px;
`;

export const FailedIconWrapper = styled(SuccessIconWrapper)`
  background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};
`;

export const FailedIcon = styled(ExclamationMarkTriangle)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  padding: 11px;
`;
