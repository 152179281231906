import {
  useCallback, useContext, useEffect,
} from 'react';
import { HqoClientSdk, Handler, SdkEvents } from '@hqo/hqo-miniapp-client-sdk';
import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';

export const getMiniappSdkClient = (appUuid?: string): HqoClientSdk =>
  new HqoClientSdk({
    develop: false,
    app_uuid: appUuid,
    init: false,
    init_timeout: null,
  });

export const useMiniappSdk = (): HqoClientSdk | null => useContext(MiniappSdkContext);

export const useMiniappSdkEventHandler = (
  eventName: SdkEvents,
  handler: Handler,
  handlerDependencies: unknown[],
): void => {
  const client = useMiniappSdk();
  const eventHandler = useCallback(handler, handlerDependencies);

  useEffect(() => {
    client?.on(eventName, eventHandler);
  }, [eventName, eventHandler]);
};
