/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import { BackArrowMobileHeader } from 'components/back-arrow-mobile-header';
import { useBackArrowMobileHeader } from 'components/back-arrow-mobile-header/hooks/use-back-arrow-mobile-header';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { RewardActivationModal } from 'components/modals/reward-activation-modal';
import { useRewardActivationModal } from 'components/modals/reward-activation-modal/hooks/use-reward-activation-modal.hook';
import { RewardContent } from 'pages/reward-details-page/components/reward-content';
import { IMAGE_PLACEHOLDER_HEIGHT } from 'pages/reward-details-page/constants';
import {
  ButtonContainer,
  ButtonWrapper,
  Container,
  CtaButton,
  FooterContainer,
  PageContainer,
  RewardContainer,
  RewardImageSkeleton,
  ScrollableTrayContainer,
  StyledImage,
  StyledMobileHeaderWrapper,
} from 'pages/reward-details-page/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { imageIsLoadedSelector, selectReward, selectRewardById } from 'store/rewards/selectors';

export const RewardDetailsPage = (): JSX.Element => {
  const client = useMiniappSdk();
  const { rewardId } = useParams();
  const rewardById = useSelector(selectRewardById(rewardId));
  const activatedReward = useSelector(selectReward);
  const reward = rewardById || activatedReward;
  const backArrowMobileHeaderRef = useRef(null);
  const rewardDetailsContainerRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  const [imageHeight, setImageHeight] = useState<number>(IMAGE_PLACEHOLDER_HEIGHT);
  const imageExist = useSelector(imageIsLoadedSelector);
  const {
    isReverseAnimation, isActivated, isModalVisible, ctaLabelValues, modalContent,
  } = useRewardActivationModal();

  useBackArrowMobileHeader({
    backArrowMobileHeaderRef,
    containerRef: rewardDetailsContainerRef,
    scrollableContainerRef,
  });

  useEffect(() => {
    client?.header.hideHeader();
  }, [client]);

  return (
    <PageContainer>
      <StyledMobileHeaderWrapper>
        <BackArrowMobileHeader
          backArrowMobileHeaderRef={backArrowMobileHeaderRef}
        />
      </StyledMobileHeaderWrapper>
      <Container ref={rewardDetailsContainerRef}>
        <RewardContainer>
          <StyledImage
            src={reward?.image_url}
            alt="reward image"
            skeleton={imageExist ? null : <RewardImageSkeleton />}
            setImageHeight={setImageHeight}
          />
          <ScrollableTrayContainer
            minHeight={imageHeight}
            marginTop={imageHeight}
            isMobileDevice
            data-testid="scrollable-tray"
            ref={scrollableContainerRef}
          >
            <RewardContent reward={reward} isActivated={isActivated} />
          </ScrollableTrayContainer>
        </RewardContainer>
        <FooterContainer isMobileDevice>
          <ButtonContainer>
            <ButtonWrapper>
              <CtaButton
                disabled={ctaLabelValues.isDisabled}
                loading={ctaLabelValues.isLoading}
                text={ctaLabelValues.label}
                onClick={ctaLabelValues.onClick}
                variant={ctaLabelValues.variant}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </FooterContainer>
        <RewardActivationModal
          title={modalContent.title}
          subtitles={modalContent.subtitles}
          isError={modalContent.isError}
          isVisible={isModalVisible}
          isReverseAnimation={isReverseAnimation}
          buttons={modalContent.buttons}
        />
      </Container>
    </PageContainer>
  );
};
