import {
  SavePaymentMethodRequest,
  SavePaymentMethodResponse,
  SavedPaymentMethod,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getPaymentMethods = createAsyncAction(
  'GET_PAYMENT_METHODS_REQUEST',
  'GET_PAYMENT_METHODS_SUCCESS',
  'GET_PAYMENT_METHODS_FAILURE',
)<[undefined, undefined], Array<SavedPaymentMethod>, FailureActionPayload>();

export const savePaymentMethod = createAsyncAction(
  'SAVE_PAYMENT_METHOD_REQUEST',
  'SAVE_PAYMENT_METHOD_SUCCESS',
  'SAVE_PAYMENT_METHOD_FAILURE',
)<SavePaymentMethodRequest, SavePaymentMethodResponse, FailureActionPayload>();

export const deletePaymentMethod = createAsyncAction(
  'DELETE_PAYMENT_METHOD_REQUEST',
  'DELETE_PAYMENT_METHOD_SUCCESS',
  'DELETE_PAYMENT_METHOD_FAILURE',
)<number, number, FailureActionPayload>();

export const resetSavePaymentMethod = createAction('RESET_SAVE_PAYMENT_METHOD')<void>();
