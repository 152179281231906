import React from 'react';
import {
  Container, Title, Bar, Subtitle, RewardsBalanceBlock, RewardBlock,
} from './styles';

interface SkeletonProps {
  isSettingsPage?: boolean;
}

export const Skeleton = ({ isSettingsPage = false }: SkeletonProps): JSX.Element => (
  <Container data-testid="skeleton" isSettingsPage={isSettingsPage}>
    { isSettingsPage
      ? (
        <RewardBlock />
      )
      : (
        <>
          <Title />
          <Subtitle />
          <RewardsBalanceBlock />
          <Bar />
        </>
      )}

    <RewardBlock />
    <RewardBlock />
    <RewardBlock />
    <RewardBlock />
    <RewardBlock />
    <RewardBlock />
  </Container>
);
