import { PaymentState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { APPLE_PAY_METHOD_TYPE, CARD_BRANDS } from 'shared/consts/payment-method-consts';

export const paymentSelector = (state: RootState): PaymentState => state.paymentMethods;

export const selectPaymentMethods = createSelector(
  [paymentSelector],
  (payment) =>
    payment.paymentMethods?.filter(
      (paymentMethod) =>
        paymentMethod.payment_method_type !== APPLE_PAY_METHOD_TYPE
        && paymentMethod.card_brand !== CARD_BRANDS.DISCOVER,
    ) ?? [],
);

export const selectGetPaymentMethodsStatus = createSelector(
  [paymentSelector],
  (payment) => payment.getPaymentMethods.status,
);

export const selectGetPaymentMethodsError = createSelector(
  [paymentSelector],
  (payment) => payment.getPaymentMethods.error,
);

export const selectSavePaymentMethodStatus = createSelector(
  [paymentSelector],
  (payment) => payment.savePaymentMethod.status,
);

export const selectSavePaymentMethodError = createSelector(
  [paymentSelector],
  (payment) => payment.savePaymentMethod.error,
);
