import { Observable, of } from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom, mergeMap,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { getNotificationSettings, patchNotificationSettings } from './actions';
import { NotificationSettings } from 'store/notification-settings/types';

export const getNotificationSettingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(getNotificationSettings.request)),
    withLatestFrom(state$),
    switchMap(
      ([, state]): Observable<RootAction> =>
        apiClient(state)
          .getNotificationSettings({ ownerId: state.building.building.uuid })
          .pipe(
            map((xhrPayload) => getNotificationSettings.success(xhrPayload.response.data.notification_settings)),
            catchError((error) => of(getNotificationSettings.failure({ error, errorCode: getErrorCode(error) }))),
          ),
    ),
  );

export const patchNotificationSettingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(patchNotificationSettings.request)),
    withLatestFrom(state$),
    mergeMap(
      ([{ payload }, state]): Observable<RootAction> =>
        apiClient(state)
          .patchNotificationSettings(
            { ownerId: state.building.building.uuid },
            { ...payload, id: state.notificationSettings?.notificationSettings?.id },
          )
          .pipe(
            map((xhrPayload) =>
              patchNotificationSettings.success({
                notificationSettings: xhrPayload.response.data.notification_settings,
                patchedSetting: Object.keys(payload) as Array<Exclude<keyof NotificationSettings, 'id'>>,
              }),
            ),
            catchError((error) =>
              of(
                patchNotificationSettings.failure({
                  error: {
                    error,
                    errorCode: getErrorCode(error),
                  },
                  patchedSetting: Object.keys(payload) as Array<Exclude<keyof NotificationSettings, 'id'>>,
                }),
              ),
            ),
          ),
    ),
  );
