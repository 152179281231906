import React from 'react';
import { Container, Title, Wrapper } from './styles';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

interface RegisterCardProps {
  onClick: VoidFunction;
  registerCardRef?: React.RefObject<HTMLDivElement>;
}

export const RegisterCard = ({ onClick, registerCardRef }: RegisterCardProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Wrapper className="register-card-wrapper" ref={registerCardRef}>
      <Container className="register-card-container" data-testid="register-card-container">
        <Title>{intl.formatMessage({ id: 'startRewardsEarning' })}</Title>
        <Button
          data-testid="register-card-button"
          text={intl.formatMessage({ id: 'registerACard' })}
          onClick={onClick}
        />
      </Container>
    </Wrapper>
  );
};
