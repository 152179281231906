import React from 'react';

import { BottomModalContentButtons } from 'components/bottom-modal-content/types';
import { useIntl } from 'react-intl';

import { BottomModalContent } from '../../bottom-modal-content';
import { StyledBottomMobileModal } from 'components/modals/styled-bottom-mobile-modal';

interface RemoveCardModalProps {
  isVisible: boolean;
  isError: boolean;
  title: string;
  subtitles: Array<(string | JSX.Element)>;
  isReverseAnimation: boolean;
  buttons: Array<BottomModalContentButtons>;
}

export const RewardActivationModal = ({
  isVisible,
  isReverseAnimation,
  title,
  subtitles,
  isError,
  buttons,
}: RemoveCardModalProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledBottomMobileModal
      reverseAnimation={isReverseAnimation}
      isVisible={isVisible}
      content={(
        <BottomModalContent
          title={title}
          subtitles={subtitles}
          buttons={buttons}
          isError={isError}
          errorText={intl.formatMessage({ id: 'rewardActivation.errorText' })}
        />
      )}
    />
  );
};
