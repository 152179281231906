import { DefaultThemeWithFonts, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { selectBrandTheme } from 'store/theme/selectors';

export const useGetTheme = (): DefaultThemeWithFonts => {
  const brandTheme = useSelector(selectBrandTheme);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...defaultTheme.colors,
          $primary: brandTheme.primary1 ?? brandTheme.primary_color,
          $secondary: brandTheme.primary2 ?? brandTheme.secondary_color,
          primary: brandTheme.primary1 ?? brandTheme.primary_color,
          secondary: brandTheme.primary2 ?? brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      };
    }

    return defaultTheme;
  }, [brandTheme]);
};
