import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeSpreedly,
  resetCreditCardConfigSpreedly,
  resetTokenizeCreditCardSpreedly,
  setCreditCardConfigSpreedly,
  setValidationSpreedly,
} from 'store/spreedly/actions';
import { InputBorderStyles } from '../styles';
import { SpreedlyValidationInputProperties } from 'store/spreedly/types';
import { formatExpirationDate } from 'utils/formatExpirationDate';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { selectBrandTheme } from 'store/theme/selectors';
import { hqoTheme } from '@hqo/react-components-library';
import {
  selectSpreedlyInitializeStatus,
  selectSpreedlyReady,
  selectSpreedlyReloadStatus,
  selectSpreedlyTokenizeCreditCardStatus,
  selectSpreedlyValidation,
} from 'store/spreedly/selectors';
import { useIntl } from 'react-intl';
import { SpreedlySetState, UseRegisterNewCardReturnValues } from './types';
import {
  ACTION_STATUSES,
} from 'shared/consts';
import {
  MIN_ZIP_CODE_LENGTH, SPREEDLY_CARD_ID, SPREEDLY_CARD_REF, SPREEDLY_CVV_ID, SPREEDLY_CVV_REF,
} from 'shared/consts/payment-method-consts';
import { isExpDateValid } from 'utils/isExpDateValid';

// eslint-disable-next-line max-lines-per-function
export const useRegisterNewCard = (): UseRegisterNewCardReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentUser] = useCurrentUser();
  const brandTheme = useSelector(selectBrandTheme);
  const useSpreedlyState = (init: boolean) => useState<boolean>(init) as [boolean, SpreedlySetState];
  const defaultEmail = currentUser?.email ?? '';

  const firstName = currentUser?.first_name ?? '';
  const lastName = currentUser?.last_name ?? '';

  const spreedlyCardElementRef = useRef(null);
  const spreedlyCvvElementRef = useRef(null);

  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [expDate, setExpDate] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  const [triggerErrorZipCode, setTriggerErrorZipCode] = useState<boolean>(false);
  const [triggerErrorExpDate, setTriggerErrorExpDate] = useState<boolean>(false);
  const [displayAddPaymentMethodErrorMessage, setDisplayAddPaymentMethodErrorMessage] = useState<boolean>(false);
  const [errorSpreedlyCardField, setErrorSpreedlyCardField] = useState<boolean>(false);
  const [errorSpreedlyCvvField, setErrorSpreedlyCvvField] = useState<boolean>(false);
  const [isSpreedlyCardFocused, setIsSpreedlyCardFocused] = useSpreedlyState(false);
  const [isSpreedlyCvvFocused, setIsSpreedlyCvvFocused] = useSpreedlyState(false);
  const fonts = brandTheme?.font?.body?.font_family
    ? [`${brandTheme?.font?.body?.font_family}`, ...hqoTheme.fonts]
    : hqoTheme.fonts;
  const fontsString = fonts.join(',');
  const spreedlyFontColor = brandTheme?.primary_font_color ?? hqoTheme.colors.fontColor;
  const SpreedlyIFrameStyle = `width:100%;height:100%;font:400 14px/18px ${fontsString};color:${spreedlyFontColor};`;
  const spreedlyInitializeStatus = useSelector(selectSpreedlyInitializeStatus);
  const spreedlyReloadStatus = useSelector(selectSpreedlyReloadStatus);
  const spreedlyTokenizeCreditCardStatus = useSelector(selectSpreedlyTokenizeCreditCardStatus);
  const spreedlyReady = useSelector(selectSpreedlyReady);
  const spreedlyValidation = useSelector(selectSpreedlyValidation);

  useEffect(() => {
    dispatch(
      initializeSpreedly.request({
        envKey: process.env.REACT_APP_SPREEDLY_ENV_KEY,
        numberEl: SPREEDLY_CARD_ID,
        cvvEl: SPREEDLY_CVV_ID,
      }),
    );
  }, []);

  const styleSpreedlyIFrameBorder = useCallback(
    (
      spreedlyElement: React.MutableRefObject<HTMLElement>,
      type: string,
      setIsFocused: SpreedlySetState,
      isValidInput: boolean,
    ) => {
      const spreedlyElementValid = spreedlyElement?.current?.style?.border != null;
      const currentElement = spreedlyElement;

      switch (type) {
      case 'input':
      case 'focus':
        if (spreedlyElementValid) {
          currentElement.current.style.border = InputBorderStyles.focus;
          setIsFocused(true);
        }
        break;
      case 'blur':
        if (spreedlyElementValid) {
          currentElement.current.style.border = isValidInput ? InputBorderStyles.default : InputBorderStyles.error;
          setIsFocused(false);
        }
        break;
      default:
        break;
      }
    },
    [spreedlyValidation.validCvv, spreedlyValidation.validNumber],
  );

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (
      (spreedlyReady && spreedlyInitializeStatus === ACTION_STATUSES.FULFILLED)
      || spreedlyReloadStatus === ACTION_STATUSES.FULFILLED
    ) {
      window.Spreedly.setFieldType(SPREEDLY_CARD_REF, 'text');
      window.Spreedly.setNumberFormat('prettyFormat');
      window.Spreedly.setStyle(SPREEDLY_CARD_REF, SpreedlyIFrameStyle);
      window.Spreedly.setStyle(SPREEDLY_CVV_REF, SpreedlyIFrameStyle);
      window.Spreedly.setLabel(SPREEDLY_CARD_REF, intl.formatMessage({ id: 'registerCardModal.creditCardNumber' }));
      window.Spreedly.setLabel(SPREEDLY_CVV_REF, intl.formatMessage({ id: 'registerCardModal.cvv' }));
      window.Spreedly.setPlaceholder(
        SPREEDLY_CARD_REF, intl.formatMessage({ id: 'registerCardModal.placeholders.creditCardNumber' }),
      );
      window.Spreedly.setPlaceholder(
        SPREEDLY_CVV_REF, intl.formatMessage({ id: 'registerCardModal.placeholders.cvv' }),
      );

      window.Spreedly.on(
        'fieldEvent',
        // eslint-disable-next-line max-lines-per-function
        (name: string, eventType: string, _activeElement: string, inputData: SpreedlyValidationInputProperties) => {
          if (eventType === 'input') {
            dispatch(setValidationSpreedly(inputData));
          }
          switch (name) {
          case SPREEDLY_CARD_REF:
            if (eventType === 'input') {
              setErrorSpreedlyCardField(false);
            } else if (eventType === 'blur') {
              setErrorSpreedlyCardField(!spreedlyValidation.validNumber);
            }
            styleSpreedlyIFrameBorder(
              spreedlyCardElementRef,
              eventType,
              setIsSpreedlyCardFocused,
              spreedlyValidation.validNumber,
            );
            break;
          case SPREEDLY_CVV_REF:
            if (eventType === 'input') {
              setErrorSpreedlyCvvField(false);
            } else if (eventType === 'blur') {
              setErrorSpreedlyCvvField(!spreedlyValidation.validCvv);
            }

            styleSpreedlyIFrameBorder(
              spreedlyCvvElementRef,
              eventType,
              setIsSpreedlyCvvFocused,
              spreedlyValidation.validCvv,
            );
            break;
          default:
            break;
          }
        },
      );
    }
  }, [spreedlyReady, spreedlyInitializeStatus, spreedlyReloadStatus, spreedlyValidation]);

  const setFormatExpDate = useCallback(
    (rawExpDate: string) => {
      const monthIndex = 0;
      const yearIndex = 1;
      const maxYearLength = 4;
      const formattedExpDate = formatExpirationDate(rawExpDate);
      const fields = formattedExpDate.split('/');
      const formattedMonth = fields[monthIndex];
      const formattedYear = fields[yearIndex] && fields[yearIndex].length < maxYearLength
        ? `20${fields[yearIndex]}`
        : fields[yearIndex];

      setExpDate(formattedExpDate);
      setMonth(formattedMonth);
      setYear(formattedYear);
    },
    [formatExpirationDate, setExpDate, setMonth, setYear],
  );

  const isZipCodeValid = useCallback(
    (formattedZipCode: string): boolean => formattedZipCode.length >= MIN_ZIP_CODE_LENGTH,
    [MIN_ZIP_CODE_LENGTH],
  );

  const handleOnChangeExpDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorExpDate(false);
      setFormatExpDate(event.target.value);
    },
    [setTriggerErrorExpDate],
  );

  const handleOnChangeZipCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorZipCode(false);
      setZipCode(event.target.value);
    },
    [setTriggerErrorZipCode, setZipCode],
  );

  const handleSpreedlyCardOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validNumber) element.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validCvv) element.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validCvv],
  );

  const handleSpreedlyCardOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validNumber) {
        element.style.border = isSpreedlyCardFocused ? InputBorderStyles.focus : InputBorderStyles.default;
      }
    },
    [isSpreedlyCardFocused, spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validCvv) {
        element.style.border = isSpreedlyCvvFocused
          ? InputBorderStyles.focus
          : InputBorderStyles.default;
      }
    },
    [isSpreedlyCvvFocused, spreedlyValidation.validCvv],
  );

  const handleKeyboardEnter = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  }, []);

  const handleZipCodeOnBlur = useCallback(() => {
    setTriggerErrorZipCode(true);
  }, [setTriggerErrorZipCode]);

  const handleExpDateOnBlur = useCallback(() => {
    setTriggerErrorExpDate(true);
  }, [setTriggerErrorExpDate]);

  useEffect(() => {
    if (isExpDateValid(expDate)
    && isZipCodeValid(zipCode)
    && spreedlyValidation.validCvv
    && spreedlyValidation.validNumber) {
      setDisplayAddPaymentMethodErrorMessage(false);
      dispatch(
        setCreditCardConfigSpreedly({
          first_name: firstName,
          last_name: lastName,
          month,
          year,
          zip: zipCode,
          email: defaultEmail,
        }),
      );
    } else if (spreedlyTokenizeCreditCardStatus) {
      dispatch(resetTokenizeCreditCardSpreedly());
      dispatch(resetCreditCardConfigSpreedly());
    }
  }, [
    firstName,
    lastName,
    month,
    year,
    zipCode,
    defaultEmail,
    spreedlyValidation.validCvv,
    spreedlyValidation.validNumber,
  ]);

  return {
    expDate,
    zipCode,
    triggerErrorZipCode,
    triggerErrorExpDate,
    displayAddPaymentMethodErrorMessage,
    isZipCodeValid,
    handleOnChangeExpDate,
    handleOnChangeZipCode,
    handleSpreedlyCardOnMouseEnter,
    handleSpreedlyCvvOnMouseEnter,
    handleSpreedlyCardOnMouseLeave,
    handleSpreedlyCvvOnMouseLeave,
    handleKeyboardEnter,
    handleZipCodeOnBlur,
    handleExpDateOnBlur,
    spreedlyCvvElementRef,
    spreedlyCardElementRef,
    errorSpreedlyCardField,
    errorSpreedlyCvvField,
  };
};
