import { ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS } from '../constants';

export interface SwitchDefaultFilterProps {
  children: Array<JSX.Element>;
  value: ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS;
}

export const SwitchModal = ({ children, value }: SwitchDefaultFilterProps): JSX.Element | null => (
  children.find((child) => child.props['data-value'] === value) ?? null
);
