import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { IntroRewardState } from 'store/intro-reward/types';

export const introRewardStateSelector = (state: RootState): IntroRewardState => state.introReward;

export const selectIntroReward = createSelector(
  introRewardStateSelector,
  (introRewardState) => introRewardState.introReward,
);

export const selectEarnDescription = createSelector(
  introRewardStateSelector,
  (introRewardState) => introRewardState.introReward?.earn_description,
);

export const selectRedeemDescription = createSelector(
  introRewardStateSelector,
  (introRewardState) => introRewardState.introReward?.redeem_description,
);

export const selectIntroRewardStatus = createSelector(
  introRewardStateSelector,
  (introRewardState) => introRewardState.getIntroReward.status,
);
