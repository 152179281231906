import { FormField } from '@hqo/react-components-library/dist/atoms/form-field';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import styled from 'styled-components';

export const BorderColors = {
  default: hqoTheme.colors.palette.systemGreyMedium,
  hover: defaultTheme.colors.$darkGrayishBlue,
  focus: defaultTheme.colors.$sharedGreyDark,
  error: defaultTheme.colors.$adminPink,
};

export const InputBorderStyles = {
  default: `1px solid ${BorderColors.default}`,
  hover: `1px solid ${BorderColors.hover}`,
  focus: `1px solid ${BorderColors.focus}`,
  error: `1px solid ${BorderColors.error}`,
};

export const StyledWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const Label = styled.div`
  font: normal 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$greyDark};
`;

export const SpreedlyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const SpreedlyCVVWrapper = styled.div`
  width: 65px;
`;

export const SpreedlyIFrameContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border: ${InputBorderStyles.default};
  padding: 13px 16px;
  background-color: transparent;
  border-radius: 4px;
  transition: border-color 0.15s ease-out;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledFormField = styled(FormField)`
  width: 82px;
  font: normal 14px/18px ${({ theme }) => theme.fonts.join()};

  .field.error {
    padding-right: 16px;
  }
`;

export const CvvAndExpirationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${StyledFormField} {
    width: 92px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;

  span {
    padding: 0;
  }

  .exclamation-mark {
    display: none;
  }
`;

export const SpreedlyErrorLabel = styled.div`
  font: italic 12px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;
