import React from 'react';
import {
  Container, Title, PointsBlock, Wrapper,
} from './styles';
import { useSelector } from 'react-redux';
import { selectRewardsBalance } from 'store/rewards/selectors';
import { useIntl } from 'react-intl';
import { DEFAULT_LOCALE, EMPTY_BALANCE } from 'shared/consts';

interface RewardsbalanceProps {
  rewardsBalanceRef: React.RefObject<HTMLDivElement>;
}

export const RewardsBalance = ({ rewardsBalanceRef }: RewardsbalanceProps): JSX.Element => {
  const intl = useIntl();
  const rewardsBalance = useSelector(selectRewardsBalance);
  const { balance } = rewardsBalance || {};
  const points = balance ? balance.toLocaleString(DEFAULT_LOCALE) : EMPTY_BALANCE;

  return (
    <Wrapper ref={rewardsBalanceRef}>
      <Container data-testid="rewards-balance-container">
        <Title>{intl.formatMessage({ id: 'pointsBalance' })}</Title>
        <PointsBlock>{intl.formatMessage({ id: 'points' }, { count: points })}</PointsBlock>
      </Container>
    </Wrapper>
  );
};
