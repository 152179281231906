import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RewardTile } from '../reward-tile';
import { selectRewards } from 'store/rewards/selectors';
import { Container, RewardsContainer } from './styles';
import { RewardTileSkeleton } from '../reward-tile/components/reward-tile-skeleton';
import { useInfiniteScroll } from './hooks/use-infinite-scroll.hook';
import { push } from 'store/router/actions';
import { DefaultEmptyState } from '../default-empty-state';
import { useIntl } from 'react-intl';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { RewardsStateEnum } from 'store/rewards/types';
import { DEFAULT_LOCALE } from 'components/intl-provider/intl-provider.hooks';

interface RewardslistContentProps {
  landingPageHeaderHeight: number;
}

export const RewardsListContent = ({ landingPageHeaderHeight }: RewardslistContentProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const rewards = useSelector(selectRewards);
  const rewardsListContentContainerRef = useRef();
  const rewardsContainerRef = useRef();
  const totalDisplayedRewards = rewards.length;
  const { isInfiniteScrolling } = useInfiniteScroll({
    rewardsListContentContainerRef,
    totalDisplayedRewards,
  });

  const onRewardClick = useCallback(
    (id: string) => {
      const selectedReward = rewards.find((reward) => `${reward.id}` === `${id}`);

      track(
        TRACK_EVENT_NAMES.THANX_REWARD_TILE_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
          vendors: selectedReward?.locations ?? [],
        },
        { sendToHqoTracking: true },
      );
      dispatch(push(`reward-details/${id}`));
    },
    [dispatch, rewards],
  );

  return (
    <Container
      data-testid="rewards-list-content"
      ref={rewardsListContentContainerRef}
      pageHeaderHeight={landingPageHeaderHeight}
    >
      {rewards.length ? (
        <RewardsContainer ref={rewardsContainerRef}>
          {rewards.map(
            ({
              title, description, cost, image_url, id, state,
            }) => (
              <RewardTile
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => onRewardClick(id)}
                key={id}
                points={cost?.toLocaleString(DEFAULT_LOCALE)}
                imageUrl={image_url}
                title={title}
                subtitle={description}
                isActiveReward={state === RewardsStateEnum.ACTIVE}
              />
            ),
          )}
          {isInfiniteScrolling && <RewardTileSkeleton />}
        </RewardsContainer>
      ) : (
        <DefaultEmptyState
          title={intl.formatMessage({ id: 'rewardsEmptyState.title' })}
          subtitle={intl.formatMessage({ id: 'rewardsEmptyState.subtitle' })}
        />
      )}
    </Container>
  );
};
