import { BuildingState, Building } from 'store/building/types';
import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const buildingStateSelector = (state: RootState): BuildingState => state.building;

export const selectBuilding = createSelector(buildingStateSelector, (buildingState) => buildingState.building);

export const selectBuildingStatus = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.getBuilding.status,
);

export const selectBuildingTimeZone = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.timezone ?? null,
);

export const selectBuildingLocale = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.locale ?? null,
);

export const selectBuildingUuid = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.uuid ?? null,
);
