import React, { useCallback, useEffect, useState } from 'react';

import { BottomModalContentButtons } from 'components/bottom-modal-content/types';

import { BoldText } from 'hocs/shared-styles';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  ACTION_STATUSES,
  BUILDING,
  DEFAULT_SLIDE_ANIMATION_DELAY,
  REWARDS_LIST_LIMIT,
  TRACK_EVENT_NAMES, TRACK_EVENT_TYPES,
} from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import {
  activateReward,
  finalizeReward,
  getRewards,
  resetActivateRewardState,
  resetFinalizeRewardState,
  resetRewardsState,
} from 'store/rewards/actions';
import {
  selectActivateRewardStatus,
  selectFinalizeRewardStatus,
  selectRewardById,
  selectRewardsBalance,
} from 'store/rewards/selectors';
import { RewardsStateEnum } from 'store/rewards/types';
import { push } from 'store/router/actions';

import { useBooleanState } from '@hqo/react-components-library';

import { UseRewardActivationModalReturnValues } from '../types';
import { pastActivationExpirationTime } from 'utils/activationExpiration';
import { useTrackEvent } from 'hooks/use-track-event.hook';

export const useRewardActivationModal = (): UseRewardActivationModalReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activateRewardStatus = useSelector(selectActivateRewardStatus);
  const finalizeRewardStatus = useSelector(selectFinalizeRewardStatus);
  const { rewardId } = useParams();
  const reward = useSelector(selectRewardById(rewardId));
  const { value: isReverseAnimation, toggle: toggleIsReverseAnimation } = useBooleanState(false);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const rewardsBalance = useSelector(selectRewardsBalance);
  const { balance = 0 } = rewardsBalance || {};

  const actionStatus = isActivated ? finalizeRewardStatus : activateRewardStatus;
  const isLoading = actionStatus === ACTION_STATUSES.PENDING;
  const isError = actionStatus === ACTION_STATUSES.REJECTED;
  const hasFulfilled = actionStatus === ACTION_STATUSES.FULFILLED;

  const {
    cost, expiration_window, activated_at, state,
  } = reward || {};

  const { handleTrackEvent } = useTrackEvent({
    eventName: TRACK_EVENT_NAMES.THANX_REWARD_REDEMPTION,
    eventType: TRACK_EVENT_TYPES.ACTION,
    metadata: { redeemed_points: cost },
  });

  const { value: isModalVisible, toggle: toggleRewardActivationModalVisible } = useBooleanState(false);
  const buildingUuid = useSelector(selectBuildingUuid);

  const redeemLabel = cost > 0
    ? intl.formatMessage({ id: 'rewardActivation.redeemButtonLabel' }, { pointsTotal: cost })
    : intl.formatMessage({ id: 'rewardActivation.redeemFreeLabel' });

  const finalizeLabel = intl.formatMessage({ id: 'rewardActivation.markAsRedeemCtaLabel' });
  const handleToggleModalVisibility = useCallback(() => {
    dispatch(isActivated ? resetFinalizeRewardState() : resetActivateRewardState());
    if (isModalVisible !== isReverseAnimation) {
      toggleIsReverseAnimation();
    }

    setTimeout(() => {
      toggleRewardActivationModalVisible();
    }, DEFAULT_SLIDE_ANIMATION_DELAY);
  }, [isActivated, dispatch, toggleIsReverseAnimation, isModalVisible]);

  const handleActivateClick = useCallback(() => {
    if (isActivated) {
      dispatch(finalizeReward.request({ ownerType: BUILDING, ownerId: buildingUuid, rewardId }));
    } else {
      dispatch(activateReward.request({
        ownerType: BUILDING,
        ownerId: buildingUuid,
        rewardId,
        rewardState: state,
      }));
    }
  }, [handleToggleModalVisibility, buildingUuid, BUILDING, rewardId, isActivated]);

  const navigateToLandingPage = useCallback(() => {
    dispatch(resetRewardsState());
    dispatch(push('/'));
  }, [dispatch]);

  useEffect(() => {
    if (pastActivationExpirationTime({ activated_at, expiration_window })) {
      navigateToLandingPage();
    } else if (activated_at) {
      setIsActivated(true);
    }
  }, [expiration_window, activated_at]);

  useEffect(() => {
    if (hasFulfilled) {
      handleToggleModalVisibility();

      if (isActivated) {
        navigateToLandingPage();
      } else {
        dispatch(getRewards.request({
          ownerType: BUILDING,
          ownerId: buildingUuid,
          states: [RewardsStateEnum.AVAILABLE, RewardsStateEnum.NOT_REDEEMED, RewardsStateEnum.ACTIVE],
          limit: REWARDS_LIST_LIMIT,
        }));
      }
    }
  }, [hasFulfilled, handleToggleModalVisibility]);

  useEffect(() => {
    if (activateRewardStatus === ACTION_STATUSES.FULFILLED) {
      handleTrackEvent();
      dispatch(resetActivateRewardState());
    }
  }, [activateRewardStatus, cost, handleTrackEvent]);

  const ctaLabel = isActivated ? finalizeLabel : redeemLabel;
  const ctaButtonVariant = isActivated ? 'Outline' : undefined;
  const isCtaDisabled = isActivated ? isLoading : cost > balance || isLoading;
  const title = isActivated
    ? intl.formatMessage({ id: 'rewardActivation.markAsRedeemedModalTitle' })
    : intl.formatMessage({ id: 'rewardActivation.readyToRedeem' });

  const redeemExplanation = intl.formatMessage(
    { id: 'rewardActivation.redeemExplanation' },
    {
      expirationWindow:
  <BoldText>
    {intl.formatMessage({ id: 'rewardActivation.expirationMinutes' },
      { expirationMinutes: reward?.expiration_window })}
  </BoldText>,
    },
  ) as JSX.Element;

  const subtitles = isActivated
    ? [intl.formatMessage({ id: 'rewardActivation.markAsRedeemedModalDescription' })]
    : [
      ...(reward?.expiration_window ? [redeemExplanation] : []),
      intl.formatMessage(
        { id: 'rewardActivation.currentBalanceReminder' },
        { pointsTotal: balance },
      ),
    ];

  const buttons: Array<BottomModalContentButtons> = [
    {
      isLoading,
      isDisabled: isCtaDisabled,
      onClick: handleActivateClick,
      text: ctaLabel,
    },
    {
      isDisabled: isLoading,
      text: intl.formatMessage({ id: 'genericModal.goBack' }),
      onClick: handleToggleModalVisibility,
      variant: 'Plain',
    },
  ];

  return {
    isReverseAnimation,
    modalContent: {
      isError,
      buttons,
      title,
      subtitles,
    },
    isActivated,
    isModalVisible,
    ctaLabelValues: {
      isLoading,
      isDisabled: isCtaDisabled,
      onClick: handleToggleModalVisibility,
      label: ctaLabel,
      variant: ctaButtonVariant,
    },
  };
};
