import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTION_STATUSES, BUILDING, CARD_BRANDS, SETTINGS_PATHS,
} from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import { registerCard, resetRegisterCardState } from 'store/cards/actions';
import { selectCards, selectGetCardsStatus, selectRegisterCardStatus } from 'store/cards/selectors';
import { getPaymentMethods, resetSavePaymentMethod, savePaymentMethod } from 'store/payment-methods/actions';
import {
  selectGetPaymentMethodsStatus,
  selectPaymentMethods,
  selectSavePaymentMethodStatus,
} from 'store/payment-methods/selectors';
import { SavedPaymentMethod } from 'store/payment-methods/types';
import { replace } from 'store/router/actions';
import { resetSpreedly, tokenizeCreditCardSpreedly } from 'store/spreedly/actions';
import {
  selectSpreedlyCreditCardConfig,
  selectSpreedlyPaymentMethodData,
  selectSpreedlyTokenizeCreditCardStatus,
} from 'store/spreedly/selectors';
import { formatSavePaymentMethodRequestBody } from 'utils/formatSavePaymentMethodRequestBody';

interface UsePaymentMethodsManagerProps {
  isSettingsModal?: boolean;
}

interface UsePaymentMethodsManagerValues {
  onOptionClickHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredPaymentMethods: Array<SavedPaymentMethod>;
  selectedPaymentMethod: string;
  isAddingExistingCard: boolean;
  onRegisterCardClick: VoidFunction;
  setIsAddingExistingCard: VoidFunction;
  isRegisterButtonDisabled: boolean;
  isRegisterButtonLoading: boolean;
  onSkipClick: VoidFunction
  showRegisterCardError: boolean;
  setShowRegisterCardError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePaymentMethodsManager = ({
  isSettingsModal,
}: UsePaymentMethodsManagerProps): UsePaymentMethodsManagerValues => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const getPaymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const spreedlyCreditCardConfig = useSelector(selectSpreedlyCreditCardConfig);
  const spreedlyPaymentMethodData = useSelector(selectSpreedlyPaymentMethodData);
  const spreedlyTokenizeCreditCardStatus = useSelector(selectSpreedlyTokenizeCreditCardStatus);
  const registerCardStatus = useSelector(selectRegisterCardStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);
  const cards = useSelector(selectCards);
  const getCardsStatus = useSelector(selectGetCardsStatus);
  const cardLastFourSet = new Set(cards.map((card) => (card.last_four)));
  const cardTypeSet = new Set(cards.map((card) =>
    (card.type === CARD_BRANDS.AMEX ? CARD_BRANDS.AMERICAN_EXPRESS : card.type)));
  const paymentMethods = useSelector(selectPaymentMethods);

  const filteredPaymentMethods = isSettingsModal
    ? paymentMethods.filter(
      (paymentMethod) =>
        !(cardLastFourSet.has(paymentMethod.card_last_four)
      && cardTypeSet.has(paymentMethod.card_brand)),
    )
    : paymentMethods;
  const {
    value: isAddingExistingCard,
    setFalse: setIsAddingExistingCard,
  } = useBooleanState(filteredPaymentMethods.length > 0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    filteredPaymentMethods[0]?.id.toString() || undefined,
  );
  const [showRegisterCardError, setShowRegisterCardError] = useState(false);
  const isRegisterButtonDisabled = !(isAddingExistingCard || spreedlyPaymentMethodData?.token);
  const isRegisterButtonLoading = registerCardStatus === ACTION_STATUSES.PENDING
  || savePaymentMethodStatus === ACTION_STATUSES.PENDING || getCardsStatus === ACTION_STATUSES.PENDING;

  useEffect(() => {
    if (!isAddingExistingCard) {
      dispatch(resetRegisterCardState());
      setShowRegisterCardError(false);
    }
    return () => {
      setShowRegisterCardError(false);
      dispatch(resetRegisterCardState());
    };
  }, [isAddingExistingCard]);

  useEffect(() => {
    if (!getPaymentMethodsStatus) {
      dispatch(getPaymentMethods.request());
    }
  }, [paymentMethods, getPaymentMethodsStatus]);

  useEffect(() => {
    if (spreedlyCreditCardConfig.first_name.length > 0 && !isAddingExistingCard && !spreedlyTokenizeCreditCardStatus) {
      dispatch(tokenizeCreditCardSpreedly.request(spreedlyCreditCardConfig));
    }
  }, [spreedlyCreditCardConfig, isAddingExistingCard, spreedlyTokenizeCreditCardStatus]);

  const onOptionClickHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedPaymentMethod(event.target.value);
    },
    [],
  );

  const getPaymentMethodToken = useCallback((): string => {
    const {
      last_four_digits, card_type, month, year, zip,
    } = spreedlyPaymentMethodData.paymentMethod;

    const matchingPaymentMethod = filteredPaymentMethods.find((paymentMethod) => {
      const {
        card_last_four, card_brand, card_exp_month, card_exp_year, payment_method_address_zip,
      } = paymentMethod;

      return card_last_four === last_four_digits
      && card_brand === card_type
      && card_exp_month === month.toString()
      && card_exp_year === year.toString()
      && payment_method_address_zip === zip;
    });

    return matchingPaymentMethod?.payment_token ?? '';
  }, [filteredPaymentMethods, spreedlyPaymentMethodData]);

  const onRegisterCardClick = useCallback(() => {
    const paymentToken = isAddingExistingCard
      ? filteredPaymentMethods?.find((paymentMethod) =>
        paymentMethod.id.toString() === selectedPaymentMethod)?.payment_token
      : getPaymentMethodToken();

    setShowRegisterCardError(false);
    if (paymentToken?.length) {
      dispatch(registerCard.request({ ownerType: BUILDING, ownerId: buildingUuid, payment_token: paymentToken }));
    } else {
      dispatch(resetRegisterCardState());
      dispatch(savePaymentMethod.request(formatSavePaymentMethodRequestBody(spreedlyPaymentMethodData?.paymentMethod)));
    }
  }, [isAddingExistingCard,
    filteredPaymentMethods,
    getPaymentMethodToken,
    selectedPaymentMethod,
    dispatch,
    buildingUuid,
    spreedlyPaymentMethodData,
  ]);

  const onSkipClick = useCallback(() => {
    dispatch(replace('/'));
  }, [dispatch]);

  useEffect(() => {
    if (savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(resetSavePaymentMethod());
      dispatch(getPaymentMethods.request());
    }
    if (registerCardStatus === ACTION_STATUSES.FULFILLED && getCardsStatus === ACTION_STATUSES.FULFILLED) {
      const route = isSettingsModal ? SETTINGS_PATHS.PAYMENT_METHODS : '/';
      dispatch(resetSpreedly());
      dispatch(resetRegisterCardState());
      dispatch(replace(route));
      setShowRegisterCardError(false);
    }
    if (savePaymentMethodStatus === ACTION_STATUSES.REJECTED) {
      setShowRegisterCardError(true);
      dispatch(resetSavePaymentMethod());
    }
    if (registerCardStatus === ACTION_STATUSES.REJECTED) {
      setShowRegisterCardError(true);
    }
  }, [dispatch, isSettingsModal, registerCardStatus, savePaymentMethodStatus, getCardsStatus]);

  return {
    onOptionClickHandler,
    filteredPaymentMethods,
    selectedPaymentMethod,
    isAddingExistingCard,
    onRegisterCardClick,
    setIsAddingExistingCard,
    isRegisterButtonDisabled,
    isRegisterButtonLoading,
    onSkipClick,
    showRegisterCardError,
    setShowRegisterCardError,
  };
};
