import React from 'react';
import { useIntl } from 'react-intl';
import {
  Container, Title, StyledFormField, StyledButton, Subtitle, ContentContainer,
} from './styles';
import { FormFieldType } from '@hqo/react-components-library';
import { isEmailValid } from 'utils/isEmailValid';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';
import { isTruthyString } from 'utils/isTruthyString';

interface EmailInputButtonProps {
  title: string;
  subtitle?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: VoidFunction;
  logoUrl?: string;
  isExternalError?: boolean;
  isButtonLoading?: boolean;
}

export const EmailInputButton = ({
  title,
  subtitle,
  value,
  onChange,
  onClick,
  logoUrl,
  isExternalError,
  isButtonLoading,
}: EmailInputButtonProps): JSX.Element => {
  const intl = useIntl();
  const { isSlideAnimationEnable } = useSearchParams<SearchParams>();
  const isNotValidEmail = Boolean(value) && !isEmailValid(value);
  const isButtonDisabled = !value || isNotValidEmail || isButtonLoading;
  const isSlideAnimationExist = isTruthyString(isSlideAnimationEnable);
  const isErrorTextVisible = isNotValidEmail || isExternalError;
  const errorTextId = isExternalError ? 'emailInput.accountNotFound' : 'emailInput.errorEmail';

  return (
    <Container isSlideAnimationEnable={isSlideAnimationExist} data-testid="email-input-button-container">
      <Title>{title}</Title>
      <StyledFormField
        data-testid="email-field"
        errorText={isErrorTextVisible && intl.formatMessage({ id: errorTextId })}
        fieldType={FormFieldType.INPUT}
        label={intl.formatMessage({ id: 'emailInput.label' })}
        onChange={onChange}
        placeholder={intl.formatMessage({ id: 'emailInput.placeholder' })}
        value={value}
        variant="master"
        type="email"
      />
      {subtitle && (
        <ContentContainer>
          {logoUrl && <img data-testid="logo" src={logoUrl} alt="logo" />}
          <Subtitle>{subtitle}</Subtitle>
        </ContentContainer>
      )}
      <StyledButton
        data-testid="next-button"
        onClick={onClick}
        text={intl.formatMessage({ id: 'buttons.next' })}
        disabled={isButtonDisabled}
        loading={isButtonLoading}
      />
    </Container>
  );
};
