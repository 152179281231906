import styled from 'styled-components';

export const Container = styled.div<{ showBlock: boolean }>`
  display: ${({ showBlock }) => (showBlock ? 'flex' : 'none')};
  justify-content: flex-start;
  align-items: center;
  width: calc(85% - 32px);
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  padding: 16px;
  gap: 17px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.palette.systemGreyMedium};
  user-select: none;
`;

export const Title = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
