import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

import { ExternalLinksState } from './types';

export const initialState: ExternalLinksState = {
  externalLinks: null,
  getExternalLinks: null,
};

const getExternalLinksRequestHandler = (state: ExternalLinksState): ExternalLinksState => ({
  ...state,
  getExternalLinks: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getExternalLinksSuccessHandler = (
  state: ExternalLinksState,
  { payload }: ActionType<typeof actions.getExternalLinks.success>,
): ExternalLinksState => ({
  ...state,
  externalLinks: payload.data,
  getExternalLinks: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getExternalLinksFailureHandler = (
  state: ExternalLinksState,
  { payload: error }: ActionType<typeof actions.getExternalLinks.failure>,
): ExternalLinksState => ({
  ...state,
  getExternalLinks: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

export const externalLinksReducer = createReducer(initialState)
  .handleAction(actions.getExternalLinks.request, getExternalLinksRequestHandler)
  .handleAction(actions.getExternalLinks.success, getExternalLinksSuccessHandler)
  .handleAction(actions.getExternalLinks.failure, getExternalLinksFailureHandler);
