import React from 'react';

import { Logout } from 'components/logout';
import { useLegalRequirements } from 'components/modals/payment-method-manager/hooks/use-legal-requirements.hook';
import { useIntl } from 'react-intl';

import { Container, StyledMailTo } from './styles';
import { SETTINGS_MODAL, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { MailToContainer } from 'components/modals/sso-flow/components/magic-link-content/styles';
import { useTrackEvent } from 'hooks/use-track-event.hook';

export const SettingsFooter = (): JSX.Element => {
  const intl = useIntl();
  const { externalLinks } = useLegalRequirements();
  const { handleTrackEvent } = useTrackEvent({
    eventName: TRACK_EVENT_NAMES.THANX_CONTACT_SUPPORT_CLICK,
    eventType: TRACK_EVENT_TYPES.ACTION,
    metadata: { modal_name: SETTINGS_MODAL },
  });

  return (
    <Container data-testid="settings-footer">
      <MailToContainer onClick={handleTrackEvent}>
        <StyledMailTo
          subject={intl.formatMessage({ id: 'support.emailSubject' })}
          title={intl.formatMessage({ id: 'support.contactSupport' })}
          mailTo={externalLinks?.support_email}
          data-testid="settings-contact-support-button"
        />
      </MailToContainer>
      <Logout />
    </Container>
  );
};
