import { concatMap, filter } from 'rxjs/operators';
import { showGenericErrorNotification } from './actions';

import { ERROR_PATH } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'store/router/actions';

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  filter(
    isActionOf([
      showGenericErrorNotification,
    ]),
  ),
  concatMap(() => of(
    replace(ERROR_PATH),
  )),
);
