import { RewardsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const rewardsState = (state: RootState): RewardsState => state.rewards;

export const selectRewards = createSelector([rewardsState], (rewards) => rewards.rewards ?? []);

export const selectUsedRewards = createSelector([rewardsState], (rewards) => rewards.usedRewards ?? []);

export const selectReward = createSelector([rewardsState], (rewards) => rewards.reward);

export const selectRewardById = (id: string) => createSelector([selectRewards], (rewards) =>
  rewards.find((reward) => `${reward.id}` === `${id}`), // thanx returns rewards with ids as both strings and numbers
);

export const selectRewardsTotal = createSelector([rewardsState], (rewards) => rewards.total);

export const selectRewardsCount = createSelector([rewardsState], (rewards) => rewards.count);

export const selectGetRewardsStatus = createSelector([rewardsState], (rewards) => rewards.getRewards.status);

export const selectGetRewardsError = createSelector([rewardsState], (rewards) => rewards.getRewards.error);

export const selectGetUsedRewardsStatus = createSelector([rewardsState], (rewards) => rewards.getUsedRewards.status);

export const selectGetUsedRewardsError = createSelector([rewardsState], (rewards) => rewards.getUsedRewards.error);

export const selectGetInfiniteRewardsStatus = createSelector(
  [rewardsState],
  (rewards) => rewards.getInfiniteRewards.status,
);

export const selectGetInfiniteRewardsError = createSelector(
  [rewardsState],
  (rewards) => rewards.getInfiniteRewards.error,
);

export const selectRewardsBalance = createSelector([rewardsState], (rewards) => rewards.rewardsBalance);

export const selectGetRewardsBalanceStatus = createSelector(
  [rewardsState],
  (rewards) => rewards.getRewardsBalance.status,
);

export const selectGetRewardsBalanceError = createSelector(
  [rewardsState],
  (rewards) => rewards.getRewardsBalance.error,
);

export const selectActivateRewardStatus = createSelector(
  [rewardsState],
  (rewards) => rewards.activateReward.status,
);

export const selectActivateRewardError = createSelector(
  [rewardsState],
  (rewards) => rewards.activateReward.error,
);

export const selectFinalizeRewardStatus = createSelector(
  [rewardsState],
  (rewards) => rewards.finalizeReward.status,
);

export const selectFinalizeRewardError = createSelector(
  [rewardsState],
  (rewards) => rewards.finalizeReward.error,
);

export const imageIsLoadedSelector = createSelector([rewardsState], (rewards) => rewards.imageIsLoaded);
