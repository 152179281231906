import styled from 'styled-components';

export const TilesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 8px;

  :active {
    background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};
  }
`;
