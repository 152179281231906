import QueryString, { stringify } from 'qs';
import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { VISITOR_REGISTRATION } from 'shared/consts';
import { OwnerParams } from 'shared/types';
import { BuildingResponse } from 'store/building/types';
import { DeleteCardPayload, GetCardsAPIResponse, RegisterCardPayload } from 'store/cards/types';
import { GetExternalLinksResponse } from 'store/external-links/types';
import { IntroReward } from 'store/intro-reward/types';
import { NotificationSettings, NotificationSettingsRO } from 'store/notification-settings/types';
import {
  DeletePaymentMethodResponse, SavedPaymentMethod, SavePaymentMethodRequest, SavePaymentMethodResponse,
} from 'store/payment-methods/types';
import {
  ActivateRewardPayload,
  DefaultParams,
  FinalizeRewardPayload, GetRewardResponse,
  GetRewardsBalanceResponse,
  GetRewardsPayload,
  RewardsAPIResponse,
} from 'store/rewards/types';
import { BuildingTheme } from 'store/theme/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { CurrentUserResponse } from 'store/user/types';
import { AuthPayload, FinishLoginPayload, LogoutPayload } from 'store/userAuth/types';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private altBuildingUuid?: string | null,
    private thanxDevUrl?: string | null,
  ) {
    this.thanxDevUrl = this.baseUrl.includes('localhost')
      ? 'http://localhost:3033'
      : this.baseUrl;
  }

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      ...(this.altBuildingUuid ? { 'alt-building-uuid': this.altBuildingUuid } : {}),
    };
  }

  private get DefaultParamsOptions(): QueryString.IStringifyOptions {
    return {
      skipNulls: true,
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?include=languages`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${VISITOR_REGISTRATION}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  getRewards({
    ownerId,
    ownerType,
    states,
    ...params
  }: GetRewardsPayload): Observable<AjaxResponse<RewardsAPIResponse>> {
    const requestParams = { ...params, states: states.join(',') };
    const paramsString = stringify(requestParams, this.DefaultParamsOptions);

    return ajax.get(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/rewards?${paramsString}`,
      this.DefaultHeaders,
    );
  }

  getIntroReward(buildingUuid: string): Observable<AjaxResponse<IntroReward>> {
    return ajax.get(`${this.thanxDevUrl}/thanx/v1/building/${buildingUuid}/intro-reward`, this.DefaultHeaders);
  }

  login({ ownerId, ownerType, email }: AuthPayload): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.post(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/login`,
      { email },
      this.DefaultHeaders,
    );
  }

  logout({ ownerId, ownerType }: LogoutPayload): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.delete(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/logout`,
      this.DefaultHeaders,
    );
  }

  register({ ownerId, ownerType, email }: AuthPayload): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.post(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/register`,
      { email },
      this.DefaultHeaders,
    );
  }

  finishLogin({ ownerId, ownerType, code }: FinishLoginPayload): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.post(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/finish-login`,
      { code },
      this.DefaultHeaders,
    );
  }

  registerCard(
    { ownerId, ownerType, payment_token }: RegisterCardPayload,
  ): Observable<AjaxResponse<void>> {
    return ajax.post(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/cards`,
      { payment_token },
      this.DefaultHeaders,
    );
  }

  getCards(ownerId: string): Observable<AjaxResponse<GetCardsAPIResponse>> {
    return ajax.get(
      `${this.thanxDevUrl}/thanx/v1/building/${ownerId}/cards`,
      this.DefaultHeaders,
    );
  }

  deleteCard({ ownerId, cardId }: DeleteCardPayload): Observable<AjaxResponse<void>> {
    return ajax.delete(
      `${this.thanxDevUrl}/thanx/v1/building/${ownerId}/cards/${cardId}`,
      this.DefaultHeaders,
    );
  }

  getPaymentMethods(): Observable<AjaxResponse<Array<SavedPaymentMethod>>> {
    return ajax.get(`${this.baseUrl}/api/payment-methods`, this.DefaultHeaders);
  }

  savePaymentMethod(
    savePaymentMethodRequest: SavePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.post(`${this.baseUrl}/api/payment-methods`, savePaymentMethodRequest, this.DefaultHeaders);
  }

  deletePaymentMethod(
    paymentMethodId: number,
  ): Observable<AjaxResponse<DeletePaymentMethodResponse>> {
    return ajax.delete(`${this.baseUrl}/api/payment-methods/${paymentMethodId}`, this.DefaultHeaders);
  }

  getExternalLinks(ownerId: string): Observable<AjaxResponse<GetExternalLinksResponse>> {
    return ajax.get(
      `${this.thanxDevUrl}/thanx/v1/building/${ownerId}/configurations/external-links`,
      this.DefaultHeaders,
    );
  }

  getRewardsBalance({
    ownerId,
    ownerType,
  }: DefaultParams): Observable<AjaxResponse<GetRewardsBalanceResponse>> {
    return ajax.get(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/rewards-balance`,
      this.DefaultHeaders,
    );
  }

  deleteUser({
    ownerId,
    ownerType,
  }: DefaultParams): Observable<AjaxResponse<GetRewardsBalanceResponse>> {
    return ajax.delete(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/users/me`,
      this.DefaultHeaders,
    );
  }

  activateReward({
    ownerId,
    ownerType,
    rewardId,
    rewardState,
  }: ActivateRewardPayload): Observable<AjaxResponse<GetRewardResponse>> {
    return ajax.patch(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/rewards/${rewardId}/activate?state=${rewardState}`,
      {},
      this.DefaultHeaders,
    );
  }

  finalizeReward({
    ownerId,
    ownerType,
    rewardId,
  }: FinalizeRewardPayload): Observable<AjaxResponse<GetRewardResponse>> {
    return ajax.patch(
      `${this.thanxDevUrl}/thanx/v1/${ownerType}/${ownerId}/rewards/${rewardId}/finalize`, {}, this.DefaultHeaders,
    );
  }

  getNotificationSettings({ ownerId }: DefaultParams): Observable<AjaxResponse<NotificationSettingsRO>> {
    return ajax.get(
      `${this.thanxDevUrl}/thanx/v1/building/${ownerId}/notification-settings`,
      this.DefaultHeaders,
    );
  }

  patchNotificationSettings(
    { ownerId }: DefaultParams,
    notificationSettings: NotificationSettings,
  ): Observable<AjaxResponse<NotificationSettingsRO>> {
    const { id, ...patchNotificationSettingsPayload } = notificationSettings;

    return ajax.patch(
      `${this.thanxDevUrl}/thanx/v1/building/${ownerId}/notification-settings/${id}`,
      patchNotificationSettingsPayload,
      this.DefaultHeaders,
    );
  }
}
