import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingUuid } from 'store/building/selectors';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { selectGetRewardsBalanceStatus, selectGetRewardsStatus } from 'store/rewards/selectors';
import { getRewards, getRewardsBalance } from 'store/rewards/actions';
import { ACTION_STATUSES, BUILDING, REWARDS_LIST_LIMIT } from 'shared/consts';
import { RewardsStateEnum } from 'store/rewards/types';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { selectAreCardsRegistered, selectGetCardsStatus } from 'store/cards/selectors';
import { getCards } from 'store/cards/actions';
import { replace } from 'store/router/actions';
import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { selectFinishLoginStatus } from 'store/userAuth/selectors';

const DEFAULT_HEADER_HEIGHT = 0;

interface UseLandingPageReturnValues {
  isLoading: boolean;
  pageHeaderRef: React.MutableRefObject<HTMLDivElement>;
  registerCardRef: React.MutableRefObject<HTMLDivElement>;
  rewardsBalanceRef: React.MutableRefObject<HTMLDivElement>;
  landingPageHeaderHeight: number;
  isRegisterCardVisible: boolean;
  onRegisterCardClick: VoidFunction;
}

export const useLandingPage = (): UseLandingPageReturnValues => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const getRewardsStatus = useSelector(selectGetRewardsStatus);
  const getRewardsBalanceStatus = useSelector(selectGetRewardsBalanceStatus);
  const finishLoginStatus = useSelector(selectFinishLoginStatus);
  const areCardsRegistered = useSelector(selectAreCardsRegistered);
  const isLoading = getRewardsStatus === ACTION_STATUSES.PENDING || getRewardsBalanceStatus === ACTION_STATUSES.PENDING;
  const client = useMiniappSdk();
  const pageHeaderRef = useRef<HTMLDivElement>();
  const registerCardRef = useRef<HTMLDivElement>();
  const rewardsBalanceRef = useRef<HTMLDivElement>();
  const [landingPageHeaderHeight, setLandingPageHeaderHeight] = useState<number>(DEFAULT_HEADER_HEIGHT);
  const defaultPayload = { ownerId: buildingUuid, ownerType: BUILDING };
  const getCardsStatus = useSelector(selectGetCardsStatus);

  useEffect(() => {
    if (!isLoading) {
      const pageHeaderHeight = pageHeaderRef?.current?.clientHeight ?? DEFAULT_HEADER_HEIGHT;
      const registerCardHeight = !areCardsRegistered && registerCardRef?.current
        ? registerCardRef?.current?.clientHeight
        : DEFAULT_HEADER_HEIGHT;
      const rewardsBalanceHeight = rewardsBalanceRef?.current?.clientHeight ?? DEFAULT_HEADER_HEIGHT;

      setLandingPageHeaderHeight(pageHeaderHeight + registerCardHeight + rewardsBalanceHeight);
    }
  }, [areCardsRegistered, isLoading]);

  useEffect(() => {
    const payload = {
      ...defaultPayload,
      states: [RewardsStateEnum.AVAILABLE, RewardsStateEnum.NOT_REDEEMED, RewardsStateEnum.ACTIVE],
      limit: REWARDS_LIST_LIMIT,
    };

    if (buildingUuid && !getRewardsStatus) {
      dispatch(getRewards.request(payload));
      dispatch(getRewardsBalance.request(defaultPayload));
    }
  }, [dispatch, finishLoginStatus]);

  useEffect(() => {
    if (getCardsStatus === null) {
      dispatch(getCards.request());
    }
  }, [dispatch, finishLoginStatus, getCardsStatus]);

  useEffect(() => {
    client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
    client?.header.showHeader();
  }, [client]);

  const onRegisterCardClick = useCallback(() => {
    dispatch(replace(`sso?step=${SSO_MODAL_CONTENT_STEPS.REGISTER_CARD}`));
  }, [dispatch]);

  return {
    isLoading,
    pageHeaderRef,
    registerCardRef,
    rewardsBalanceRef,
    landingPageHeaderHeight,
    isRegisterCardVisible: !areCardsRegistered,
    onRegisterCardClick,
  };
};
