/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';

import { ReactComponent as Stars } from 'assets/images/stars.svg';
import { Countdown } from 'components/countdown';
import { CouponCode } from 'components/coupon-code';
import {
  ActivatedRewardContainer,
  ActivatedRewardDescription,
  ContentContainer,
  Cost,
  CountBlock,
  HeaderContainer,
  LeftBar,
  Title,
} from 'pages/reward-details-page/components/styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { resetRewardsState } from 'store/rewards/actions';
import { Reward } from 'store/rewards/types';
import { push } from 'store/router/actions';
import { formatActivationExpiration } from 'utils/activationExpiration';

export interface RewardContentProps {
  reward: Reward;
  isActivated: boolean;
}

export const RewardContent = ({ reward, isActivated }: RewardContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    title, cost, description, coupon_code, coupon_code_type, activated_at, expiration_window,
  } = reward || {};
  const activatedRewardDescription = intl.formatMessage({ id: 'rewardActivation.activatedDescription' });
  const activationEndsAt = formatActivationExpiration({ activated_at, expiration_window });

  const onEnd = useCallback(() => {
    dispatch(resetRewardsState());
    dispatch(push('/'));
  }, [dispatch]);

  return (reward == null)
    ? null : (
      <>
        <HeaderContainer>
          <LeftBar>
            <Title>{title}</Title>
          </LeftBar>
          {Boolean(cost) && (
            <CountBlock>
              <Stars />
              <Cost>{cost}</Cost>
            </CountBlock>
          )}
        </HeaderContainer>
        <ContentContainer>
          {description}
        </ContentContainer>
        {isActivated && (
          <ActivatedRewardContainer>

            {coupon_code && coupon_code_type && (
              <>
                <ActivatedRewardDescription>
                  {activatedRewardDescription}
                </ActivatedRewardDescription>
                <CouponCode code={coupon_code} codeType={coupon_code_type} />
              </>
            )}

            <Countdown endDatetime={activationEndsAt} onEnd={onEnd} />
          </ActivatedRewardContainer>
        )}
      </>
    );
};
