import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import {
  CONTENT_BORDER_RADIUS,
  IMAGE_PLACEHOLDER_HEIGHT,
  REWARD_SECTIONS_Z_INDEXES,
} from 'pages/reward-details-page/constants';
import styled from 'styled-components';
import { Image } from 'components/image';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: calc(100vh - 70px);
  }
`;

export const RewardContainer = styled.div`
  width: min(700px, 100%);
  padding-bottom: 40px;

  &:last-child {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-bottom: 40px;
    position: relative;
  }
`;

export const StyledMobileHeaderWrapper = styled.div`
  z-index: ${REWARD_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  overflow-y: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 244px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    object-fit: fill;
    position: fixed;
    z-index: ${REWARD_SECTIONS_Z_INDEXES.IMAGE_SECTION};
  }
`;

export const RewardImage = styled.div`
  width: 876px;
  height: 300px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 188px;
  }
`;

export const RewardImageSkeleton = styled(RewardImage)`
  display: block;
  width: 100%;
  height: ${IMAGE_PLACEHOLDER_HEIGHT}px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    border-radius: 0;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const ScrollableTrayContainer = styled.div<{ minHeight: number, marginTop: number; isMobileDevice: boolean }>`
  position: relative;
  padding: 24px 16px 0 16px;
  z-index: ${REWARD_SECTIONS_Z_INDEXES.SCROLLABLE_CONTENT_SECTION};
  background: ${({ theme }) => theme.colors.$white};
  min-height: ${({ minHeight }) => `${minHeight}px`};
  ${({ marginTop, isMobileDevice }) => isMobileDevice && `margin-top: ${marginTop - CONTENT_BORDER_RADIUS}px;`}
  border-top-left-radius: ${CONTENT_BORDER_RADIUS}px;
  border-top-right-radius: ${CONTENT_BORDER_RADIUS}px;
`;

export const FooterContainer = styled.div<{ isMobileDevice: boolean }>`
width: 100%;
  position: fixed;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  z-index: ${REWARD_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: ${({ theme }) => `0px 2px 4px ${theme.colors.greys.adminBlack}33`};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const CtaButton = styled(Button)`
  width: 196px;
  height: 48px;
  padding: 16px;
  -webkit-tap-highlight-color: transparent;
  align-self: flex-end;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
    font-size: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0;
  }
`;
