import ArrowRightChevron from '@hqo/react-components-library/dist/icons/arrow-right-chevron';
import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px 12px 16px;
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0px rgba(54, 54, 54, 0.20);
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
`;

export const Title = styled.span`
  font: 500 16px / 22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledIcon = styled(ArrowRightChevron)`
  height: 14px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;
