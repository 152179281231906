import styled from 'styled-components';
import { skeletonAnimation } from 'hocs/shared-styles';

export const SkeletonBlock = styled.div`
  width: 100%;
  height: 228px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.colors.palette.systemGreyMedium};
  ${skeletonAnimation};
`;

export const SkeletonContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;
