import { FinishLoginPayload, AuthPayload, LogoutPayload } from './types';
import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const login = createAsyncAction(
  'user/LOGIN_REQUEST',
  'user/LOGIN_RESPONSE',
  'user/LOGIN_FAILURE',
)<AuthPayload, [undefined, undefined], FailureActionPayload>();

export const logout = createAsyncAction(
  'user/LOGOUT_REQUEST',
  'user/LOGOUT_RESPONSE',
  'user/LOGOUT_FAILURE',
)<LogoutPayload, [undefined, undefined], FailureActionPayload>();

export const register = createAsyncAction(
  'user/REGISTER_REQUEST',
  'user/REGISTER_RESPONSE',
  'user/REGISTER_FAILURE',
)<AuthPayload, [undefined, undefined], FailureActionPayload>();

export const finishLogin = createAsyncAction(
  'user/FINISH_LOGIN_REQUEST',
  'user/FINISH_LOGIN_RESPONSE',
  'user/FINISH_LOGIN_FAILURE',
)<FinishLoginPayload, [undefined, undefined], FailureActionPayload>();

export const resetLoginStatus = createAction('RESET_LOGIN_STATUS')<undefined>();

export const resetRegisterStatus = createAction('RESET_REGISTER_STATUS')<undefined>();

export const resetFinishLoginStatus = createAction('RESET_FINISH_LOGIN_STATUS')<undefined>();

export const resetUserAuthState = createAction('RESET_USER_AUTH_STATE')<undefined>();
