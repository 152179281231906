import {
  AppThemeProvider,
  DefaultThemeWithFonts,
} from '@hqo/react-components-library/dist/molecules/theme';

import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';
import { useGetTheme } from 'hooks/use-get-theme.hook';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const theme = useGetTheme();

  if (theme) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as DefaultThemeWithFonts}>
          {children}
        </AppThemeProvider>
      </>
    );
  }

  return null;
};
