import { useBooleanState } from '@hqo/react-components-library';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'store/router/actions';
import { ACTION_STATUSES, DEFAULT_SLIDE_ANIMATION_DELAY } from 'shared/consts';
import { useIntl } from 'react-intl';
import { selectDeleteCardStatus } from 'store/cards/selectors';
import { deleteCard, resetDeleteCardState } from 'store/cards/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';
import { BottomModalContentButtons } from 'components/bottom-modal-content/types';
import { useLocation } from 'react-router-dom';

interface UseRemoveCardModalReturnValues {
  handleGoBack: VoidFunction;
  handleCTAButtonClick: VoidFunction;
  isReverseAnimation: boolean;
  isCTAButtonLoading: boolean;
  isRemoveCardModalVisible: boolean;
  buttons: Array<BottomModalContentButtons>;
  toggleIsReverseAnimation: VoidFunction;
}

export const useRemoveCardModal = (): UseRemoveCardModalReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { cardId } = useSearchParams<SearchParams>();
  const { value: isReverseAnimation, toggle: toggleIsReverseAnimation } = useBooleanState(false);
  const isCTAButtonLoading = useSelector(selectDeleteCardStatus) === ACTION_STATUSES.PENDING;
  const { pathname } = useLocation();
  const isRemoveCardModalVisible = pathname.includes('remove-card');

  const handleGoBack = useCallback(() => {
    toggleIsReverseAnimation();
    dispatch(resetDeleteCardState());

    setTimeout(() => {
      dispatch(replace('/settings/payment-methods'));
    }, DEFAULT_SLIDE_ANIMATION_DELAY);
  }, [dispatch, toggleIsReverseAnimation]);

  const handleCTAButtonClick = useCallback(() => {
    dispatch(deleteCard.request({ cardId }));
  }, [dispatch, cardId]);

  const buttons: Array<BottomModalContentButtons> = [
    {
      isLoading: isCTAButtonLoading,
      isDisabled: isCTAButtonLoading,
      text: intl.formatMessage({ id: 'removeCardModal.removeCard' }),
      variant: 'Destructive',
      onClick: handleCTAButtonClick,
    },
    {
      isDisabled: isCTAButtonLoading,
      text: intl.formatMessage({ id: 'removeCardModal.goBack' }),
      variant: 'Plain',
      onClick: handleGoBack,
    },
  ];

  return {
    handleGoBack,
    handleCTAButtonClick,
    isReverseAnimation,
    isCTAButtonLoading,
    buttons,
    toggleIsReverseAnimation,
    isRemoveCardModalVisible,
  };
};
