import React from 'react';
import { usePaymentMethodsContent } from './hooks/use-payment-methods-content.hook';
import { Container } from './styles';
import { Skeleton } from 'components/skeleton';
import { RemoveCardModal } from 'components/modals/remove-card-modal';
import { useRemoveCardModal } from 'components/modals/remove-card-modal/hooks/use-remove-card-modal.hook';
import { RegisterCard } from 'components/register-card';
import { CardsList } from './components/cards-list';

export const PaymentMethodsContent = (): JSX.Element => {
  const {
    cards, isLoading, handleRegisterCardClick, isRegisterCardVisible,
  } = usePaymentMethodsContent();
  const {
    isReverseAnimation, buttons, isRemoveCardModalVisible, toggleIsReverseAnimation,
  } = useRemoveCardModal();

  if (isLoading) {
    return <Skeleton isSettingsPage />;
  }

  return (
    <Container>
      {isRegisterCardVisible ? <RegisterCard onClick={handleRegisterCardClick} /> : (
        <CardsList
          isReverseAnimation={isReverseAnimation}
          toggleIsReverseAnimation={toggleIsReverseAnimation}
          handleRegisterCardClick={handleRegisterCardClick}
          cards={cards}
        />
      )}
      <RemoveCardModal isVisible={isRemoveCardModalVisible} buttons={buttons} isReverseAnimation={isReverseAnimation} />
    </Container>
  );
};
