import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { getIntroReward } from './actions';

export const getIntroRewardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(getIntroReward.request)),
  withLatestFrom(state$),
  switchMap(([, state]): Observable<RootAction> => apiClient(state)
    .getIntroReward(state.building.building.uuid)
    .pipe(
      map((xhrPayload) => getIntroReward.success(xhrPayload.response)),
      catchError((error) => of(getIntroReward.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
