import styled from 'styled-components';
import { skeletonAnimation } from 'hocs/shared-styles';

export const HistoryItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.systemGreyMedium};
`;

export const Title = styled.div`
  font: 400 16px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.universal.black};
`;

export const Cost = styled(Title)`
  font: 500 14px / normal ${({ theme }) => theme.fonts.join()};
`;

export const DateBlock = styled.div`
  font: 400 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin-bottom: 16px;
`;

export const SkeletonBlock = styled.div`
  width: 100%;
  height: 68px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.colors.palette.systemGreyMedium};
  ${skeletonAnimation};
`;

export const SkeletonContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;
