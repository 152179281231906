import React, { useCallback, useEffect } from 'react';

import { LogoutButton } from 'components/logout/styles';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, BUILDING } from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import { selectCurrentUserEmail } from 'store/user/selectors';
import { logout } from 'store/userAuth/actions';
import { selectLogoutStatus } from 'store/userAuth/selectors';
import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';

export const Logout = (): JSX.Element => {
  const intl = useIntl();
  const logoutText = intl.formatMessage({ id: 'logout' });
  const dispatch = useDispatch();
  const client = useMiniappSdk();

  const buildingUuid = useSelector(selectBuildingUuid);
  const currentUserEmail = useSelector(selectCurrentUserEmail);
  const logoutStatus = useSelector(selectLogoutStatus);

  useEffect(() => {
    if (logoutStatus === ACTION_STATUSES.FULFILLED) {
      client.close({
        text: intl.formatMessage({ id: 'logoutToastMessage' }),
        icon: 'fa-circle-check',
        color: `${colors.palette.systemBlack}cc`,
      });
    }
  }, [dispatch, logoutStatus]);

  const onLogout = useCallback(() => {
    if (buildingUuid != null) {
      const payload = {
        ownerType: BUILDING,
        ownerId: buildingUuid,
        email: currentUserEmail,
      };

      dispatch(logout.request(payload));
    }
  }, [buildingUuid, currentUserEmail, dispatch]);

  return <LogoutButton data-testid="logout-button" variant="Plain" text={logoutText} onClick={onLogout} />;
};
