import * as actions from './actions';

import {
  catchError, filter, map, switchMap, withLatestFrom, mergeMap,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { concat, of } from 'rxjs';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { replace } from 'store/router/actions';
import qs from 'qs';
import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { AjaxError } from 'rxjs/ajax';
import { REGISTER_ERROR_MESSAGES } from 'store/userAuth/constants';
import { RewardsStateEnum } from '../rewards/types';
import { getRewards, getRewardsBalance } from 'store/rewards/actions';
import { REWARDS_LIST_LIMIT } from 'shared/consts';
import { getCards } from 'store/cards/actions';

const defaultGetRewardsPayload = {
  states: [RewardsStateEnum.AVAILABLE, RewardsStateEnum.NOT_REDEEMED, RewardsStateEnum.ACTIVE],
  limit: REWARDS_LIST_LIMIT,
};

export const loginEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.login.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .login(payload)
    .pipe(
      mergeMap(() => {
        const searchString = qs.stringify({
          step: SSO_MODAL_CONTENT_STEPS.MAGIC_LINK,
          isSlideAnimationEnable: true,
        });

        return concat(of(actions.login.success()), of(replace(`/sso?${searchString}`)));
      }),
      catchError((error: Error) => of(actions.login.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);

export const logoutEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.logout.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .logout(payload)
    .pipe(
      map(() => actions.logout.success()),
      catchError((error) => of(actions.logout.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);

export const registerEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.register.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .register(payload)
    .pipe(
      mergeMap(() => {
        const searchString = qs.stringify({
          step: SSO_MODAL_CONTENT_STEPS.REGISTER_CARD,
          isSlideAnimationEnable: true,
        });

        return concat(
          of(actions.register.success()),
          of(
            getRewards.request({
              ...defaultGetRewardsPayload,
              ownerType: payload.ownerType,
              ownerId: payload.ownerId,
            }),
          ),
          of(replace(`/sso?${searchString}`)),
        );
      }),
      catchError((error: Error) => {
        if (
          error instanceof AjaxError
              && error?.response?.data?.detail === REGISTER_ERROR_MESSAGES.USER_ALREADY_REGISTERED
        ) {
          return of(actions.login.request(payload));
        }

        return of(actions.register.failure({ error, errorCode: getErrorCode(error) }));
      }),
    )),
);

export const finishLoginEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.finishLogin.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .finishLogin(payload)
    .pipe(
      switchMap(() =>
        concat(
          of(actions.finishLogin.success()),
          of(
            getRewards.request({
              ...defaultGetRewardsPayload,
              ownerType: payload.ownerType,
              ownerId: payload.ownerId,
            }),
          ),
          of(getCards.request()),
          of(getRewardsBalance.request({
            ownerType: payload.ownerType,
            ownerId: payload.ownerId,
          })),
        )),
      catchError((error: Error) => of(actions.finishLogin.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
