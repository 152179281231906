import React from 'react';

import { CardTile } from '../card-tile';
import {
  TilesContainer, Container, StyledLink, StyledIcon,
} from './styles';
import { useIntl } from 'react-intl';
import { Card } from 'store/cards/types';

interface CardTilesProps {
  isReverseAnimation: boolean;
  toggleIsReverseAnimation: VoidFunction;
  handleRegisterCardClick: VoidFunction;
  cards: Array<Card>;
}

export const CardsList = ({
  cards, handleRegisterCardClick, toggleIsReverseAnimation, isReverseAnimation,
}: CardTilesProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Container>
      <TilesContainer data-testid="cards-list">
        {
          cards.map(({ id, type, last_four }) => (
            <CardTile
              key={id}
              id={id}
              type={type}
              lastFour={last_four}
              toggleIsReverseAnimation={toggleIsReverseAnimation}
              isReverseAnimation={isReverseAnimation}
            />
          ),
          )
        }
      </TilesContainer>
      <StyledLink
        Icon={<StyledIcon />}
        iconDirection="left"
        variant="link"
        text={intl.formatMessage({ id: 'settings.paymentMethods.registerACard' })}
        disabled={false}
        data-testid="register-card-button"
        onClick={handleRegisterCardClick}
      />
    </Container>
  );
};
