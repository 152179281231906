import styled, { css } from 'styled-components';
import { slideInRight, slideOutLeft } from 'hocs/shared-styles';
import { Button } from '@hqo/react-components-library';

export const Container = styled.div<{ isSlideAnimationEnable: boolean, reverseSlideAnimation?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;
  ${({ isSlideAnimationEnable }) => isSlideAnimationEnable && 'transform: translate(-50%, -50%);'}
  ${({ isSlideAnimationEnable, reverseSlideAnimation }) => isSlideAnimationEnable
  && css`animation: ${reverseSlideAnimation ? slideOutLeft : slideInRight} 0.5s forwards;`}
`;

export const StyledButton = styled(Button)`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  background: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  border-color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  height: 48px;
  width: 85%;

  &:hover:not([disabled]), &:focus:not([disabled]) {
    border-color: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    background: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    opacity: 0.9;
  }
`;
