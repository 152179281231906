import { StateType } from 'typesafe-actions';
import BuildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import rewardsReducer from 'store/rewards/reducer';
import { introRewardReducer } from 'store/intro-reward/reducer';
import userAuthReducer from 'store/userAuth/reducer';
import cardsReducer from 'store/cards/reducer';
import { paymentReducer } from 'store/payment-methods/reducer';
import { externalLinksReducer } from 'store/external-links/reducer';
import spreedlyReducer from 'store/spreedly/reducer';
import { notificationSettingsReducer } from 'store/notification-settings/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  theme: themeReducer,
  errors: errorsReducer,
  building: BuildingReducer,
  uiMetadata: uiMetadataReducer,
  rewards: rewardsReducer,
  introReward: introRewardReducer,
  userAuth: userAuthReducer,
  cards: cardsReducer,
  paymentMethods: paymentReducer,
  externalLinks: externalLinksReducer,
  spreedly: spreedlyReducer,
  notificationSettings: notificationSettingsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
