import styled from 'styled-components';
import { PageHeader } from 'components/page-header';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledPageHeader = styled(PageHeader)`
  padding: 24px 16px 8px 16px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
`;

export const Title = styled.div`
  font: 500 16px/20px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  padding: 0 16px;
`;

export const Subtitle = styled.div`
  font: 400 12px/20px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  padding: 0 16px;
  margin-bottom: 13px;
`;
