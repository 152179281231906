import React from 'react';
import { SpinnerContainer } from './styles';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Spinner as ModalSpinner } from '@hqo/react-components-library/dist/atoms/spinner';

export const Spinner = (): JSX.Element => (
  <SpinnerContainer data-testid="spinner-container" data-cy="spinner-container">
    <ModalSpinner size="10em" color={defaultTheme.colors.$greyLight} />
  </SpinnerContainer>
);
