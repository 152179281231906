import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { Header, StyledIcon, Title } from 'components/page-header/styles';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SETTINGS_PATHS } from 'shared/consts';
import { replace } from 'store/router/actions';

interface PageHeaderProps {
  title: string;
  pageHeaderRef: React.MutableRefObject<HTMLDivElement>;
  className?: string;
}

export const PageHeader = ({ title, pageHeaderRef, className }: PageHeaderProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleSettingsIconClick = useCallback(() => {
    dispatch(replace(SETTINGS_PATHS.BASE));
  }, [dispatch]);

  return (
    <Header data-testid="header" ref={pageHeaderRef} className={className}>
      <Title data-testid="header-title">{title}</Title>
      <StyledIcon data-testid="settings-icon" icon={faGear} onClick={handleSettingsIconClick} />
    </Header>
  );
};
