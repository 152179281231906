import React from 'react';
import { useIntl } from 'react-intl';
import {
  ModalContentContainer,
  ModalContentTitle,
  ModalContentSubtitle,
  ButtonsContainer,
  SuccessIconWrapper,
  SuccessIcon,
  StyledCTAButton,
} from './styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';
import { isTruthyString } from 'utils/isTruthyString';
import { SUCCESS_CONTENT_GAP, SUCCESS_CONTENT_TOP } from '../constants';

interface ModalContentProps {
  handleCTAButtonClick: VoidFunction;
}

export const SuccessRequestContent = ({
  handleCTAButtonClick,
}: ModalContentProps): JSX.Element => {
  const intl = useIntl();
  const { isSlideAnimationEnable } = useSearchParams<SearchParams>();
  const isSlideAnimationExist = isTruthyString(isSlideAnimationEnable);

  return (
    <ModalContentContainer
      gap={SUCCESS_CONTENT_GAP}
      isSlideAnimationEnable={isSlideAnimationExist}
      data-testid="modal-content-container"
    >
      <SuccessIconWrapper data-testid="bottom-mobile-icon">
        <SuccessIcon />
      </SuccessIconWrapper>
      <ModalContentTitle top={SUCCESS_CONTENT_TOP}>
        {intl.formatMessage({ id: 'accountRemovalModal.requestSent' })}
      </ModalContentTitle>
      <ModalContentSubtitle>{intl.formatMessage({ id: 'accountRemovalModal.successMessage' })}</ModalContentSubtitle>
      <ButtonsContainer>
        <StyledCTAButton
          onClick={handleCTAButtonClick}
          text={intl.formatMessage({ id: 'done' })}
        />
      </ButtonsContainer>
    </ModalContentContainer>
  );
};
