import React, { useCallback } from 'react';

import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { StyledBottomMobileModal } from 'components/modals/styled-bottom-mobile-modal';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDispatch } from 'react-redux';
import { SearchParams } from 'shared/types';
import { goBack } from 'store/router/actions';

import { ModalContent } from './components/modal-content';

export const SsoFlow = (): JSX.Element => {
  const dispatch = useDispatch();
  const { step } = useSearchParams<SearchParams>();
  const isIframeStep = step === SSO_MODAL_CONTENT_STEPS.IFRAME;
  const onBackIconClick = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return (
    <StyledBottomMobileModal
      isIframeStep={isIframeStep}
      fullHeight
      className="sso-flow"
      reverseAnimation={false}
      isVisible
      content={<ModalContent />}
      withLeftArrowButton={isIframeStep}
      onPressBack={onBackIconClick}
    />
  );
};
