import React from 'react';
import {
  HistoryItemContainer,
  Title,
  DateBlock,
} from './styles';
import { formatDate } from '../utils/format-date';
import { useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { DEFAULT_LOCALE } from 'components/intl-provider/intl-provider.hooks';

interface HistoryItemProps {
  title: string;
  date: string;
}

export const HistoryItem = ({ title, date }: HistoryItemProps): JSX.Element => {
  const buildingLocale = useSelector(selectBuildingLocale) || DEFAULT_LOCALE;

  return (
    <HistoryItemContainer>
      <Title>{title}</Title>
      <DateBlock>{formatDate(date, buildingLocale)}</DateBlock>
    </HistoryItemContainer>
  );
};
