/* eslint-disable react/jsx-no-bind */
import { useLegalRequirements } from './hook/use-legal-requirements.hook';
import { UnderlinedText, Container } from 'components/legal-requirements/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import { THANX_BRAND_NAME } from 'shared/consts';
import { ExternalLinks } from 'store/external-links/types';

export interface LegalRequirementsProps {
  isExternalLinksLoading: boolean;
  externalLinks: ExternalLinks;
}

export const LegalRequirements = ({ isExternalLinksLoading, externalLinks }: LegalRequirementsProps) => {
  const intl = useIntl();
  const onClick = useLegalRequirements();

  if (isExternalLinksLoading) return null;

  return (
    <Container data-testid="legal-requirements-container">
      {intl.formatMessage(
        { id: 'legalRequirements.text' },
        {
          merchantName: THANX_BRAND_NAME,
          underlinedText1: (
            <UnderlinedText onClick={() => onClick(externalLinks.terms_and_conditions_link)}>
              {intl.formatMessage({ id: 'legalRequirements.termsAndConditions' })}
            </UnderlinedText>
          ),
          underlinedText2: (
            <UnderlinedText onClick={() => onClick(externalLinks.privacy_policy_link)}>
              {intl.formatMessage({ id: 'legalRequirements.privacyPolicy' })}
            </UnderlinedText>
          ),
        },
      )}
    </Container>
  );
};
