import {
  GetCurrentUserPayload, UserLoginResponse,
} from './types';
import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { DefaultParams } from 'store/rewards/types';

export const externalLogin = createAsyncAction(
  'user/EXTERNAL_LOGIN_REQUEST',
  'user/EXTERNAL_LOGIN_RESPONSE',
  'user/EXTERNAL_LOGIN_FAILURE',
)<[undefined, undefined], UserLoginResponse, FailureActionPayload>();

export const getCurrentUser = createAsyncAction(
  'user/GET_CURRENT_REQUEST',
  'user/GET_CURRENT_RESPONSE',
  'user/GET_CURRENT_FAILURE',
)<GetCurrentUserPayload, Partial<Pick<UserLoginResponse, 'user'>>, FailureActionPayload>();

export const deleteUser = createAsyncAction(
  'DELETE_USER_REQUEST',
  'DELETE_USER_RESPONSE',
  'DELETE_USER_FAILURE',
)<DefaultParams, [undefined, undefined], FailureActionPayload>();

export const refreshToken = createAction('config/REFRESH_TOKEN')<undefined>();
