import * as actions from './actions';
import { getRewards } from './actions';

import {
  catchError, filter, map, mergeMap, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { concat, EMPTY, of } from 'rxjs';
import { getErrorCode } from '../utils/get-error-code.util';
import {
  GetRewardResponse,
  GetRewardsBalanceResponse,
  OrderByEnum,
  RewardsAPIResponse,
  RewardsStateEnum,
  SortRewardsByEnum,
} from './types';
import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import qs from 'qs';
import { GET_REWARDS_ERROR_MESSAGES } from './constants';
import { replace } from '../router/actions';
import { history } from 'store/router/history';
import { REWARDS_LIST_LIMIT } from 'shared/consts';

const defaultGetRewardsPayload = {
  states: [RewardsStateEnum.AVAILABLE, RewardsStateEnum.NOT_REDEEMED, RewardsStateEnum.ACTIVE],
  limit: REWARDS_LIST_LIMIT,
};

export const getRewardsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getRewards.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .getRewards(payload)
    .pipe(
      map(({ response }: AjaxResponse<RewardsAPIResponse>) => actions.getRewards.success({
        rewards: response.data.rewards,
        total: response.total,
        count: response.count,
      })),
      catchError((error: Error) => {
        const isRedirecting = !history?.location?.search?.includes('code');

        if (error instanceof AjaxError) {
          let searchString = '';

          if (error.response?.data?.detail === GET_REWARDS_ERROR_MESSAGES.USER_NOT_REGISTERED) {
            searchString = qs.stringify({ step: SSO_MODAL_CONTENT_STEPS.WELCOME });
          } else if (error.response?.data?.detail === GET_REWARDS_ERROR_MESSAGES.USER_TOKEN_EXPIRED) {
            searchString = qs.stringify({ step: SSO_MODAL_CONTENT_STEPS.SIGN_IN });
          }

          return concat(
            of(actions.getRewards.failure({ error, errorCode: getErrorCode(error) })),
            isRedirecting && searchString ? of(replace(`/sso?${searchString}`)) : EMPTY,
          );
        }

        return concat(
          of(actions.getRewards.failure({ error, errorCode: getErrorCode(error) })),
          isRedirecting ? of(replace('error')) : EMPTY,
        );
      }),
    )),
);

export const getInfiniteRewardsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getInfiniteRewards.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]) => apiClient(state).getRewards(payload).pipe(
      map((
        { response }: AjaxResponse<RewardsAPIResponse>,
      ) => actions.getInfiniteRewards.success(response.data.rewards)),
      catchError((error) => of(actions.getInfiniteRewards.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const getUsedRewardsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getUsedRewards.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .getRewards({
      ...payload,
      states: [RewardsStateEnum.USED],
      sort_by: SortRewardsByEnum.USED_AT,
      sort_dir: OrderByEnum.DESC,
    })
    .pipe(
      map(({ response }: AjaxResponse<RewardsAPIResponse>) => actions.getUsedRewards.success({
        rewards: response.data.rewards,
        total: response.total,
        count: response.count,
      })),
      catchError((error) => of(actions.getUsedRewards.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);

export const getRewardsBalanceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getRewardsBalance.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]) => apiClient(state).getRewardsBalance(payload).pipe(
      map((
        { response }: AjaxResponse<GetRewardsBalanceResponse>,
      ) => actions.getRewardsBalance.success(response.data)),
      catchError((error) => of(actions.getRewardsBalance.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const activateRewardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.activateReward.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]) => apiClient(state).activateReward(payload).pipe(
      mergeMap(({ response }: AjaxResponse<GetRewardResponse>) => concat(
        of(actions.activateReward.success(response.data.reward)),
        of(
          getRewards.request({
            ...defaultGetRewardsPayload,
            ownerType: payload.ownerType,
            ownerId: payload.ownerId,
          }),
        ),
        of(replace(`/reward-details/${response.data.reward.id}`)),
      )),
      catchError((error) => of(actions.activateReward.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const finalizeRewardEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.finalizeReward.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]) => apiClient(state).finalizeReward(payload).pipe(
      map((
        { response }: AjaxResponse<GetRewardResponse>,
      ) => actions.finalizeReward.success(response.data.reward)),
      catchError((error) => of(actions.finalizeReward.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);
