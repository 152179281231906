import React from 'react';

import { BottomModalContentButtons } from 'components/bottom-modal-content/types';
import { StyledBottomMobileModal } from 'components/modals/styled-bottom-mobile-modal';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { selectDeleteCardStatus } from 'store/cards/selectors';

import { BottomModalContent } from '../../bottom-modal-content';

interface RemoveCardModalProps {
  isVisible: boolean;
  isReverseAnimation: boolean;
  buttons: Array<BottomModalContentButtons>;
}

export const RemoveCardModal = ({ isVisible, isReverseAnimation, buttons }: RemoveCardModalProps): JSX.Element => {
  const intl = useIntl();
  const isError = useSelector(selectDeleteCardStatus) === ACTION_STATUSES.REJECTED;

  return (
    <StyledBottomMobileModal
      reverseAnimation={isReverseAnimation}
      isVisible={isVisible}
      content={(
        <BottomModalContent
          title={intl.formatMessage({ id: 'removeCardModal.removeCard' })}
          subtitles={[intl.formatMessage({ id: 'removeCardModal.subtitle' })]}
          buttons={buttons}
          isError={isError}
          errorText={intl.formatMessage({ id: 'removeCardModal.errorText' })}
        />
      )}
    />
  );
};
