export const formatSavedPaymentMethodBrand = (
  brand: string,
): string => {
  const brandWords = brand?.split('_');

  return brandWords?.reduce((brandString, word) => {
    const capitalizedWord = `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`;
    const prevWords = brandString?.length === 0 ? '' : `${brandString} `;

    return `${prevWords}${capitalizedWord}`;
  }, '');
};
