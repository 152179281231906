import { ExternalLinksState } from 'store/external-links/types';
import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { ACTION_STATUSES } from 'shared/consts';

export const externalLinksStateSelector = (state: RootState): ExternalLinksState => state.externalLinks;

export const selectExternalLinks = createSelector(
  externalLinksStateSelector,
  (externalLinksState) => externalLinksState.externalLinks,
);

export const selectExternalLinksStatus = createSelector(
  externalLinksStateSelector,
  (externalLinksState) => externalLinksState?.getExternalLinks?.status,
);

export const selectIsExternalLinksLoading = createSelector(
  selectExternalLinksStatus,
  (status) => status === ACTION_STATUSES.PENDING,
);
