import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import qs from 'qs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SETTINGS_PATHS } from 'shared/consts';
import { push, replace } from 'store/router/actions';

export const useLegalRequirements = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const onClick = useCallback(
    (link: string) => {
      const isSettingsPage = pathname.includes(SETTINGS_PATHS.BASE);
      const searchString = qs.stringify({
        ...(!isSettingsPage && { step: SSO_MODAL_CONTENT_STEPS.IFRAME }),
        callback: link,
      });
      const route = `${pathname}?${searchString}`;

      if (isSettingsPage) {
        dispatch(replace(route));
      } else {
        dispatch(push(route));
      }
    },
    [pathname, dispatch],
  );

  return onClick;
};
