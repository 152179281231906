import React from 'react';

import { Container, Title } from './styles';

interface WelcomeBlockProps {
  icon: string;
  title: string;
  showBlock?: boolean;
}

export const WelcomeBlock = ({ title, icon, showBlock = true }: WelcomeBlockProps): JSX.Element => (
  <Container data-testid="welcome-block-container" showBlock={showBlock}>
    <img src={icon} alt="welcome-block-icon" />
    <Title>{title}</Title>
  </Container>
);
