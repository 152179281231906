export const formatDate = (dateToFormat: string, locale: string): string | null => {
  if (!dateToFormat) {
    return null;
  }

  const date = new Date(dateToFormat);

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);
};
