import React from 'react';
import {
  Container,
  Title,
  Subtitle,
  Image,
  CountBlock,
  TextContent,
  ActivePill,
  DescriptionContainer,
  StyledStar,
  CountTopLine,
  RedeemText,
  PointsNumber,
} from './styles';
import { useSelector } from 'react-redux';
import { selectIsUserWithRelatedWhitelabel } from 'store/config/selectors';
import RelatedPlaceholder from 'assets/images/reward-placeholder.png';
import DefaultPlaceholder from 'assets/images/default-placeholder.png';
import { useIntl } from 'react-intl';

interface RewardTileProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  points?: number | string;
  onClick?: VoidFunction;
  isActiveReward?: boolean;
}

export const RewardTile = ({
  title,
  subtitle,
  imageUrl,
  points,
  onClick,
  isActiveReward = false,
}: RewardTileProps): JSX.Element => {
  const isRelatedWhitelabel = useSelector(selectIsUserWithRelatedWhitelabel);
  const intl = useIntl();
  const imageUrlPlaceholder = isRelatedWhitelabel ? RelatedPlaceholder : DefaultPlaceholder;

  return (
    <Container data-testid="reward-tile-container" onClick={onClick}>
      {isActiveReward && <ActivePill data-testid="active-status">{intl.formatMessage({ id: 'active' })}</ActivePill>}
      <Image src={imageUrl ?? imageUrlPlaceholder} alt="reward-tile-image" />
      <TextContent>
        <DescriptionContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </DescriptionContainer>
        {Boolean(points) && (
          <CountBlock>
            <CountTopLine>
              <StyledStar />
              <PointsNumber>{points}</PointsNumber>
            </CountTopLine>
            <RedeemText>{intl.formatMessage({ id: 'redeem' })}</RedeemText>
          </CountBlock>
        )}
      </TextContent>
    </Container>
  );
};
