import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { replace } from 'store/router/actions';
import { DEFAULT_ANIMATION_DELAY } from 'shared/consts';

interface UseSignInContentReturnValues {
  handleLinkClick: VoidFunction;
}

export const useSignInContent = (): UseSignInContentReturnValues => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLinkClick = useCallback(() => {
    const searchString = qs.stringify({ step: SSO_MODAL_CONTENT_STEPS.SIGN_UP, isSlideAnimationEnable: true });

    setTimeout(() => {
      dispatch(replace(`${pathname}?${searchString}`));
    }, DEFAULT_ANIMATION_DELAY);
  }, [pathname]);

  return { handleLinkClick };
};
