import { ACTION_STATUSES } from 'shared/consts';
import { getNotificationSettings, patchNotificationSettings } from 'store/notification-settings/actions';
import { NotificationSettings, NotificationSettingsState } from 'store/notification-settings/types';
import { createReducer } from 'typesafe-actions';

export const INITIAL_STATE: NotificationSettingsState = {
  notificationSettings: null,
  getNotificationSettings: {
    status: null,
    error: null,
  },
  patchNotificationSettings: {
    status: null,
    error: null,
  },
  patchedSettings: [],
};

const getNotificationSettingsRequestHandler = (state: NotificationSettingsState): NotificationSettingsState => ({
  ...state,
  getNotificationSettings: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getNotificationSettingsSuccessHandler = (
  state: NotificationSettingsState,
  { payload }: ReturnType<typeof getNotificationSettings.success>,
): NotificationSettingsState => ({
  ...state,
  notificationSettings: payload,
  getNotificationSettings: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getNotificationSettingsFailureHandler = (
  state: NotificationSettingsState,
  { payload }: ReturnType<typeof getNotificationSettings.failure>,
): NotificationSettingsState => ({
  ...state,
  getNotificationSettings: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const patchNotificationSettingsRequestHandler = (
  state: NotificationSettingsState,
  { payload }: ReturnType<typeof patchNotificationSettings.request>,
): NotificationSettingsState => ({
  ...state,
  patchedSettings: [
    ...state.patchedSettings,
    ...(Object.keys(payload) as Array<Exclude<keyof NotificationSettings, 'id'>>),
  ],
  patchNotificationSettings: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const patchNotificationSettingsSuccessHandler = (
  state: NotificationSettingsState,
  { payload }: ReturnType<typeof patchNotificationSettings.success>,
): NotificationSettingsState => ({
  ...state,
  patchedSettings: [...state.patchedSettings.filter((setting) => setting !== payload.patchedSetting[0])],
  notificationSettings: payload.notificationSettings,
  patchNotificationSettings: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const patchNotificationSettingsFailureHandler = (
  state: NotificationSettingsState,
  { payload }: ReturnType<typeof patchNotificationSettings.failure>,
): NotificationSettingsState => ({
  ...state,
  patchedSettings: [...state.patchedSettings.filter((setting) => setting !== payload.patchedSetting[0])],
  patchNotificationSettings: {
    error: payload.error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

export const notificationSettingsReducer = createReducer(INITIAL_STATE)
  .handleAction(getNotificationSettings.request, getNotificationSettingsRequestHandler)
  .handleAction(getNotificationSettings.success, getNotificationSettingsSuccessHandler)
  .handleAction(getNotificationSettings.failure, getNotificationSettingsFailureHandler)
  .handleAction(patchNotificationSettings.request, patchNotificationSettingsRequestHandler)
  .handleAction(patchNotificationSettings.success, patchNotificationSettingsSuccessHandler)
  .handleAction(patchNotificationSettings.failure, patchNotificationSettingsFailureHandler);
