import { createSelector } from 'reselect';
import { RootState } from 'store/reducer';

export const spreedlySelector = (state: RootState) => state.spreedly;

export const selectSpreedlyCreditCardConfig = createSelector(
  [spreedlySelector],
  (spreedly) => spreedly.creditCardConfigSpreedly,
);

export const selectSpreedlyReady = createSelector([spreedlySelector], (spreedly) => spreedly.readySpreedly);

export const selectSpreedlyTokenizeCreditCardStatus = createSelector(
  [spreedlySelector],
  (spreedly) => spreedly.tokenizeCreditCardSpreedly.status,
);

export const selectSpreedlyPaymentMethodData = createSelector(
  [spreedlySelector],
  (spreedly) => spreedly.paymentMethodData,
);

export const selectSpreedlyInitializeStatus = createSelector(
  [spreedlySelector],
  (spreedly) => spreedly.initializeSpreedly.status,
);

export const selectSpreedlyReloadStatus = createSelector(
  [spreedlySelector],
  (spreedly) => spreedly.reloadSpreedly.status,
);

export const selectSpreedlyValidation = createSelector([spreedlySelector], (spreedly) => spreedly.validationSpreedly);

export const selectSpreedlyErrors = createSelector([spreedlySelector], (spreedly) => spreedly.errorsSpreedly);
