import styled, { css, keyframes } from 'styled-components';
import { BottomModal } from '@hqo/react-components-library/dist/molecules/modals/bottomModal';
import { DIMENSIONS, ZINDEX } from '@hqo/react-components-library';

type BottomModalProps = {
  reverseAnimation: boolean;
} & (
  | { fullHeight: true; isIframeStep: boolean }
  | { fullHeight?: false }
);

const slide = (reverseAnimation: boolean) => keyframes`
  from {
    transform: translateY(${reverseAnimation ? 0 : '100%'});
  }
  to {
    transform: translateY(${reverseAnimation ? '100%' : 0});
  }
`;

const fade = (reverseAnimation: boolean) => keyframes`
  from {
    opacity: ${reverseAnimation ? 1 : 0};
  }
  to {
    opacity: ${reverseAnimation ? 0 : 1};
  }
`;

export const StyledBottomMobileModal = styled(BottomModal)<BottomModalProps>`
  ${(props) =>
    (props.fullHeight
      ? css`
          .content {
            height: 100%;
          }

          .bottom-mobile-wrapper {
            height: 100%;
            padding: 0;
          }

          .bottom-mobile-content {
            height: 100%;
            overflow: hidden;
            padding: ${() => (props.isIframeStep ? '0' : '8px 24px')};
          }

          .container-content {
            @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
              height: 90%;
              animation: ${slide(props.reverseAnimation)} 0.4s;
            }
          }

          .background-container {
            @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
              animation: ${fade(props.reverseAnimation)} 0.4s;
            }
            z-index: ${ZINDEX.MODAL_CONTAINER};
            background-color: ${({ theme }) => theme.colorsRgba.black_5};
            backdrop-filter: blur(0);
          }
        `
      : css`
          .bottom-mobile-content {
            margin: 0;
          }

          .container-content {
            @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
              animation: ${slide(props.reverseAnimation)} 0.3s;
            }
          }

          .background-container {
            @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
              animation: ${fade(props.reverseAnimation)} 0.3s;
            }
            z-index: 14;
            background-color: ${({ theme }) => theme.colorsRgba.black_5};
            backdrop-filter: blur(0);
          }
        `)}
`;
