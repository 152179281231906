import { useIntl } from 'react-intl';
import { SETTINGS_PATHS } from 'shared/consts';

import { SettingTileProps } from '../components/setting-tile/setting-tile';

interface UseSettingsListContentReturnValues {
  tiles: Array<SettingTileProps>;
}
export const useSettingsListContent = (): UseSettingsListContentReturnValues => {
  const intl = useIntl();
  const tiles = [
    {
      title: intl.formatMessage({ id: 'settings.history.title' }),
      route: SETTINGS_PATHS.HISTORY,
    },
    {
      title: intl.formatMessage({ id: 'settings.notificationSettings.title' }),
      route: SETTINGS_PATHS.NOTIFICATION_SETTINGS,
    },
    {
      title: intl.formatMessage({ id: 'settings.paymentMethods.title' }),
      route: SETTINGS_PATHS.PAYMENT_METHODS,
    },
    {
      title: intl.formatMessage({ id: 'settings.viewTermsOfService.title' }),
      route: SETTINGS_PATHS.VIEW_TERMS_OF_SERVICE,
    },
    {
      title: intl.formatMessage({ id: 'settings.viewPrivacyPolicy.title' }),
      route: SETTINGS_PATHS.VIEW_PRIVACY_POLICY,
    },
    {
      title: intl.formatMessage({ id: 'settings.requestAccountRemoval.title' }),
      route: SETTINGS_PATHS.REQUEST_ACCOUNT_REMOVAL,
    },
  ];

  return { tiles };
};
