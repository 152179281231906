import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0px rgba(54, 54, 54, 0.20);

  :active {
    background-color: ${({ theme }) => theme.colors.palette.systemPinkPressed};
  }
`;

export const Title = styled.span`
  font: 500 16px / 22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 16px;
  font: 500 16px / 22px ${({ theme }) => theme.fonts.join()};
  background-color: ${({ theme }) => theme.colors.palette.systemPink};
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
