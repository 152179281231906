import React from 'react';

import { StyledBottomMobileModal } from 'components/modals/styled-bottom-mobile-modal';

import { ModalContent } from './components/modal-content';
import { useAccountRemovalModal } from './hooks/use-account-removal-modal.hook';

export const AccountRemovalModal = (): JSX.Element => {
  const { isReverseAnimation, buttons, redirectToTheLandingPage } = useAccountRemovalModal();

  return (
    <StyledBottomMobileModal
      reverseAnimation={isReverseAnimation}
      isVisible
      content={(
        <ModalContent buttons={buttons} redirectToTheLandingPage={redirectToTheLandingPage} />
      )}
    />
  );
};
