import { MILLIS_IN_SECOND, SECONDS_IN_MINUTE } from 'shared/consts';

export const formatActivationExpiration = ({ activated_at, expiration_window }: {
  activated_at: string;
  expiration_window: number;
}): Date => {
  if (!activated_at || !expiration_window) {
    return new Date();
  }

  const activatedAtDatetime = new Date(activated_at);
  const expirationTime = activatedAtDatetime.getTime() + expiration_window * MILLIS_IN_SECOND * SECONDS_IN_MINUTE;
  const activationExpiresDatetime = new Date(expirationTime);

  return activationExpiresDatetime;
};

export function pastActivationExpirationTime({ activated_at, expiration_window }: {
  activated_at?: string;
  expiration_window?: number;
  }): boolean {
  if (!activated_at || !expiration_window) {
    return false;
  }

  return formatActivationExpiration({ activated_at, expiration_window }) < new Date();
}
