import styled from 'styled-components';

import { MailTo } from '@hqo/react-components-library';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  gap: 15px;
`;

export const StyledMailTo = styled(MailTo)`
  display: flex;
  justify-content: center;
  width: calc(100% - 32px);
  padding: 13px 16px;
  background-color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font: 500 16px / 22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
`;
