export enum CouponCodeTypes {
  RAW = 'raw',
  QRCODE = 'qrcode',
  BARCODE39 = 'barcode39',
  BARCODE39EXTENDED = 'barcode39extended',
  BARCODE128 = 'barcode128',
  BARCODE_UPC_A = 'barcode_upc_a',
  BARCODE_EAN_8 = 'barcode_ean_8',
  BARCODE_EAN_13 = 'barcode_ean_13',
  BARCODE25INTERLEAVED = 'barcode25interleaved'
}
