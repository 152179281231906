import styled from 'styled-components';
import { DEFAULT_CONTAINER_PADDING } from 'shared/consts';

export const Container = styled.div<{ pageHeaderHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: calc(100% - ${2 * DEFAULT_CONTAINER_PADDING}px);
  max-height: ${({ pageHeaderHeight }) => `${pageHeaderHeight ? `calc(100% - ${pageHeaderHeight}px)` : '100%'}`};
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 ${DEFAULT_CONTAINER_PADDING}px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const RewardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;

  && > :first-child {
    margin-top: 10px;
  }

  && > :last-child {
    margin-bottom: 20px;
  }
`;
