import styled, { css } from 'styled-components';
import { Button, VariantTypes } from '@hqo/react-components-library';

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const ModalContentTitle = styled.div`
  font: 700 28px/normal ${({ theme }) => theme.fonts.join()};
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ModalContentSubtitle = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  text-align: center;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const StyledButton = styled(Button)<{ variant?: VariantTypes }>`
  height: 48px;
  width: 100%;
  ${({ variant, theme }) =>
    variant == null
    && css`
      font: 500 16px / 22px ${theme.fonts.join()};
      background: ${theme.colors.palette.halfMoonBlue};
      border-color: ${theme.colors.palette.halfMoonBlue};
    `}
`;

export const ErrorText = styled.div`
  font: italic 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.admin.pink};
`;
