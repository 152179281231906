export const APPLE_PAY_METHOD_TYPE = 'apple_pay';

export const TOKENIZATION_METHOD = 'Spreedly';

export const SPREEDLY_CARD_REF = 'number';
export const SPREEDLY_CVV_REF = 'cvv';
export const SPREEDLY_CARD_ID = 'spreedly-credit-card';
export const SPREEDLY_CVV_ID = 'spreedly-cvv';
export const EXPDATE_LENGTH = 5;
export const MIN_ZIP_CODE_LENGTH = 5;

export enum CARD_BRANDS {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MASTER = 'master',
  AMERICAN_EXPRESS = 'american_express',
  AMEX = 'amex',
  DISCOVER = 'discover',
}
