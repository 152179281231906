import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
`;

export const StyledIFrame = styled.iframe<{isLoading: boolean}>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  border: 0;
`;
