import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingUuid } from 'store/building/selectors';
import { useCallback } from 'react';
import { selectCurrentUserEmail } from 'store/user/selectors';
import { BUILDING } from 'shared/consts';
import { login } from 'store/userAuth/actions';

interface UseMagicLinkContentReturnValues {
  handleResendLink: VoidFunction;
}

export const useMagicLinkContent = (): UseMagicLinkContentReturnValues => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(selectBuildingUuid);
  const currentUserEmail = useSelector(selectCurrentUserEmail);

  const handleResendLink = useCallback(() => {
    if (buildingUuid != null) {
      const payload = {
        ownerType: BUILDING,
        ownerId: buildingUuid,
        email: currentUserEmail,
      };

      dispatch(login.request(payload));
    }
  }, [dispatch, buildingUuid, currentUserEmail]);

  return { handleResendLink };
};
