import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationSettings } from 'store/notification-settings/actions';
import {
  selectIsGetNotificationSettingsStatusPending,
  selectNotificationSettingsForRender,
} from 'store/notification-settings/selectors';
import { Container, Invitation } from './styles';
import { useIntl } from 'react-intl';
import { NotificationSetting } from './components/notification-setting';
import { RewardBlock } from 'components/skeleton/styles';

export const NotificationSettingsContent = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const notificationSettings = useSelector(selectNotificationSettingsForRender);
  const isGetNotificationSettingsPending = useSelector(selectIsGetNotificationSettingsStatusPending);
  useEffect(() => {
    dispatch(getNotificationSettings.request());
  }, []);

  return (
    <Container>
      <Invitation>{intl.formatMessage({ id: 'settings.notificationSettings.emailMe' })}</Invitation>
      {isGetNotificationSettingsPending ? (
        <>
          <RewardBlock />
          <RewardBlock />
        </>
      ) : (
        notificationSettings.map((setting) => <NotificationSetting setting={setting} key={setting.title} />)
      )}
    </Container>
  );
};
