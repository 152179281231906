import React, { useEffect } from 'react';
import bwipjs from 'bwip-js';
import { CodeContainer } from 'components/coupon-code/components/styles';

interface BarcodeProps {
  code: string;
  codeType: string;
}

export const Barcode = ({ code, codeType }: BarcodeProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    bwipjs.toCanvas('barcode-canvas', {
      bcid: codeType,
      text: code,
      includetext: true,
      textxalign: 'center',
      scale: 1,
    });
  }, [codeType, code]);

  return (
    <CodeContainer data-testid="bar-code"><canvas id="barcode-canvas" /></CodeContainer>
  );
};
