import React from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';
import { Container, StyledIFrame } from './styles';
import { useIframeContent } from 'components/modals/iframe/hooks/use-iframe-content.hook';
import { Spinner } from 'components/spinner';

export interface IframeContentProps {
  callbackOverride?: string;
}

export const IframeContent = ({ callbackOverride }: IframeContentProps): JSX.Element => {
  const { callback } = useSearchParams<SearchParams>();
  const { isLoading, handleLoad } = useIframeContent();

  return (
    <Container data-testid="iframe-content-container">
      {isLoading && <Spinner />}
      <StyledIFrame
        data-testid="content-viewer-iframe"
        src={callback ?? callbackOverride}
        onLoad={handleLoad}
        isLoading={isLoading}
      />
    </Container>
  );
};
