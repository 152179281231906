import {
  ActivateRewardPayload,
  DefaultParams,
  FinalizeRewardPayload,
  GetRewardsPayload,
  GetRewardsResponse,
  Reward,
  RewardsBalance,
} from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getRewards = createAsyncAction(
  'GET_REWARDS_REQUEST',
  'GET_REWARDS_SUCCESS',
  'GET_REWARDS_FAILURE',
)<GetRewardsPayload, GetRewardsResponse, FailureActionPayload>();

export const getUsedRewards = createAsyncAction(
  'GET_USED_REWARDS_REQUEST',
  'GET_USED_REWARDS_SUCCESS',
  'GET_USED_REWARDS_FAILURE',
)<DefaultParams, GetRewardsResponse, FailureActionPayload>();

export const getRewardsBalance = createAsyncAction(
  'GET_REWARDS_BALANCE_REQUEST',
  'GET_REWARDS_BALANCE_SUCCESS',
  'GET_REWARDS_BALANCE_FAILURE',
)<DefaultParams, RewardsBalance, FailureActionPayload>();

export const activateReward = createAsyncAction(
  'ACTIVATE_REWARD_REQUEST',
  'ACTIVATE_REWARD_SUCCESS',
  'ACTIVATE_REWARD_FAILURE',
)<ActivateRewardPayload, Reward, FailureActionPayload>();

export const finalizeReward = createAsyncAction(
  'FINALIZE_REWARD_REQUEST',
  'FINALIZE_REWARD_SUCCESS',
  'FINALIZE_REWARD_FAILURE',
)<FinalizeRewardPayload, Reward, FailureActionPayload>();

export const getInfiniteRewards = createAsyncAction(
  'GET_INFINITE_REWARDS_REQUEST',
  'GET_INFINITE_REWARDS_SUCCESS',
  'GET_INFINITE_REWARDS_FAILURE',
)<GetRewardsPayload, Array<Reward>, FailureActionPayload>();

export const resetRewardsState = createAction('RESET_REWARDS_STATE')<undefined>();

export const resetUsedRewardsState = createAction('RESET_USED_REWARDS_STATE')<undefined>();

export const resetActivateRewardState = createAction('RESET_ACTIVATE_REWARD_STATE')<undefined>();
export const resetFinalizeRewardState = createAction('RESET_FINALIZE_REWARD_STATE')<undefined>();

export const imageIsLoaded = createAction('IMAGE_IS_LOADED')<boolean>();
