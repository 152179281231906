export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';
export const BUILDING = 'BUILDING';
export const VERTICAL_NAME = '';
export const EMPTY_STRING = '';
export const THANX_BRAND_NAME = 'HY+';
export const APP_UUID = 'thanx_miniapp';

export const DEFAULT_LOCALE = 'en-US';

export enum WHITELABELS {
  RELATED = 'RELATED',
  RELATEDCONNECT = 'RELATEDCONNECT',
}

export const SETTINGS_MODAL = 'settings_modal';
export const MAGIC_LINK_MODAL = 'magic_link_modal';

export const REWARDS_LIST_LIMIT = 5;

export const HISTORY_REWARDS_LIST_LIMIT = 10;

export const DEFAULT_CONTAINER_PADDING = 16;

export const EMPTY_BALANCE = 0;

export const DEFAULT_ANIMATION_DELAY = 300;

export const DEFAULT_SLIDE_ANIMATION_DELAY = 200;

export const MILLIS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;

export const PROMISE_TIMEOUT = 5_000;

export enum TRACK_EVENT_NAMES {
  THANX_REWARD_TILE_CLICK = 'thanx_reward_tile_click',
  THANX_REWARD_REDEMPTION = 'thanx_reward_redemption',
  THANX_CONTACT_SUPPORT_CLICK = 'thanx_contact_support_click',
}

export enum TRACK_EVENT_TYPES {
  ACTION = 'action',
  IMPRESSION = 'impression',
}
