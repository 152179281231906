export const ROOT_PATH = '/' as const;
export const ERROR_PATH = '/error' as const;

export enum SETTINGS_PATHS {
  BASE = '/settings',
  HISTORY = '/settings/history',
  NOTIFICATION_SETTINGS = '/settings/notification-settings',
  PAYMENT_METHODS = '/settings/payment-methods',
  REGISTER_CARD = '/settings/payment-methods/register-card',
  REMOVE_CARD = '/settings/payment-methods/remove-card',
  VIEW_TERMS_OF_SERVICE = '/settings/view-terms-of-service',
  VIEW_PRIVACY_POLICY = '/settings/view-privacy-policy',
  REQUEST_ACCOUNT_REMOVAL = 'request-account-removal',
}
