import React from 'react';
import {
  ModalContentContainer, ModalContentTitle, ModalContentSubtitle, ButtonsContainer, StyledButton, ErrorText,
} from './styles';
import { BottomModalContentProps } from './types';

export const BottomModalContent = ({
  title,
  subtitles,
  buttons,
  isError,
  errorText,
}: BottomModalContentProps): JSX.Element => (
  <ModalContentContainer data-testid="modal-content-container">
    <ModalContentTitle>{title}</ModalContentTitle>
    {subtitles.map((subtitle) => (
      <ModalContentSubtitle key={`${subtitle}`}>{subtitle}</ModalContentSubtitle>
    ))}
    <ButtonsContainer>
      {isError && <ErrorText>{errorText}</ErrorText>}
      {buttons.map(({
        isDisabled, isLoading, text, onClick, variant,
      }) => (
        <StyledButton
          key={text}
          loading={isLoading}
          disabled={isDisabled}
          onClick={onClick}
          text={text}
          variant={variant}
        />
      ))}
    </ButtonsContainer>
  </ModalContentContainer>
);
