import { useEffect } from 'react';

import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, BUILDING } from 'shared/consts';
import { selectBuildingUuid } from 'store/building/selectors';
import { push } from 'store/router/actions';
import { finishLogin, resetFinishLoginStatus } from 'store/userAuth/actions';
import { selectFinishLoginStatus } from 'store/userAuth/selectors';

type MagicLinkParams = { code?: string};

export const useMagicLinkRedirect = (): boolean => {
  const dispatch = useDispatch();
  const { code } = useSearchParams<MagicLinkParams>();
  const buildingUuid = useSelector(selectBuildingUuid);
  const finishLoginStatus = useSelector(selectFinishLoginStatus);

  useEffect(() => {
    if (finishLoginStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(push('/'));
    } else if (finishLoginStatus === ACTION_STATUSES.REJECTED) {
      dispatch(push(`/sso?step=${SSO_MODAL_CONTENT_STEPS.SIGN_IN}`));
      dispatch(resetFinishLoginStatus());
    }
  }, [dispatch, finishLoginStatus]);

  useEffect(() => {
    if (code != null && buildingUuid != null && finishLoginStatus == null) {
      dispatch(finishLogin.request({
        ownerType: BUILDING,
        ownerId: buildingUuid,
        code,
      }));
    }
  }, [dispatch, code, buildingUuid, finishLoginStatus]);

  return finishLoginStatus === ACTION_STATUSES.PENDING;
};
