import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'store/router/actions';

import { Container, StyledIcon, Title } from './styles';
import { useLocation } from 'react-router-dom';
import { SETTINGS_PATHS } from 'shared/consts';
import { resetUsedRewardsState } from 'store/rewards/actions';

export interface SettingTileProps {
  title: string;
  route: string;
}

export const SettingTile = ({ title, route }: SettingTileProps): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleSettingTileClick = useCallback(() => {
    if (route.includes(SETTINGS_PATHS.HISTORY)) {
      dispatch(resetUsedRewardsState());
    }

    if (route.includes(SETTINGS_PATHS.BASE)) {
      dispatch(replace(route));
    } else {
      dispatch(replace(`${pathname}/${route}`));
    }
  }, [route, dispatch, pathname]);

  return (
    <Container data-testid="setting-tile" onClick={handleSettingTileClick}>
      <Title data-testid="setting-tile-title">{title}</Title>
      <StyledIcon data-testid="setting-tile-icon" />
    </Container>
  );
};
