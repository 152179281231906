import React, { useEffect, useState } from 'react';
import * as qrcode from 'qrcode';
import { CodeContainer } from 'components/coupon-code/components/styles';

export interface QRCodeProps {
  value: string;
}

export const QRCode = ({ value }: QRCodeProps) => {
  const [qr, setQr] = useState<string>('');
  useEffect(() => {
    qrcode.toString(value, { type: 'svg' }, (error, xurl) => {
      if (!error) {
        setQr(`data:image/svg+xml;utf8,${encodeURIComponent(xurl)}`);
      }
    });
  });

  return (
    <CodeContainer data-testid="qrcode">
      {qr && <img style={{ width: '100%' }} src={qr} alt="Redeem QRCode" data-testid="qr-code" />}
    </CodeContainer>
  );
};
