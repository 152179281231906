export enum ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS {
  SUCCESS = 'success',
  FAILED = 'failed',
  DEFAULT = 'default',
}

export const DEFAULT_GAP = 20;
export const SUCCESS_CONTENT_GAP = 16;
export const DEFAULT_TOP = 10;
export const SUCCESS_CONTENT_TOP = 0;
