import React from 'react';

import {
  Container,
} from './styles';

import { PaymentMethodManagerContent } from 'components/modals/payment-method-manager/payment-method-manager-content';
import { IframeContent } from 'components/modals/iframe';

interface RegisterCardContentProps {
  callback?: boolean;
}

export const RegisterCardContent = ({ callback }: RegisterCardContentProps): JSX.Element => (

  callback
    ? <IframeContent />

    : (
      <Container data-testid="register-card-content">
        <PaymentMethodManagerContent isSettingsModal />
      </Container>
    )
);
