import React from 'react';
import { useIntl } from 'react-intl';
import { useEmailInputButton } from '../email-input-button/hooks/use-email-input-button.hook';
import { EmailInputButton } from '../email-input-button';
import { LinkText } from './styles';
import { useSignInContent } from './hooks/use-sign-in-content.hook';

export const SignInContent = (): JSX.Element => {
  const intl = useIntl();
  const {
    email, handleChangeEmail, onLoginButtonClick, isLoginFailed, isLoginPending,
  } = useEmailInputButton();
  const { handleLinkClick } = useSignInContent();
  const subtitle = intl.formatMessage(
    { id: 'signInModal.subtitle' },
    {
      linkText: <LinkText onClick={handleLinkClick}>{intl.formatMessage({ id: 'signUpModal.signUp' })}</LinkText>,
    },
  ) as string;

  return (
    <EmailInputButton
      title={intl.formatMessage({ id: 'signInModal.title' })}
      subtitle={subtitle}
      value={email}
      onChange={handleChangeEmail}
      onClick={onLoginButtonClick}
      isExternalError={isLoginFailed}
      isButtonLoading={isLoginPending}
    />
  );
};
