import { RootState } from 'store/reducer';
import { User } from 'store/user/types';
import { createSelector } from 'reselect';
import { ACTION_STATUSES } from 'shared/consts';

export const userState = (state: RootState): RootState['user'] => state.user;

export const userLoading = createSelector(userState, (state: RootState['user']): boolean => state.loading);
export const currentUser = createSelector(userState, (state: RootState['user']): User | null => state.user);
export const userError = createSelector(userState, (state: RootState['user']): Error | null => state.error);
export const hasToken = createSelector(userState, (state: RootState['user']): boolean => !!state.token);
export const isUserLoaded = createSelector(
  userState,
  (state: RootState['user']): boolean => state.loading === false && state.user !== null,
);
export const selectCurrentUserEmail = createSelector(userState, (state: RootState['user']): string => state.user.email);

export const selectDeleteUserStatus = createSelector(
  userState,
  (state: RootState['user']): ACTION_STATUSES => state.deleteUser.status);

export const selectDeleteUserError = createSelector(
  userState,
  (state: RootState['user']): Error => state.deleteUser.error);
