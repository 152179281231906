import { FailureActionPayload } from 'store/errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { Card, DeleteCardPayload, RegisterCardPayload } from './types';

export const registerCard = createAsyncAction(
  'REGISTER_CARD_REQUEST',
  'REGISTER_CARD_SUCCESS',
  'REGISTER_CARD_FAILURE',
)<
  RegisterCardPayload,
  [undefined, undefined],
  FailureActionPayload
>();

export const getCards = createAsyncAction(
  'GET_CARDS_REQUEST',
  'GET_CARDS_SUCCESS',
  'GET_CARDS_FAILURE',
)<
[undefined, undefined],
Array<Card>,
FailureActionPayload
>();

export const deleteCard = createAsyncAction(
  'DELETE_CARD_REQUEST',
  'DELETE_CARD_SUCCESS',
  'DELETE_CARD_FAILURE',
)<
DeleteCardPayload,
[undefined, undefined],
FailureActionPayload
>();

export const resetRegisterCardState = createAction('RESET_REGISTER_CARD_STATE')<void>();

export const resetDeleteCardState = createAction('RESET_DELETE_CARD_STATE')<void>();
