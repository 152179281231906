import * as paymentActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { PaymentState } from './types';

export const initialState: PaymentState = {
  paymentMethods: null,
  getPaymentMethods: {
    status: null,
    error: null,
  },
  savePaymentMethod: {
    status: null,
    error: null,
  },
  deletePaymentMethod: {
    status: null,
    error: null,
  },
};

const getPaymentMethodsRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  getPaymentMethods: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPaymentMethodsSuccessHandler = (
  state: PaymentState,
  { payload }: ActionType<typeof paymentActions.getPaymentMethods.success>,
): PaymentState => ({
  ...state,
  paymentMethods: payload,
  getPaymentMethods: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getPaymentMethodsFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.getPaymentMethods.failure>,
): PaymentState => ({
  ...state,
  getPaymentMethods: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const savePaymentMethodRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  savePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const savePaymentMethodSuccessHandler = (
  state: PaymentState,
): PaymentState => ({
  ...state,
  savePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const savePaymentMethodFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.savePaymentMethod.failure>,
): PaymentState => ({
  ...state,
  savePaymentMethod: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const deletePaymentMethodRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  deletePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const deletePaymentMethodSuccessHandler = (
  state: PaymentState,
  { payload }: ActionType<typeof paymentActions.deletePaymentMethod.success>,
): PaymentState => ({
  ...state,
  paymentMethods: state.paymentMethods?.filter((paymentMethod) => paymentMethod.id !== payload),
  deletePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const deletePaymentMethodFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.deletePaymentMethod.failure>,
): PaymentState => ({
  ...state,
  deletePaymentMethod: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetSavePaymentMethodHandler = (
  state: PaymentState,
): PaymentState => ({
  ...state,
  savePaymentMethod: initialState.savePaymentMethod,
});

export const paymentReducer = createReducer(initialState)
  .handleAction(paymentActions.getPaymentMethods.request, getPaymentMethodsRequestHandler)
  .handleAction(paymentActions.getPaymentMethods.success, getPaymentMethodsSuccessHandler)
  .handleAction(paymentActions.getPaymentMethods.failure, getPaymentMethodsFailureHandler)
  .handleAction(paymentActions.savePaymentMethod.request, savePaymentMethodRequestHandler)
  .handleAction(paymentActions.savePaymentMethod.success, savePaymentMethodSuccessHandler)
  .handleAction(paymentActions.savePaymentMethod.failure, savePaymentMethodFailureHandler)
  .handleAction(paymentActions.deletePaymentMethod.request, deletePaymentMethodRequestHandler)
  .handleAction(paymentActions.deletePaymentMethod.success, deletePaymentMethodSuccessHandler)
  .handleAction(paymentActions.deletePaymentMethod.failure, deletePaymentMethodFailureHandler)
  .handleAction(paymentActions.resetSavePaymentMethod, resetSavePaymentMethodHandler);
