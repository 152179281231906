import styled, { css } from 'styled-components';
import { FormField, Button } from '@hqo/react-components-library';
import { slideInRight, slideOutLeft } from 'hocs/shared-styles';

export const Container = styled.div<{ isSlideAnimationEnable: boolean, reverseSlideAnimation?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ isSlideAnimationEnable }) => isSlideAnimationEnable && 'transform: translate(-50%, -50%);'}
  ${({ isSlideAnimationEnable, reverseSlideAnimation }) => isSlideAnimationEnable
    && css`animation: ${reverseSlideAnimation ? slideOutLeft : slideInRight} 0.5s forwards;`}
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Title = styled.div`
  font: 500 18px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
`;

export const Subtitle = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
  user-select: none;
`;

export const StyledButton = styled(Button)`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  background: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  border-color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
  height: 48px;
  width: 85%;
  margin-top: 24px;

  &:hover:not([disabled]), &:focus:not([disabled]) {
    border-color: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    background: ${({ theme }) => theme.colors.palette.halfMoonBlueHover};
    opacity: 0.9;
  }
`;

export const StyledFormField = styled(FormField)`
  width: 85%;
  height: 94px;
  margin-top: 24px;

  span {
    padding: 2px 0 0 0;
  }

  .field-label {
    font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
    color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  }
`;
