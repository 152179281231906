import { createSelector } from 'reselect';
import { RootState } from 'store/reducer';

const themeSelector = (state: RootState) => state.theme;

export const selectBrandTheme = createSelector([themeSelector], (theme) => theme.theme);

export const selectThemeFont = createSelector([themeSelector], (theme) => theme.theme?.font);

export const selectIsThemeLoaded = createSelector(
  themeSelector,
  (themeState) => themeState.loading === false && themeState.theme !== null,
);
