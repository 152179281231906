import { styled } from 'styled-components';

export const UnderlinedText = styled.div`
  text-decoration: underline;
  display: inline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.palette.halfMoonBlue};
`;

export const Container = styled.div`
  text-align: center;
  font: 400 10px/12px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;
