import { TRACK_EVENT_TYPES } from 'shared/consts';
import { track } from '@hqo/web-tracking';
import { useCallback } from 'react';

interface UseTrackEventProps {
  eventName: string;
  eventType: TRACK_EVENT_TYPES;
  metadata: Record<string, string | number>;
}

interface UseTrackEventReturnValues {
  handleTrackEvent: VoidFunction;
}

export const useTrackEvent = ({ eventName, eventType, metadata }: UseTrackEventProps): UseTrackEventReturnValues => {
  const handleTrackEvent = useCallback(() => {
    track(
      eventName,
      {
        ...metadata,
        type: eventType,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  return { handleTrackEvent };
};
