import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExternalLinks } from 'store/external-links/actions';
import { selectExternalLinks, selectIsExternalLinksLoading } from 'store/external-links/selectors';

export const useLegalRequirements = () => {
  const dispatch = useDispatch();
  const isExternalLinksLoading = useSelector(selectIsExternalLinksLoading);
  const externalLinks = useSelector(selectExternalLinks);

  useEffect(() => {
    if (!externalLinks) {
      dispatch(getExternalLinks.request());
    }
  }, [dispatch, externalLinks]);

  return { isExternalLinksLoading, externalLinks };
};
