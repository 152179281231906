import { CodeContainer } from 'components/coupon-code/components/styles';
import React from 'react';

export interface DefaultCodeRender {
  code: string;
}

export const DefaultCodeRender = ({ code }: DefaultCodeRender) => (
  <CodeContainer data-testid="default-code">{code}</CodeContainer>
);
