import { ConfigState } from 'store/config/types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { WHITELABELS } from '../../shared/consts';

export const configSelector = (state: RootState): ConfigState => state?.config;

export const selectLocale = createSelector(configSelector, (state: ConfigState): string => state?.locale || 'en');

export const hasToken = createSelector(configSelector, ({ authToken }: RootState['config']): boolean => !!authToken);

export const selectIsUserWithRelatedWhitelabel = createSelector(
  configSelector,
  (state: ConfigState): boolean => state.appBrand === WHITELABELS.RELATED
    || state.appBrand === WHITELABELS.RELATEDCONNECT,
);
