import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';

interface UseIframeContentReturnValues {
  isLoading: boolean;
  handleLoad: VoidFunction;
}

export const useIframeContent = (): UseIframeContentReturnValues => {
  const { value: isLoading, setFalse: handleLoad } = useBooleanState(true);

  return { isLoading, handleLoad };
};
