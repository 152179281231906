import React from 'react';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { Private } from 'components/router/private';
import { ErrorPage } from 'pages/error';
import { LandingPage } from 'pages/landing-page';
import { Route, Routes } from 'react-router-dom';

import { DefaultRedirect } from './default-redirect';
import { RewardDetailsPage } from 'pages/reward-details-page';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path="/*" element={<Private><LandingPage /></Private>} />
      <Route path="/reward-details/:rewardId" element={<Private><RewardDetailsPage /></Private>} />
      <Route path="/error" element={<ErrorPage />} />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
