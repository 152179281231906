import * as actions from './actions';

import {
  catchError, filter, map, mergeMap, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { concat, of } from 'rxjs';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { registerCard } from 'store/cards/actions';
import { BUILDING } from 'shared/consts';
import { resetTokenizeCreditCardSpreedly } from 'store/spreedly/actions';

export const getPaymentMethodsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getPaymentMethods.request)),
  withLatestFrom(state$),
  switchMap(([, state]) => apiClient(state)
    .getPaymentMethods()
    .pipe(
      map((data) => actions.getPaymentMethods.success(data.response)),
      catchError((error: Error) => of(actions.getPaymentMethods.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);

export const savePaymentMethodEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.savePaymentMethod.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .savePaymentMethod(payload)
    .pipe(
      mergeMap((data) => {
        const ownerType = BUILDING;
        const ownerId = state.building.building.uuid;

        return concat(
          of(actions.savePaymentMethod.success(data.response)),
          of(registerCard.request({
            ownerType, ownerId, payment_token: payload.payment_token, new_payment_method_id: data.response.id,
          })));
      }),
      catchError((error: Error) => of(actions.savePaymentMethod.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
export const deletePaymentMethodEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.deletePaymentMethod.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .deletePaymentMethod(payload)
    .pipe(
      mergeMap(() => concat(
        of(actions.deletePaymentMethod.success(payload)),
        of(resetTokenizeCreditCardSpreedly()),
      )),
      catchError((error: Error) => of(actions.deletePaymentMethod.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
