import React from 'react';
import { useSettingsPage } from './hooks/use-settings-page.hook';
import { StyledSwipeModal } from './styles';

export const SettingsPage = (): JSX.Element => {
  const {
    onClose, isReverseAnimation, swipeModalProps, scrollContainerRef,
  } = useSettingsPage();

  return (
    <StyledSwipeModal
      title={swipeModalProps.title}
      className="settings-swipe-modal"
      onClose={onClose}
      reverseAnimation={isReverseAnimation}
      content={swipeModalProps.content}
      withLeftArrowButton={swipeModalProps.withLeftArrowButton}
      onPressBack={swipeModalProps.onPressBack}
      noContentWrapperPadding
      footerContent={swipeModalProps.footerContent}
      isIframe={swipeModalProps.isIframe}
      scrollContainerRef={scrollContainerRef}
    />
  );
};
