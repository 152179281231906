import React from 'react';
import { SwitchModalContent } from './switch-modal-content';
import { MagicLinkContent } from './magic-link-content';
import { SSO_MODAL_CONTENT_STEPS } from '../constants';
import { SignInContent } from './sign-in-content';
import { SignUpContent } from './sign-up-content';
import { WelcomeContent } from './welcome-content';
import { PaymentMethodManagerContent } from 'components/modals/payment-method-manager/payment-method-manager-content';
import { IframeContent } from 'components/modals/iframe';

export const ModalContent = (): JSX.Element => (
  <SwitchModalContent>
    <SignInContent data-value={SSO_MODAL_CONTENT_STEPS.SIGN_IN} />
    <SignUpContent data-value={SSO_MODAL_CONTENT_STEPS.SIGN_UP} />
    <PaymentMethodManagerContent data-value={SSO_MODAL_CONTENT_STEPS.REGISTER_CARD} />
    <MagicLinkContent data-value={SSO_MODAL_CONTENT_STEPS.MAGIC_LINK} />
    <WelcomeContent data-value={SSO_MODAL_CONTENT_STEPS.WELCOME} />
    <IframeContent data-value={SSO_MODAL_CONTENT_STEPS.IFRAME} />
  </SwitchModalContent>
);
