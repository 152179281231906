import React from 'react';

import { SettingTile } from './components/setting-tile/setting-tile';
import { useSettingsListContent } from './hooks/useSettingsListContent.hook';
import { TilesContainer } from './styles';
import { Route, Routes } from 'react-router-dom';
import { AccountRemovalModal } from 'components/modals/account-removal-modal';
import { SETTINGS_PATHS } from 'shared/consts';

export const SettingsListContent = (): JSX.Element => {
  const { tiles } = useSettingsListContent();

  return (
    <TilesContainer data-testid="settings-list-content">
      {
        tiles.map(({ title, route }) =>
          <SettingTile key={route} title={title} route={route} />,
        )
      }
      <Routes>
        <Route path={SETTINGS_PATHS.REQUEST_ACCOUNT_REMOVAL} element={<AccountRemovalModal />} />
      </Routes>
    </TilesContainer>
  );
};
