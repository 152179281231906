import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

import { CardsState } from './types';

export const initialState: CardsState = {
  cards: [],
  getCards: {
    status: null,
    error: null,
  },
  deleteCard: {
    status: null,
    error: null,
  },
  registerCard: {
    status: null,
    error: null,
  },
};

const registerCardRequestHandler = (state: CardsState): CardsState => ({
  ...state,
  registerCard: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const registerCardSuccessHandler = (
  state: CardsState,
): CardsState => ({
  ...state,
  registerCard: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const registerCardFailureHandler = (
  state: CardsState,
  { payload: error }: ActionType<typeof actions.registerCard.failure>,
): CardsState => ({
  ...state,
  registerCard: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const getCardsRequestHandler = (state: CardsState): CardsState => ({
  ...state,
  getCards: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getCardsSuccessHandler = (
  state: CardsState,
  { payload }: ActionType<typeof actions.getCards.success>,
): CardsState => ({
  ...state,
  cards: payload,
  getCards: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getCardsFailureHandler = (
  state: CardsState,
  { payload: error }: ActionType<typeof actions.getCards.failure>,
): CardsState => ({
  ...state,
  getCards: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const deleteCardRequestHandler = (state: CardsState): CardsState => ({
  ...state,
  deleteCard: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const deleteCardSuccessHandler = (
  state: CardsState,
): CardsState => ({
  ...state,
  deleteCard: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const deleteCardFailureHandler = (
  state: CardsState,
  { payload: error }: ActionType<typeof actions.deleteCard.failure>,
): CardsState => ({
  ...state,
  deleteCard: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetRegisterCardStateHandler = (): CardsState => ({
  ...initialState,
});

const resetDeleteCardStateHandler = (state: CardsState): CardsState => ({
  ...state,
  deleteCard: {
    status: null,
    error: null,
  },
});

const cardsReducer = createReducer(initialState)
  .handleAction(actions.registerCard.request, registerCardRequestHandler)
  .handleAction(actions.registerCard.success, registerCardSuccessHandler)
  .handleAction(actions.registerCard.failure, registerCardFailureHandler)
  .handleAction(actions.getCards.request, getCardsRequestHandler)
  .handleAction(actions.getCards.success, getCardsSuccessHandler)
  .handleAction(actions.getCards.failure, getCardsFailureHandler)
  .handleAction(actions.deleteCard.request, deleteCardRequestHandler)
  .handleAction(actions.deleteCard.success, deleteCardSuccessHandler)
  .handleAction(actions.deleteCard.failure, deleteCardFailureHandler)
  .handleAction(actions.resetRegisterCardState, resetRegisterCardStateHandler)
  .handleAction(actions.resetDeleteCardState, resetDeleteCardStateHandler);

export default cardsReducer;
