import { EXPDATE_LENGTH } from 'shared/consts/payment-method-consts';

export const isExpDateValid = (formattedExpDate: string): boolean => {
  if (formattedExpDate.length === EXPDATE_LENGTH) {
    const [expMonth, expYear] = formattedExpDate.split('/');
    const expMonthNumber = Number(expMonth);
    const expYearNumber = Number(expYear);
    const currentUserLocaleMonth = Number(
      Intl.DateTimeFormat()
        .formatToParts()
        .find((part) => part.type === 'month').value,
    );
    const currentUserLocaleYear = Number(
      Intl.DateTimeFormat()
        .formatToParts()
        .find((part) => part.type === 'year').value,
    ) % 100;

    return (
      expYearNumber > currentUserLocaleYear
      || (expYearNumber === currentUserLocaleYear && expMonthNumber >= currentUserLocaleMonth)
    );
  }
  return false;
};
