import * as actions from './actions';

import { UserAuthState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

export const initialState: UserAuthState = {
  login: {
    status: null,
    error: null,
  },
  logout: {
    status: null,
    error: null,
  },
  finishLogin: {
    status: null,
    error: null,
  },
  register: {
    status: null,
    error: null,
  },
};

const loginRequestHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  login: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const loginSuccessHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  login: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const loginFailureHandler = (
  state: UserAuthState,
  { payload }: ActionType<typeof actions.login.failure>,
): UserAuthState => ({
  ...state,
  login: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const logoutRequestHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  logout: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const logoutSuccessHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  logout: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const logoutFailureHandler = (
  state: UserAuthState,
  { payload }: ActionType<typeof actions.logout.failure>,
): UserAuthState => ({
  ...state,
  logout: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const registerRequestHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  register: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const registerSuccessHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  register: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const registerFailureHandler = (
  state: UserAuthState,
  { payload }: ActionType<typeof actions.register.failure>,
): UserAuthState => ({
  ...state,
  register: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const finishLoginRequestHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  finishLogin: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const finishLoginSuccessHandler = (
  state: UserAuthState,
): UserAuthState => ({
  ...state,
  finishLogin: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const finishLoginFailureHandler = (
  state: UserAuthState,
  { payload }: ActionType<typeof actions.finishLogin.failure>,
): UserAuthState => ({
  ...state,
  finishLogin: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetLoginStatusHandler = (state: UserAuthState): UserAuthState => ({
  ...state,
  login: {
    ...initialState.login,
  },
});

const resetRegisterStatusHandler = (state: UserAuthState): UserAuthState => ({
  ...state,
  register: {
    ...initialState.register,
  },
});

const resetFinishLoginStatusHandler = (state: UserAuthState): UserAuthState => ({
  ...state,
  finishLogin: {
    ...initialState.finishLogin,
  },
});

const resetUserAuthStateHandler = (): UserAuthState => ({
  ...initialState,
});

const UserAuthReducer = createReducer(initialState)
  .handleAction(actions.login.request, loginRequestHandler)
  .handleAction(actions.login.success, loginSuccessHandler)
  .handleAction(actions.login.failure, loginFailureHandler)
  .handleAction(actions.logout.request, logoutRequestHandler)
  .handleAction(actions.logout.success, logoutSuccessHandler)
  .handleAction(actions.logout.failure, logoutFailureHandler)
  .handleAction(actions.register.request, registerRequestHandler)
  .handleAction(actions.register.success, registerSuccessHandler)
  .handleAction(actions.register.failure, registerFailureHandler)
  .handleAction(actions.finishLogin.request, finishLoginRequestHandler)
  .handleAction(actions.finishLogin.success, finishLoginSuccessHandler)
  .handleAction(actions.finishLogin.failure, finishLoginFailureHandler)
  .handleAction(actions.resetLoginStatus, resetLoginStatusHandler)
  .handleAction(actions.resetRegisterStatus, resetRegisterStatusHandler)
  .handleAction(actions.resetFinishLoginStatus, resetFinishLoginStatusHandler)
  .handleAction(actions.resetUserAuthState, resetUserAuthStateHandler);

export default UserAuthReducer;
