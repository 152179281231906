import { useBooleanState } from '@hqo/react-components-library';
import { SettingsListContent } from '../components/settings-list-content/settings-list-content';
import { SettingsFooter } from '../components/settings-list-content/components/footer/settings-footer';
import { PaymentMethodsContent } from '../components/payment-methods-content/payment-methods-content';
import { RegisterCardContent } from '../components/register-card-content/register-card-content';
import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SETTINGS_PATHS } from 'shared/consts';
import { replace } from 'store/router/actions';
import { IframeContent } from 'components/modals/iframe';
import { selectExternalLinks } from 'store/external-links/selectors';
import { getExternalLinks } from 'store/external-links/actions';
import { HistoryListContent } from '../components/history-list-content/history-list-content';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { NotificationSettingsContent } from 'components/modals/settings/components/notification-settings-content';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';

interface SwipeModalProps {
  title: string;
  withLeftArrowButton: boolean;
  onPressBack: VoidFunction;
  content: React.ReactNode;
  footerContent?: React.ReactNode;
  isIframe?: boolean;
}

interface UseSettingsPageReturnValues {
  onClose: VoidFunction;
  isReverseAnimation: boolean;
  swipeModalProps: SwipeModalProps;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

export const useSettingsPage = (): UseSettingsPageReturnValues => {
  const dispatch = useDispatch();
  const externalLinks = useSelector(selectExternalLinks);
  const client = useMiniappSdk();
  const intl = useIntl();
  const { pathname } = useLocation();
  const { callback } = useSearchParams();
  const { value: isReverseAnimation, toggle: toggleIsReverseAnimation } = useBooleanState(false);
  const scrollContainerRef = useRef();

  useEffect(() => {
    client?.header.hideHeader();

    return () => {
      client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
      client?.header.showHeader();
    };
  }, [client]);

  const onClose = useCallback(() => {
    toggleIsReverseAnimation();
    dispatch(replace('/'));
  }, []);

  const onPressBack = useCallback(() => {
    const route = pathname === SETTINGS_PATHS.REGISTER_CARD ? SETTINGS_PATHS.PAYMENT_METHODS : SETTINGS_PATHS.BASE;

    dispatch(replace(callback ? pathname : route));
  }, [dispatch, pathname, callback]);

  useEffect(() => {
    if (externalLinks === null) {
      dispatch(getExternalLinks.request());
    }
  }, [dispatch, externalLinks]);

  const swipeModalProps = useMemo(() => {
    const commonProps = {
      withLeftArrowButton: true,
      onPressBack,
    };

    switch (pathname) {
    case SETTINGS_PATHS.PAYMENT_METHODS:
    case SETTINGS_PATHS.REMOVE_CARD:
      return {
        ...commonProps,
        title: intl.formatMessage({ id: 'settings.paymentMethods.title' }),
        content: <PaymentMethodsContent />,
      };
    case SETTINGS_PATHS.REGISTER_CARD:
      return {
        ...commonProps,
        title: !callback && intl.formatMessage({ id: 'settings.paymentMethods.registerACard' }),
        content: <RegisterCardContent callback={!!callback} />,
        isIframe: !!callback,
      };
    case SETTINGS_PATHS.HISTORY:
      return {
        ...commonProps,
        title: intl.formatMessage({ id: 'settings.history.title' }),
        content: <HistoryListContent scrollContainerRef={scrollContainerRef} />,
      };
    case SETTINGS_PATHS.VIEW_TERMS_OF_SERVICE:
      return {
        ...commonProps,
        title: null,
        content: <IframeContent callbackOverride={externalLinks?.terms_and_conditions_link} />,
        isIframe: true,
      };
    case SETTINGS_PATHS.VIEW_PRIVACY_POLICY:
      return {
        ...commonProps,
        title: null,
        content: <IframeContent callbackOverride={externalLinks?.privacy_policy_link} />,
        isIframe: true,
      };
    case SETTINGS_PATHS.NOTIFICATION_SETTINGS:
      return {
        ...commonProps,
        title: intl.formatMessage({ id: 'settings.notificationSettings.title' }),
        content: <NotificationSettingsContent />,
      };
    default:
      return {
        title: intl.formatMessage({ id: 'settings.title' }),
        withLeftArrowButton: false,
        onPressBack: undefined,
        content: <SettingsListContent />,
        footerContent: <SettingsFooter />,
      };
    }
  }, [externalLinks, intl, onPressBack, pathname, callback]);

  return {
    onClose, isReverseAnimation, swipeModalProps, scrollContainerRef,
  };
};
