import styled from 'styled-components';
import { ReactComponent as Star } from 'assets/images/stars.svg';

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 10px 0 rgba(54, 54, 54, 0.2);
  cursor: pointer;
`;

export const TextContent = styled.div`
  padding: 12px 16px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.div`
  word-break: break-word;
  font: 500 16px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Subtitle = styled.div`
  word-break: break-word;
  font: 400 12px/12px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const Image = styled.img`
  height: 160px;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const CountBlock = styled.div`
  background-color: ${({ theme }) => theme?.colors.palette.sharedWhite};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme?.colors.palette.systemGreyMedium};
  display: grid;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 23px;
  gap: 3px;
`;

export const ActivePill = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme?.colors.palette.sharedWhite};
  padding: 4px 8px;
  position: absolute;
  top: 21px;
  right: 21px;
  border-radius: 4px;
  background-image: linear-gradient(
    108.32deg,
    ${({ theme }) => theme.colors.palette.systemGreenAlt} -7.78%,
    #054233 97.7%
  );
  background-origin: border-box;
  box-shadow: inset 0 100vw ${({ theme }) => theme.colors.palette.systemGreen};
  border: 1px solid transparent;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const StyledStar = styled(Star)`
  filter: invert(44%) sepia(76%) saturate(4935%) hue-rotate(31deg) brightness(91%) contrast(101%);
  height: 16px;
  width: 16px;
`;

export const CountTopLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

export const RedeemText = styled.div`
  font: 400 12px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  cursor: pointer;
`;

export const PointsNumber = styled.div`
  word-break: break-word;
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
