import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { SSO_MODAL_CONTENT_STEPS } from 'components/modals/sso-flow/constants';
import { replace } from 'store/router/actions';
import { selectIsUserWithRelatedWhitelabel } from 'store/config/selectors';
import ThanxLogo from 'assets/images/thanx-logo.svg';
import { ACTION_STATUSES, DEFAULT_ANIMATION_DELAY } from 'shared/consts';
import { getPaymentMethods } from 'store/payment-methods/actions';
import { selectGetPaymentMethodsStatus } from 'store/payment-methods/selectors';

interface UseSignInContentReturnValues {
  handleLinkClick: VoidFunction;
  isRelatedWhitelabel: boolean;
  logoUrl: string;
}

export const useSignUpContent = (): UseSignInContentReturnValues => {
  const dispatch = useDispatch();
  const isRelatedWhitelabel = useSelector(selectIsUserWithRelatedWhitelabel);
  const getPaymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const isGetPaymentMethodsFulfilled = getPaymentMethodsStatus === ACTION_STATUSES.FULFILLED;
  const { pathname } = useLocation();
  const logoUrl = isRelatedWhitelabel && ThanxLogo;

  const handleLinkClick = useCallback(() => {
    const searchString = qs.stringify({ step: SSO_MODAL_CONTENT_STEPS.SIGN_IN, isSlideAnimationEnable: true });

    setTimeout(() => {
      dispatch(replace(`${pathname}?${searchString}`));
    }, DEFAULT_ANIMATION_DELAY);
  }, [pathname]);

  useEffect(() => {
    if (getPaymentMethodsStatus !== ACTION_STATUSES.PENDING && !isGetPaymentMethodsFulfilled) {
      dispatch(getPaymentMethods.request());
    }
  }, [getPaymentMethodsStatus, isGetPaymentMethodsFulfilled]);

  return { handleLinkClick, isRelatedWhitelabel, logoUrl };
};
