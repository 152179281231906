import { ACTION_STATUSES } from 'shared/consts';
import { getIntroReward } from 'store/intro-reward/actions';
import { IntroRewardState } from 'store/intro-reward/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const INITIAL_STATE: IntroRewardState = {
  introReward: null,
  getIntroReward: {
    status: null,
    error: null,
  },
};

const getIntroRewardRequestHandler = (state: IntroRewardState): IntroRewardState => ({
  ...state,
  getIntroReward: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getIntroRewardSuccessHandler = (
  state: IntroRewardState,
  { payload }: ActionType<typeof getIntroReward.success>,
): IntroRewardState => ({
  ...state,
  introReward: payload.data.intro_reward,
  getIntroReward: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getIntroRewardFailureHandler = (
  state: IntroRewardState,
  { payload }: ActionType<typeof getIntroReward.failure>,
): IntroRewardState => ({
  ...state,
  getIntroReward: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

export const introRewardReducer = createReducer(INITIAL_STATE)
  .handleAction(getIntroReward.request, getIntroRewardRequestHandler)
  .handleAction(getIntroReward.success, getIntroRewardSuccessHandler)
  .handleAction(getIntroReward.failure, getIntroRewardFailureHandler);
