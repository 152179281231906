import React from 'react';
import { ReactComponent as EmptyState } from 'assets/images/empty-state.svg';
import {
  Container, Subtitle, Title,
} from './styles';

interface DefaultEmptyStateProps {
  title: string;
  subtitle: string;
}

export const DefaultEmptyState = ({ title, subtitle }: DefaultEmptyStateProps): JSX.Element => (
  <Container data-testid="default-empty-state-container">
    <Title>{title}</Title>
    <EmptyState />
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);
