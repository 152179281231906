import { useSearchParams } from 'hooks/use-search-params.hook';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { SearchParams } from 'shared/types';
import { getBuilding } from 'store/building/actions';
import { selectBuildingStatus } from 'store/building/selectors';
import { saveExternalConfig } from 'store/config/actions';
import { hasToken } from 'store/config/selectors';
import { selectGenericErrorNotifications } from 'store/errors/selectors';
import { getBuildingTheme } from 'store/theme/actions';
import { selectIsThemeLoaded } from 'store/theme/selectors';
import { externalLogin } from 'store/user/actions';
import { isUserLoaded } from 'store/user/selectors';

export const useInitialData = (): { isReadyToRenderApp: boolean } => {
  const dispatch = useDispatch();
  const {
    buildingUuid, authToken, apiUrl, locale,
  } = useSearchParams<SearchParams>();
  const isUserLoadingFinished = useSelector(isUserLoaded);
  const isBuildingLoadingFinished = useSelector(selectBuildingStatus) === ACTION_STATUSES.FULFILLED;
  const isBuildingThemeLoadingFinished = useSelector(selectIsThemeLoaded);
  const isStateContainsErrors = useSelector(selectGenericErrorNotifications).length > 0;
  const isAppDataLoaded = isUserLoadingFinished && isBuildingLoadingFinished && isBuildingThemeLoadingFinished;
  const isReadyToRenderApp = isAppDataLoaded || isStateContainsErrors;
  const isStateHasToken = useSelector(hasToken);
  useEffect(() => {
    if (isStateHasToken && !isAppDataLoaded) {
      dispatch(getBuilding.request());
      dispatch(getBuildingTheme.request());
      dispatch(externalLogin.request());
    }
  }, [isStateHasToken, isAppDataLoaded]);

  useEffect(() => {
    if (!isStateHasToken) {
      dispatch(
        saveExternalConfig({
          buildingUuid,
          authToken,
          apiUrl,
          locale,
        }),
      );
    }
  }, [isStateHasToken]);

  return { isReadyToRenderApp };
};
