import React from 'react';

import { ReactComponent as MagicLinkSent } from 'assets/images/magic-link-sent.svg';
import { useLegalRequirements } from 'components/modals/payment-method-manager/hooks/use-legal-requirements.hook';
import { BoldText } from 'hocs/shared-styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useIntl } from 'react-intl';
import { SearchParams } from 'shared/types';
import { isTruthyString } from 'utils/isTruthyString';

import { useMagicLinkContent } from './hooks/use-magic-link-content.hook';
import {
  Button, ButtonsContainer, Container, StyledMailTo, Subtitle, Title, MailToContainer,
} from './styles';
import { useTrackEvent } from 'hooks/use-track-event.hook';
import { MAGIC_LINK_MODAL, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

export const MagicLinkContent = (): JSX.Element => {
  const intl = useIntl();
  const { isSlideAnimationEnable } = useSearchParams<SearchParams>();

  const { handleResendLink } = useMagicLinkContent();
  const isSlideAnimationExist = isTruthyString(isSlideAnimationEnable);
  const { externalLinks } = useLegalRequirements();
  const { handleTrackEvent } = useTrackEvent({
    eventName: TRACK_EVENT_NAMES.THANX_CONTACT_SUPPORT_CLICK,
    eventType: TRACK_EVENT_TYPES.ACTION,
    metadata: { modal_name: MAGIC_LINK_MODAL },
  });

  return (
    <Container data-testid="magic-link-content-container" isSlideAnimationEnable={isSlideAnimationExist}>
      <Title>
        {intl.formatMessage({ id: 'magicLinkModal.title' })}
      </Title>
      <MagicLinkSent />
      <Subtitle>
        {intl.formatMessage(
          { id: 'magicLinkModal.subtitle' },
          {
            boldText: <BoldText>{intl.formatMessage({ id: 'magicLinkModal.onThisPhone' })}</BoldText>,
          },
        )}
      </Subtitle>
      <ButtonsContainer>
        <Button onClick={handleResendLink}>{intl.formatMessage({ id: 'magicLinkModal.resendLink' })}</Button>
        <MailToContainer onClick={handleTrackEvent}>
          <StyledMailTo
            subject={intl.formatMessage({ id: 'support.emailSubject' })}
            title={intl.formatMessage({ id: 'support.contactSupport' })}
            mailTo={externalLinks?.support_email}
          />
        </MailToContainer>
      </ButtonsContainer>
    </Container>
  );
};
