import { styled } from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 700 24px/26px ${({ theme }) => theme.fonts.join()};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 100vw;
  gap: 30px;
`;

export const LeftBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const Cost = styled.div`
  text-overflow: nowrap;
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CountBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
`;

export const ActivatedRewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;

export const ActivatedRewardDescription = styled.div`
font: 600 14px/18px ${({ theme }) => theme.fonts.join()};
`;
