import { flatten } from 'utils';
import messagesInEnglish from '../../lang/en.json';
import { useMemo } from 'react';

export const DEFAULT_LOCALE = 'en';

const messagesMap = new Map<string, Record<string, unknown>>([['en', messagesInEnglish.thanxMiniapp]]);

export const getAvailableLocale = (userLocale: string): [string, string] => {
  if (messagesMap.has(userLocale)) {
    return [userLocale, userLocale];
  }

  const [lang] = userLocale.split('-');

  if (messagesMap.has(lang)) {
    return [lang, userLocale];
  }

  return [DEFAULT_LOCALE, DEFAULT_LOCALE];
};

interface LocaleData {
  messages: Record<string, string>;
  locale: string;
}

export const useLocaleData = (userLocale: string): LocaleData => useMemo(() => {
  const [messagesKey, locale] = getAvailableLocale(userLocale);

  return {
    locale,
    messages: flatten(messagesMap.get(messagesKey)) as Record<string, string>,
  };
}, [userLocale]);
