import styled from 'styled-components';

export const Container = styled.div`
  .register-card-container {
    width: 100%;
  }

  .register-card-wrapper {
    padding: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;
