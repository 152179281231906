import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_STATUSES, BUILDING, REWARDS_LIST_LIMIT } from 'shared/consts';
import { selectGetInfiniteRewardsStatus, selectRewardsTotal } from 'store/rewards/selectors';
import { getInfiniteRewards } from 'store/rewards/actions';
import { RewardsStateEnum } from 'store/rewards/types';
import { selectBuildingUuid } from 'store/building/selectors';

interface UseInfiniteScrollProps {
  rewardsListContentContainerRef: React.RefObject<HTMLDivElement>;
  totalDisplayedRewards: number;
}

interface UseInfiniteScrollResponse {
  isInfiniteScrolling: boolean;
}

export const useInfiniteScroll = ({
  rewardsListContentContainerRef,
  totalDisplayedRewards,
}: UseInfiniteScrollProps): UseInfiniteScrollResponse => {
  const dispatch = useDispatch();
  const isInfiniteScrolling = useSelector(selectGetInfiniteRewardsStatus) === ACTION_STATUSES.PENDING;
  const buildingUuid = useSelector(selectBuildingUuid);
  const totalRewards = useSelector(selectRewardsTotal);
  const initiateInfiniteScrollThreshold = 20; // px
  const containerRef = rewardsListContentContainerRef?.current;

  const payload = {
    ownerId: buildingUuid,
    ownerType: BUILDING,
    states: [RewardsStateEnum.AVAILABLE, RewardsStateEnum.NOT_REDEEMED, RewardsStateEnum.ACTIVE],
  };

  const onScroll = useCallback(() => {
    if (isInfiniteScrolling) {
      return;
    }
    const scrollTop = containerRef?.scrollTop;
    const offsetHeight = containerRef?.offsetHeight;
    const scrollHeight = containerRef?.scrollHeight;

    const isScrollingToBottom = scrollTop + offsetHeight >= scrollHeight - initiateInfiniteScrollThreshold;

    if (isScrollingToBottom && totalDisplayedRewards < totalRewards) {
      dispatch(
        getInfiniteRewards.request({
          ...payload,
          limit: REWARDS_LIST_LIMIT,
          offset: totalDisplayedRewards,
        }),
      );
    }
  }, [isInfiniteScrolling, containerRef, totalDisplayedRewards, totalRewards, dispatch]);

  useEffect(() => {
    containerRef?.addEventListener('scroll', onScroll);

    return () => {
      containerRef?.removeEventListener('scroll', onScroll);
    };
  }, [containerRef, onScroll]);

  return { isInfiniteScrolling };
};
