import React from 'react';

import { usePaymentLabels } from './hooks/use-payment-labels.hook';
import { StyledPaymentMethodsManager, StyledWrapper } from './styles';
import { SavedPaymentMethod } from 'store/payment-methods/types';

interface RegisterExistingCardProps {
  onOptionClickHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paymentMethods: Array<SavedPaymentMethod>;
  selectedPaymentMethod: string;
  handleRegisterNewCardRedirect: VoidFunction;
}

export const RegisterExistingCard = ({
  onOptionClickHandler,
  paymentMethods,
  selectedPaymentMethod,
  handleRegisterNewCardRedirect,
}: RegisterExistingCardProps): JSX.Element => {
  const { buildSavedPaymentLabels, labels } = usePaymentLabels({ paymentMethods });

  return (
    <StyledWrapper data-testid="register-existing-card">
      <StyledPaymentMethodsManager
        paymentMethods={paymentMethods}
        options={buildSavedPaymentLabels()}
        onOptionClick={onOptionClickHandler}
        labels={labels}
        selectedPaymentMethodId={selectedPaymentMethod}
        onRemovePaymentMethod={undefined}
        onAddNewCard={handleRegisterNewCardRedirect}
        isRadioFormDisabled={false}
        isAddCardButtonDisabled={false}
        isSwipeModalContent
      />
    </StyledWrapper>
  );
};
