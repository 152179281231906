import { MILLIS_IN_SECOND, MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from 'shared/consts';

export type TimeLeft = {
    hours: number;
    minutes: number;
    seconds: number;
  };

export const calculateTimeLeft = ({ startDatetime, endDatetime }: {
    startDatetime: Date;
    endDatetime: Date;
}) : TimeLeft => {
  const difference = endDatetime.getTime() - startDatetime.getTime();

  if (difference > 0) {
    return {
      hours: Math.floor(difference / (MILLIS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR)),
      minutes: Math.floor((difference / MILLIS_IN_SECOND / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR),
      seconds: Math.floor((difference / MILLIS_IN_SECOND) % SECONDS_IN_MINUTE),
    };
  }

  return { hours: 0, minutes: 0, seconds: 0 };
};

export const isTimeExpired = ({
  hours,
  minutes,
  seconds,
}: TimeLeft) : boolean => hours === 0 && minutes === 0 && seconds === 0;

export const formatCountdownText = ({ hours = 0, minutes = 0, seconds = 0 }: TimeLeft): string => {
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formattedMinutes = hours > 0 && minutes < 10 ? `0${minutes}` : minutes;
  return (hours > 0)
    ? `${hours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes}:${formattedSeconds}`;
};
