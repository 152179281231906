import React from 'react';

import { useIntl } from 'react-intl';
import { usePaymentMethodsManager } from './hooks/use-payment-methods-manager.hook';
import { RegisterExistingCard } from './components/register-existing-card/register-existing-card';
import { RegisterNewCard } from 'components/modals/payment-method-manager/components/register-new-card';
import {
  ButtonWrapper,
  CardTypesNotice,
  ErrorMessage,
  StyledButton,
  StyledSkipButton,
  StyledTitle,
  StyledWrapper,
} from 'components/modals/payment-method-manager/styles';
import { LegalRequirements } from 'components/legal-requirements';
import { useLegalRequirements } from 'components/modals/payment-method-manager/hooks/use-legal-requirements.hook';

interface PaymentMethodManagerContentProps {
  isSettingsModal?: boolean;
}

export const PaymentMethodManagerContent = ({
  isSettingsModal = false,
}: PaymentMethodManagerContentProps): JSX.Element => {
  const intl = useIntl();
  const {
    onOptionClickHandler,
    selectedPaymentMethod,
    filteredPaymentMethods,
    isAddingExistingCard,
    setIsAddingExistingCard,
    onRegisterCardClick,
    isRegisterButtonDisabled,
    onSkipClick,
    isRegisterButtonLoading,
    showRegisterCardError,
  } = usePaymentMethodsManager({ isSettingsModal });
  const { isExternalLinksLoading, externalLinks } = useLegalRequirements();

  return (
    <StyledWrapper data-testid="payment-method-manager-content">
      { !isSettingsModal && (
        <StyledTitle data-testid="payment-method-manager-content-title">
          {intl.formatMessage({ id: 'registerCardModal.title' })}
        </StyledTitle>
      )}
      {isAddingExistingCard ? (
        <RegisterExistingCard
          onOptionClickHandler={onOptionClickHandler}
          selectedPaymentMethod={selectedPaymentMethod}
          paymentMethods={filteredPaymentMethods}
          handleRegisterNewCardRedirect={setIsAddingExistingCard}
        />
      )
        : <RegisterNewCard />}
      <ButtonWrapper>
        {showRegisterCardError && (
          <ErrorMessage>
            {intl.formatMessage({ id: 'registerCardModal.errors.sorryUnableToRegisterCard' })}
          </ErrorMessage>
        )}
        <StyledButton
          data-testid="register-card-button"
          text={intl.formatMessage({ id: 'registerCardModal.registerCard' })}
          onClick={onRegisterCardClick}
          disabled={isRegisterButtonDisabled || isRegisterButtonLoading}
          loading={isRegisterButtonLoading}
        />
      </ButtonWrapper>
      { !isSettingsModal && (
        <StyledSkipButton
          data-testid="skip-button"
          variant="Skip"
          text={intl.formatMessage({ id: 'registerCardModal.skip' })}
          onClick={onSkipClick}
        />
      )}
      <CardTypesNotice data-testid="card-types-notice">
        {intl.formatMessage({ id: 'registerCardModal.acceptedCardsDisclaimer' })}
      </CardTypesNotice>
      <LegalRequirements isExternalLinksLoading={isExternalLinksLoading} externalLinks={externalLinks} />
    </StyledWrapper>
  );
};
