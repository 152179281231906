import React, { useMemo, useRef } from 'react';
import { Container, HistoryContainer } from './styles';
import { Skeleton } from 'components/skeleton';
import { useHistoryListContent } from './hooks/use-history-list-content.hook';
import { HistoryItem } from './components/history-item';
import { HistoryItemSkeleton } from './components/history-item-skeleton';
import { useHistoryInfiniteScroll } from './hooks/use-history-infinite-scroll.hook';
import { DefaultEmptyState } from 'components/default-empty-state';
import { useIntl } from 'react-intl';

interface HistoryListContentProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

export const HistoryListContent = ({ scrollContainerRef }: HistoryListContentProps): JSX.Element => {
  const intl = useIntl();
  const rewardsListContentContainerRef = useRef();
  const rewardsContainerRef = useRef();
  const { usedRewards, isLoading } = useHistoryListContent();
  const totalDisplayedRewards = useMemo(() => usedRewards.length, [usedRewards]);
  const { isHistoryInfiniteScrolling } = useHistoryInfiniteScroll({
    contentContainerRef: scrollContainerRef,
    totalDisplayedRewards,
  });
  const shouldRenderSkeleton = isHistoryInfiniteScrolling && Boolean(usedRewards.length);

  if (isLoading && !usedRewards.length) {
    return <Skeleton isSettingsPage />;
  }

  return (
    <Container data-testid="history-list-content" ref={rewardsListContentContainerRef}>
      {usedRewards.length ? (
        <HistoryContainer ref={rewardsContainerRef}>
          {
            usedRewards.map(({
              id, title, used_at,
            }) =>
              <HistoryItem key={id} title={title} date={used_at} />,
            )
          }
          {shouldRenderSkeleton && <HistoryItemSkeleton />}
        </HistoryContainer>
      ) : (
        <DefaultEmptyState
          title={intl.formatMessage({ id: 'historyEmptyState.title' })}
          subtitle={intl.formatMessage({ id: 'historyEmptyState.subtitle' })}
        />
      )}
    </Container>
  );
};
