import { GetJWTHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { getMiniappSdkClient } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { Dispatch, MiddlewareAPI } from 'redux';
import { PROMISE_TIMEOUT } from 'shared/consts';
import { NOT_FOUND_ERROR_CODE, UNAUTHORIZED_ERROR_CODE } from 'shared/consts/error-codes';
import { RootAction } from 'store/actions';
import { getBuilding } from 'store/building/actions';
import { saveExternalConfig } from 'store/config/actions';
import { showGenericErrorNotification } from 'store/errors/actions';

import { RootState } from 'store/reducer';

import { getBuildingTheme } from 'store/theme/actions';
import { fetchUiMetadata } from 'store/ui-metadata/actions';
import { externalLogin, getCurrentUser, refreshToken } from 'store/user/actions';

import { isActionOf } from 'typesafe-actions';

const failedActionsTypes = [
  getBuildingTheme.failure,
  externalLogin.failure,
  getCurrentUser.failure,
  getBuilding.failure,
  fetchUiMetadata.failure,
];

const failedActionsTypesWithErrorScreenRedirect = failedActionsTypes;

export const tokenExpiryMiddleware = (store: MiddlewareAPI<Dispatch<RootAction>, RootState>) =>
  (next: Dispatch<RootAction>) =>
    async (action: RootAction) => {
      const isAsyncActionTypeFailed = isActionOf(failedActionsTypes)(action);
      if (
        isAsyncActionTypeFailed
      && 'status' in action.payload.error
        && (action.payload.error.status === UNAUTHORIZED_ERROR_CODE
          || action.payload.error.status === NOT_FOUND_ERROR_CODE)
      ) {
        const miniappSdkClient = getMiniappSdkClient();

        await new Promise<void>((resolve, reject) => {
          const timeout = setTimeout(() => {
            store.dispatch(
              showGenericErrorNotification({
                action: action.type,
                error: action.payload.error,
                errorCode: action.payload.errorCode,
              }),
            );
            reject();
          }, PROMISE_TIMEOUT);

          miniappSdkClient.identity.getJWT();

          miniappSdkClient.on('getJWTResponse', (data: GetJWTHandlerPayload) => {
            clearTimeout(timeout);
            resolve();

            store.dispatch(saveExternalConfig({ authToken: `Bearer ${data.token}` }));
          });
        });

        store.dispatch(refreshToken());
      } else if (isActionOf(failedActionsTypesWithErrorScreenRedirect)(action)) {
        store.dispatch(
          showGenericErrorNotification({
            action: action.type,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
          }),
        );
      } else {
        next(action);
      }
    };
