import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Container, StyledButton, Title } from './styles';
import { formatSavedPaymentMethodBrand } from 'utils/formatSavedPaymentMethodBrand';
import { useIntl } from 'react-intl';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { useLocation } from 'react-router-dom';

export interface CardTileProps {
  id: string;
  type: string;
  lastFour: string;
  toggleIsReverseAnimation: VoidFunction;
  isReverseAnimation: boolean;
}

export const CardTile = ({
  id,
  type,
  lastFour,
  toggleIsReverseAnimation,
  isReverseAnimation,
}: CardTileProps): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const intl = useIntl();
  const capitalizedBrand = formatSavedPaymentMethodBrand(type);
  const title = `${capitalizedBrand} *${lastFour}`;

  const handleRemoveClick = useCallback(() => {
    const searchString = qs.stringify({ cardId: id });

    if (isReverseAnimation) {
      toggleIsReverseAnimation();
    }

    dispatch(replace(`${pathname}/remove-card?${searchString}`));
  }, [dispatch, id, pathname, toggleIsReverseAnimation, isReverseAnimation]);

  return (
    <Container data-testid="card-tile">
      <Title data-testid="card-tile-title">{title}</Title>
      <StyledButton data-testid="card-tile-remove-button" onClick={handleRemoveClick}>
        {intl.formatMessage({ id: 'settings.paymentMethods.remove' })}
      </StyledButton>
    </Container>
  );
};
