import { SwitchPanel } from '@hqo/react-components-library/dist/molecules/switch-panel';
import { titleMapper } from 'components/modals/settings/components/notification-settings-content/titleMapper';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { patchNotificationSettings } from 'store/notification-settings/actions';
import { checkIsPatchingSettingByTitle } from 'store/notification-settings/selectors';
import { NotificationSettingType, NotificationSettings } from 'store/notification-settings/types';

export interface NotificationSettingProps {
  setting: {
    title: string;
    values: NotificationSettingType;
  };
}

export const NotificationSetting = ({ setting }: NotificationSettingProps): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isPendingPatchRequest = useSelector(
    checkIsPatchingSettingByTitle(setting.title as Exclude<keyof NotificationSettings, 'id'>),
  );

  const onChange = useCallback(() => {
    dispatch(
      patchNotificationSettings.request({
        [setting.title]: {
          email: !setting.values.email,
        },
      }),
    );
  }, [dispatch, setting.title, setting.values.email]);

  return (
    <SwitchPanel
      key={setting.title}
      title={intl.formatMessage({ id: titleMapper.get(setting.title) })}
      checked={setting.values.email}
      onChange={onChange}
      isDisabled={isPendingPatchRequest}
    />
  );
};
