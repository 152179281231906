import React from 'react';

import { FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field';
import {
  CvvAndExpirationContainer,
  Label,
  RowWrapper,
  SpreedlyCVVWrapper,
  SpreedlyCardWrapper,
  SpreedlyErrorLabel,
  SpreedlyIFrameContainer,
  StyledFormField,
  StyledWrapper,
} from 'components/modals/payment-method-manager/components/register-new-card/styles';
import { useIntl } from 'react-intl';
import { SPREEDLY_CARD_ID, SPREEDLY_CVV_ID } from 'shared/consts/payment-method-consts';
import { useRegisterNewCard } from './hooks/use-register-new-card.hook';
import { isExpDateValid } from 'utils/isExpDateValid';

export const RegisterNewCard = (): JSX.Element => {
  const intl = useIntl();
  const creditCardNumberErrorMessage = intl.formatMessage({ id: 'registerCardModal.errors.invalidCardNumberError' });
  const expDateErrorMessage = intl.formatMessage({ id: 'registerCardModal.errors.expirationDateError' });
  const cvvErrorMessage = intl.formatMessage({ id: 'registerCardModal.errors.invalidCvvError' });
  const zipCodeErrorMessage = intl.formatMessage({ id: 'registerCardModal.errors.zipCodeError' });
  const {
    expDate,
    zipCode,
    triggerErrorZipCode,
    triggerErrorExpDate,
    isZipCodeValid,
    handleOnChangeExpDate,
    handleOnChangeZipCode,
    handleSpreedlyCardOnMouseEnter,
    handleSpreedlyCvvOnMouseEnter,
    handleSpreedlyCardOnMouseLeave,
    handleSpreedlyCvvOnMouseLeave,
    handleKeyboardEnter,
    handleZipCodeOnBlur,
    handleExpDateOnBlur,
    spreedlyCvvElementRef,
    spreedlyCardElementRef,
    errorSpreedlyCardField,
    errorSpreedlyCvvField,
  } = useRegisterNewCard();

  return (
    <StyledWrapper>
      <SpreedlyCardWrapper>
        <Label>{intl.formatMessage({ id: 'registerCardModal.creditCardNumber' })}</Label>
        <SpreedlyIFrameContainer
          data-testid="spreedly-card-field"
          ref={spreedlyCardElementRef}
          id={SPREEDLY_CARD_ID}
          onMouseEnter={handleSpreedlyCardOnMouseEnter}
          onMouseLeave={handleSpreedlyCardOnMouseLeave}
        />
        {errorSpreedlyCardField && (
          <SpreedlyErrorLabel data-testId="spreedly-card-error">{creditCardNumberErrorMessage}</SpreedlyErrorLabel>
        )}
      </SpreedlyCardWrapper>
      <RowWrapper>
        <CvvAndExpirationContainer>
          <SpreedlyCVVWrapper>
            <Label>{intl.formatMessage({ id: 'registerCardModal.cvv' })}</Label>
            <SpreedlyIFrameContainer
              data-testid="spreedly-cvv-field"
              ref={spreedlyCvvElementRef}
              id={SPREEDLY_CVV_ID}
              onMouseEnter={handleSpreedlyCvvOnMouseEnter}
              onMouseLeave={handleSpreedlyCvvOnMouseLeave}
            />
            {errorSpreedlyCvvField && (
              <SpreedlyErrorLabel data-testId="spreedly-cvv-error">{cvvErrorMessage}</SpreedlyErrorLabel>
            )}
          </SpreedlyCVVWrapper>
          <StyledFormField
            data-testid="exp-date-field"
            errorText={!isExpDateValid(expDate) && triggerErrorExpDate && expDateErrorMessage}
            fieldType={FormFieldType.INPUT}
            inputMode="numeric"
            label={intl.formatMessage({ id: 'registerCardModal.expiration' })}
            onBlur={handleExpDateOnBlur}
            onChange={handleOnChangeExpDate}
            onKeyDown={handleKeyboardEnter}
            placeholder={intl.formatMessage({ id: 'registerCardModal.placeholders.expirationDate' })}
            value={expDate}
            variant="master"
          />
        </CvvAndExpirationContainer>
        <StyledFormField
          data-testid="zip-code-field"
          errorText={!isZipCodeValid(zipCode) && triggerErrorZipCode && zipCodeErrorMessage}
          fieldType={FormFieldType.INPUT}
          label={intl.formatMessage({ id: 'registerCardModal.zipCode' })}
          onBlur={handleZipCodeOnBlur}
          onChange={handleOnChangeZipCode}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'registerCardModal.placeholders.zipCode' })}
          value={zipCode}
          variant="master"
        />
      </RowWrapper>
    </StyledWrapper>

  );
};
