import React from 'react';
import { SwitchModal } from './switch-modal';
import { SuccessRequestContent } from './success-request-content';
import { FailedRequestContent } from './failed-request-content';
import { ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS } from '../constants';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { SearchParams } from 'shared/types';
import { BottomModalContent } from 'components/bottom-modal-content';
import { useIntl } from 'react-intl';
import { BottomModalContentButtons } from 'components/bottom-modal-content/types';

interface ModalContentProps {
  redirectToTheLandingPage: VoidFunction;
  buttons: Array<BottomModalContentButtons>;
}

export const ModalContent = ({
  buttons,
  redirectToTheLandingPage,
}: ModalContentProps): JSX.Element => {
  const intl = useIntl();
  const { step } = useSearchParams<SearchParams>();
  const contentValue = step as ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS || ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS.DEFAULT;

  return (
    <SwitchModal value={contentValue}>
      <SuccessRequestContent
        data-value={ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS.SUCCESS}
        handleCTAButtonClick={redirectToTheLandingPage}
      />
      <FailedRequestContent
        data-value={ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS.FAILED}
        handleCTAButtonClick={redirectToTheLandingPage}
      />
      <BottomModalContent
        data-value={ACCOUNT_REMOVAL_MODAL_CONTENT_STEPS.DEFAULT}
        title={intl.formatMessage({ id: 'accountRemovalModal.title' })}
        subtitles={[intl.formatMessage({ id: 'accountRemovalModal.subtitle' })]}
        buttons={buttons}
      />
    </SwitchModal>
  );
};
